import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {
    addOppositeWorkGroupToActivity,
    clearDialogData,
    selectActivityById,
    selectDialogData
} from "../../../../../store/plannerSlice";
import {selectDisplayWorkGroupByIdMemoized} from "../../../store/selectors";
import {AddOppositeWorkGroupForm} from "./types";
import {FormikHelpers} from "formik";
import {getWorkGroupItemTypeTranslation} from "../../../../../utils/enumTranslations";
import React from "react";
import AddOppositeWorkGroupDialog from "./index";

export default function AddOppositeWorkGroupDialogToActivity() {
    const dispatch = useAppDispatch();
    const addOppositeWorkGroupToActivityDialogData = useAppSelector(selectDialogData).addOppositeWorkGroupToActivity;
    if (!addOppositeWorkGroupToActivityDialogData) return <></>;
    const workGroup = useAppSelector(state => selectDisplayWorkGroupByIdMemoized(state, addOppositeWorkGroupToActivityDialogData.workGroupId));
    const activity = useAppSelector(state => selectActivityById(state, addOppositeWorkGroupToActivityDialogData.activityId));

    if (!workGroup || !activity) return <></>;

    const handleClose = () => dispatch(clearDialogData());

    const handleSubmit = (form: AddOppositeWorkGroupForm, formHelpers: FormikHelpers<AddOppositeWorkGroupForm>) => {
        if (form.workGroup) {
            formHelpers.setSubmitting(true);
            dispatch(addOppositeWorkGroupToActivity({
                activityId: addOppositeWorkGroupToActivityDialogData.activityId,
                newWorkGroup: form.workGroup,
            })).finally(() => formHelpers.setSubmitting(false));
        }
    };

    return <AddOppositeWorkGroupDialog
        isOpen={!!addOppositeWorkGroupToActivityDialogData}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={`Tegevusele ${getWorkGroupItemTypeTranslation(activity.type).toLowerCase()}`}
        workGroup={workGroup}
    />
}
