import {WorkSheetDetails as ApiWorkSheetDetails, WorkSheetWorkItem} from "../../../../API/workSheets/types";
import {BusUsageWithDistance} from "../../dashboard/DriverDashboard/types";
import dayjs from "dayjs";
import {Bus, OdometerReadingWithBusId} from "../../../../API/bus/types";
import {findNearestReading, findPreviousReading, isReadingWithinAllowedTimeRange} from "../../../../components/FormTemplates/ReadingForm/utils";
import {OdometerReadingRow} from "./WorkSheetDetails";
import {BusUsage} from "../../../../API/workSchedule/types";


export const hasAnItemFromAnotherDriverWithinTimeframe = (
    timeframeStartDateTime: string,
    timeframeEndDateTime: string,
    items: {startDateTime: string, driverWorkSheetId?: number | null}[],
    driverWorkSheetId: number
): boolean => {
    const startTime = dayjs(timeframeStartDateTime);
    const endTime = dayjs(timeframeEndDateTime);

    for (const item of items) {
        if (item.driverWorkSheetId !== driverWorkSheetId) {
            const itemTime = dayjs(item.startDateTime);

            if ((!itemTime.isSame(startTime) && (itemTime.isAfter(startTime)) && (itemTime.isBefore(endTime)) && !itemTime.isSame(endTime))) {
                return true;
            }
        }
    }

    return false;
};

export const isTheItemWithinTimeframeWithBuffer = (timeframeStartDateTime: string, timeframeEndDateTime: string, itemDateTime: string): boolean => {
    const startTime = dayjs(timeframeStartDateTime);
    const endTime = dayjs(timeframeEndDateTime);
    const itemTime = dayjs(itemDateTime);

    return (!itemTime.isSame(startTime) && (itemTime.isAfter(startTime)) && (itemTime.isBefore(endTime)) && !itemTime.isSame(endTime))
        || isReadingWithinAllowedTimeRange(startTime, itemTime) || isReadingWithinAllowedTimeRange(endTime, itemTime);
};

export const getBusUsages = (sortedWorkItems: WorkSheetWorkItem[], secondaryWorkSheets: ApiWorkSheetDetails[], driverWorkSheetId: number) => {
    const busUsages: BusUsageWithDistance[] = [];
    let currentBusId: number | undefined;
    let currentStartDateTime: string | undefined;
    let currentEndDateTime: string | undefined;
    let currentStartDateTimeOfFirstActivityWithDistance: string | undefined;
    let currentEndDateTimeOfLastActivityWithDistance: string | undefined;
    let currentDistance: number = 0;

    sortedWorkItems.forEach(workItem => {
        const busWorkSheet = secondaryWorkSheets.find(secondaryWorkSheet => secondaryWorkSheet.id === workItem.busWorkSheetId);
        const busId = busWorkSheet ? busWorkSheet.resourceId : undefined;
        if (busId) {
            // if the current bus usage id does not match the work item bus id
            if (currentBusId !== busId) {
                // if there currently already is a bus usage, then add it to usages
                if (currentBusId && currentStartDateTime && currentEndDateTime) {
                    busUsages.push({
                        busId: currentBusId,
                        startDateTime: currentStartDateTime,
                        startDateTimeOfFirstActivityWithDistance: currentStartDateTimeOfFirstActivityWithDistance,
                        endDateTime: currentEndDateTime,
                        endDateTimeOfLastActivityWithDistance: currentEndDateTimeOfLastActivityWithDistance,
                        totalDistance: currentDistance,
                    });
                }
                // start new current bus usage
                currentBusId = busId;
                currentStartDateTime = workItem.startDateTime;
                currentEndDateTime = workItem.endDateTime;
                currentDistance = workItem.distance ?? 0;
                if (workItem.distance && workItem.distance !== 0) {
                    currentStartDateTimeOfFirstActivityWithDistance = workItem.startDateTime;
                    currentEndDateTimeOfLastActivityWithDistance = workItem.endDateTime;
                } else {
                    currentStartDateTimeOfFirstActivityWithDistance = undefined;
                    currentEndDateTimeOfLastActivityWithDistance = undefined;
                }
            } else {
                // if the current bus usage matches the work item bus id, check whether there has been another usage of bus
                const busWorkItems = busWorkSheet?.workItems;
                if (busWorkItems && currentStartDateTime && currentEndDateTime && hasAnItemFromAnotherDriverWithinTimeframe(currentEndDateTime, workItem.startDateTime, busWorkItems, driverWorkSheetId)) {
                    // if another driver has used the bus in the meantime then create a bus usage
                    busUsages.push({
                        busId: currentBusId,
                        startDateTime: currentStartDateTime,
                        startDateTimeOfFirstActivityWithDistance: currentStartDateTimeOfFirstActivityWithDistance,
                        endDateTime: currentEndDateTime,
                        endDateTimeOfLastActivityWithDistance: currentEndDateTimeOfLastActivityWithDistance,
                        totalDistance: currentDistance,
                    });
                    currentBusId = busId;
                    currentStartDateTime = workItem.startDateTime;
                    currentEndDateTime = workItem.endDateTime;
                    currentDistance = workItem.distance ?? 0;
                    if (workItem.distance && workItem.distance !== 0) {
                        currentStartDateTimeOfFirstActivityWithDistance = workItem.startDateTime;
                        currentEndDateTimeOfLastActivityWithDistance = workItem.endDateTime;
                    } else {
                        currentStartDateTimeOfFirstActivityWithDistance = undefined;
                        currentEndDateTimeOfLastActivityWithDistance = undefined;
                    }
                } else {
                    // add to current bus usage
                    currentEndDateTime = workItem.endDateTime;
                    currentDistance += workItem.distance ?? 0;
                    if (workItem.distance && workItem.distance !== 0) {
                        if (!currentStartDateTimeOfFirstActivityWithDistance) {
                            currentStartDateTimeOfFirstActivityWithDistance = workItem.startDateTime;
                        }
                        if (!currentEndDateTimeOfLastActivityWithDistance || workItem.endDateTime > currentEndDateTimeOfLastActivityWithDistance) {
                            currentEndDateTimeOfLastActivityWithDistance = workItem.endDateTime;
                        }
                    }
                }
            }
        }
    });

    // add the last bus usage to usages
    if (currentBusId && currentStartDateTime && currentEndDateTime) {
        busUsages.push({
            busId: currentBusId,
            startDateTime: currentStartDateTime,
            startDateTimeOfFirstActivityWithDistance: currentStartDateTimeOfFirstActivityWithDistance,
            endDateTime: currentEndDateTime,
            endDateTimeOfLastActivityWithDistance: currentEndDateTimeOfLastActivityWithDistance,
            totalDistance: currentDistance,
        });
    }

    return busUsages;
};

const findReading = (busOdometerReadings: OdometerReadingWithBusId[], mandatoryPriorityDateTime: string, optionalFallbackDateTime: string | undefined) => {
    let result = findNearestReading(busOdometerReadings, mandatoryPriorityDateTime);
    if (!result && optionalFallbackDateTime) {
        result = findNearestReading(busOdometerReadings, optionalFallbackDateTime);
    }
    return result;
};

const findStartReading = (busOdometerReadings: OdometerReadingWithBusId[], busUsage: BusUsageWithDistance) => {
    return findReading(busOdometerReadings, busUsage.startDateTime, busUsage.startDateTimeOfFirstActivityWithDistance);
};

const findEndReading = (busOdometerReadings: OdometerReadingWithBusId[], busUsage: BusUsageWithDistance) => {
    return findReading(busOdometerReadings, busUsage.endDateTime, busUsage.endDateTimeOfLastActivityWithDistance);
};

export const getReadingRows = (workSheet: ApiWorkSheetDetails, busUsages: BusUsageWithDistance[], buses: Bus[], driverId?: number): OdometerReadingRow[] => {
    const sortedReadingsOfCurrentDriver = workSheet.odometerReadings
        .filter(reading => reading.driverId === driverId)
        .sort((a, b) => a.dateTime.localeCompare(b.dateTime));
    const readingRows: OdometerReadingRow[] = [];

    busUsages.forEach(busUsage => {
        const busOdometerReadings = getValidOdometerReadingsDuringBusUsage(sortedReadingsOfCurrentDriver, busUsage);
        const startReading = findStartReading(busOdometerReadings, busUsage);
        const previousReading = findPreviousReading(busOdometerReadings.filter(reading => reading.id !== startReading?.id), busUsage.startDateTime);
        const endReading = findEndReading(busOdometerReadings, busUsage);
        const licencePlateNr = buses.find(bus => bus.id === busUsage.busId)?.licencePlateNumber;

        busOdometerReadings.forEach(reading => {
            // don't include the previous reading as it is not relevant to the work sheet, only used in the difference calc
            if (!previousReading || reading.id !== previousReading.id) {
                if (startReading && reading.id === startReading.id) {
                    readingRows.push({...reading, type: 'Algnäit', licencePlateNumber: licencePlateNr});
                } else if (endReading && reading.id === endReading.id) {
                    const initialReading = startReading?.reading ?? previousReading?.reading ?? undefined;
                    const distanceDiff = initialReading ? endReading.reading - initialReading - busUsage.totalDistance : undefined;
                    readingRows.push({
                        ...reading,
                        type: 'Lõppnäit',
                        licencePlateNumber: licencePlateNr,
                        expectedTotalDistance: busUsage.totalDistance,
                        distanceDiff: distanceDiff,
                    })
                } else if (reading.fuelType) {
                    readingRows.push({...reading, type: 'Tankimine', licencePlateNumber: licencePlateNr});
                } else {
                    readingRows.push({...reading, type: 'Näit', licencePlateNumber: licencePlateNr});
                }
            }
        });
    });

    // Add extra readings if not added
    sortedReadingsOfCurrentDriver.forEach(reading => {
        if (
            dayjs(reading.dateTime) >= dayjs(workSheet.startDate).startOf('day')
            && !readingRows.some(readingRow => readingRow.id === reading.id)
        ) {
            const licencePlateNr = buses.find(bus => bus.id === reading.busId)?.licencePlateNumber;
            if (reading.fuelType) {
                readingRows.push({...reading, type: 'Tankimine', licencePlateNumber: licencePlateNr});
            } else {
                readingRows.push({...reading, type: 'Näit', licencePlateNumber: licencePlateNr});
            }
        }
    });

    return readingRows.sort((a, b) => a.dateTime.localeCompare(b.dateTime));
};

export const getValidOdometerReadingsDuringBusUsage = (odometerReadings: OdometerReadingWithBusId[], busUsage: BusUsage) => {
    const odometerReadingsOfTheCurrentBus = odometerReadings.filter(reading => reading.busId === busUsage.busId);
    const validReadings: OdometerReadingWithBusId[] = [];

    odometerReadingsOfTheCurrentBus.forEach(reading => {
        if (isTheItemWithinTimeframeWithBuffer(busUsage.startDateTime, busUsage.endDateTime, reading.dateTime)) validReadings.push(reading);
    });

    return validReadings;
};
