import {
    ResourceType,
    WorkGroupActivity,
    WorkGroupItemType,
    WorkGroupTripDefinition
} from "../../../API/types";
import {HOURS_IN_A_DAY, MAX_DRIVER_WORK_GROUP_DURATION_HOURS, MINUTES_IN_AN_HOUR} from "../../../constants";
import {
    PlannerItemStartAndEndHourWithType,
    PlannerItemStartAndEndHourWithTypeAndId,
    StartAndEndTimeWithModifierAndType,
    TripDefinitionWorkItem,
    WorkItem
} from "../types";
import {round} from "lodash";
import {
    TripDefinitionWorkItem as WorkScheduleTripDefinitionWorkItem,
    WorkItem as WorkScheduleWorkItem
} from "../../../store/workScheduleItemSlice";
import {Dayjs} from "dayjs";
import {getHhMmFromDate, getStartAndEndTimeWithModifierFromDateTimeStr} from "../../../utils/dateUtils";


export const getTimeInHours = (time: string, timeIsOnNextDay: boolean): number => {
    const hhMm = time.split(':');
    return round(Number(hhMm[0]) + (Number(hhMm[1]) / 60) + (timeIsOnNextDay ? HOURS_IN_A_DAY : 0), 2);
};

export const getTimeInHoursFromDate = (date: Date|Dayjs|string, isOnNextDay: boolean): number => {
    return getTimeInHours(getHhMmFromDate(date), isOnNextDay);
};

export const getSortedWorkGroupItemTimesWithIdAndType = (
    items: (WorkGroupTripDefinition | WorkGroupActivity | WorkItem | TripDefinitionWorkItem)[]
): PlannerItemStartAndEndHourWithTypeAndId[] => {
    return items
        .filter(item => item.id !== undefined)
        .map((item) => {
            return {
                id: item.id as number,
                type: 'type' in item ? item.type : WorkGroupItemType.TRIP_DEFINITION,
                startHh: getTimeInHours(item.startTime, item.startTimeIsOnNextDay),
                endHh: getTimeInHours(item.endTime, item.endTimeIsOnNextDay)
            }
        })
        .sort((a,b) => a.startHh - b.startHh);
};

export const getSortedWorkGroupItemTimes = (items: StartAndEndTimeWithModifierAndType[]): PlannerItemStartAndEndHourWithType[] => {
    return items
        .map((item) => {
            return {
                type: item.type,
                startHh: getTimeInHours(item.startTime, item.startTimeIsOnNextDay),
                endHh: getTimeInHours(item.endTime, item.endTimeIsOnNextDay)
            }
        })
        .sort((a,b) => a.startHh - b.startHh);
};

export const truncateStr = (name: string, maxLength: number) => {
    if (name.length <= maxLength) {
        return name;
    } else if (maxLength <= 1) {
        return name[0] + '.';
    } else {
        return name.slice(0, maxLength) + '..';
    }
};

export const getHhFromMin = (minutes: number) => 1 / MINUTES_IN_AN_HOUR * minutes;

export const doesDriverWorkDayLimitApply = (type: ResourceType, itemTypes: {type: WorkGroupItemType}[]): boolean =>
    type === ResourceType.DRIVER && !itemTypes.find(item => item.type === WorkGroupItemType.TIME_BETWEEN_SHIFTS)

export const isDurationOverDriverWorkDayLimit = (startHh: number, endHh: number) =>
    endHh - startHh > MAX_DRIVER_WORK_GROUP_DURATION_HOURS;

export const isDriverWorkDurationOverLimit = (
    type: ResourceType,
    startHh: number,
    endHh: number,
    itemTypes: {type: WorkGroupItemType}[]
): boolean => doesDriverWorkDayLimitApply(type, itemTypes) && isDurationOverDriverWorkDayLimit(startHh, endHh);

export const isDurationOverDriverWorkDayLimitWithNewItem = (
    firstItemStartHh: number | null, lastItemEndHh: number | null, itemStartHh: number, itemEndHh: number
) => {
    const newWorkGroupStartTime = !firstItemStartHh || itemStartHh < firstItemStartHh
        ? itemStartHh : firstItemStartHh;
    const newWorkGroupEndTime = !lastItemEndHh || itemEndHh > lastItemEndHh
        ? itemEndHh : lastItemEndHh;

    return isDurationOverDriverWorkDayLimit(newWorkGroupStartTime, newWorkGroupEndTime);
};

export const getUniqueOtherWorkGroupIds = (workGroupItems: (WorkGroupTripDefinition | WorkGroupActivity)[]) => {
    const uniqueOtherWorkGroupIds: number[] = [];

    workGroupItems.forEach(item => {
        if (item.workGroupsOfOppositeType && item.workGroupsOfOppositeType.length > 0) {
            if (!uniqueOtherWorkGroupIds.includes(item.workGroupsOfOppositeType[0].id)) {
                uniqueOtherWorkGroupIds.push(item.workGroupsOfOppositeType[0].id);
            }
        }
    });

    return uniqueOtherWorkGroupIds;
};

export const getPlannerWorkItem = (workItem: WorkScheduleWorkItem, date: Dayjs): WorkItem => ({
    ...workItem,
    ...getStartAndEndTimeWithModifierFromDateTimeStr(workItem.startDateTime, workItem.endDateTime, date)
});

export const getPlannerTripDefinitionWorkItem = (workItem: WorkScheduleTripDefinitionWorkItem, date: Dayjs): TripDefinitionWorkItem => ({
    ...workItem,
    ...getStartAndEndTimeWithModifierFromDateTimeStr(workItem.startDateTime, workItem.endDateTime, date)
});
