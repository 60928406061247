import {ResourceType} from "../../../../API/types";
import {Box, Typography} from "@mui/material";
import {isOnWeekend} from "../../../../utils/utils";
import {grey, red} from "@mui/material/colors";
import React, {memo, useMemo} from "react";
import {isOnPublicHoliday,} from "../utils";
import CustomCell from "./components/CustomCell";
import {CollapseButton} from "./components/CollapseButton";
import {useLocalStorage} from "usehooks-ts";
import {useAppSelector, useWorkScheduleSelectedDay} from "../../../../hooks";
import UnscheduledWorkGroupsRow from "./components/UnscheduledWorkGroupsRow";
import {selectCurrentMonthDays, selectMonth, selectPrevWeekDays} from "../store/selectors";
import DriverRows from "./components/DriverRows";
import BusRows from "./components/BusRows";
import {selectAllCalendarEntries} from "../../../../store/calendarEntriesSlice";
import {selectToggledResourceType} from "../../../../store/viewSlice";
import dayjs from "dayjs";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import {Weekday} from "../../../../utils/dateUtils";
import EditModeTooltipWrapper from "./components/EditModeTooltipWrapper";
import EditModeRow from "./components/EditModeRow";

export const disabledBackground = grey[100];
export const borderColor = grey[300];

const UNIT = 16;
export const COLLAPSED_COLUMN_WIDTH = 1.5 * UNIT;
export const DEFAULT_CELL_WIDTH = 2.75 * UNIT;
export const LARGE_CELL_WIDTH = 3.25 * UNIT;
export const SMALL_CELL_WIDTH = 1.5 * UNIT;
export const NAME_CELL_WIDTH = 8 * UNIT;

export const collapsedColumnSx = {
    borderTop: 'none',
    backgroundColor: disabledBackground,
    minWidth: COLLAPSED_COLUMN_WIDTH,
    maxWidth: COLLAPSED_COLUMN_WIDTH,
    borderBottom: 'none',
    borderLeft: '2px solid',
    borderRight: '3px solid',
    borderColor: '#ddd',
    flexGrow: 0,
    displayPrint: 'none',
};

function WorkScheduleTable() {
    const resourceType = useAppSelector(selectToggledResourceType);
    const sortingEnabled = resourceType === ResourceType.DRIVER;

    const [showExtra, setShowExtra] = useLocalStorage('workScheduleShowExtra', false);
    const [showPrevWeek, setShowPrevWeek] = useLocalStorage('workScheduleShowPrevWeek', false);

    const calendarEntries = useAppSelector(selectAllCalendarEntries);
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const selectedMonth = useAppSelector(selectMonth);

    const {selectedDay, handleSelectDay} = useWorkScheduleSelectedDay();

    const prevWeekDays = useAppSelector(selectPrevWeekDays);
    const currentMonthDays = useAppSelector(selectCurrentMonthDays);

    const statsWidth = useMemo(() => resourceType === ResourceType.DRIVER ? 3 * LARGE_CELL_WIDTH : 0, [resourceType]);
    const extraColumnsWidth = useMemo(() => resourceType === ResourceType.DRIVER ? (showExtra ? 6 * DEFAULT_CELL_WIDTH : COLLAPSED_COLUMN_WIDTH) : 0, [showExtra, resourceType]);
    const prevWeekColumnsWidth = useMemo(() => showPrevWeek ? prevWeekDays.length * DEFAULT_CELL_WIDTH : COLLAPSED_COLUMN_WIDTH, [showPrevWeek, prevWeekDays]);
    const nameColumnsWidth = useMemo(() => NAME_CELL_WIDTH + (sortingEnabled ? SMALL_CELL_WIDTH : 0), [sortingEnabled]);
    // Column width for unscheduled work groups label cell
    const unscheduledWorkgroupsLabelColumnWidth =  nameColumnsWidth + statsWidth + extraColumnsWidth + prevWeekColumnsWidth;

    const renderHeader = (hideRotatedLabels?: boolean) => (
        <Box sx={{
            display: 'flex',
            '& > *': {borderBottomWidth: 1},
        }} role="row">
            <CustomCell width={`${nameColumnsWidth}px`} sx={{backgroundColor: 'common.white'}} content=""/>
            {statsWidth > 0 &&
                <>
                    <CustomCell width={LARGE_CELL_WIDTH} sx={{backgroundColor: 'common.white'}} content="N"
                                tooltip="Nominaaltunnid" header />
                    <CustomCell width={LARGE_CELL_WIDTH} sx={{backgroundColor: 'common.white'}} content="P"
                                tooltip="Planeeritud tunnid" header />
                    <CustomCell width={LARGE_CELL_WIDTH} sx={{backgroundColor: 'common.white'}} content="Ü/A"
                                tooltip="Üle-/alatunnid" header />
                </>
            }

            {extraColumnsWidth > 0 && (
                showExtra ?
                    <>
                        <CustomCell width={DEFAULT_CELL_WIDTH} content="L" tooltip="Lepingu tüüp"
                                    sx={{borderLeftWidth: '2px', backgroundColor: 'common.white'}} header />
                        <CustomCell width={DEFAULT_CELL_WIDTH} content="K" tooltip="Koormus"
                                    sx={{backgroundColor: 'common.white'}} header />
                        <CustomCell width={DEFAULT_CELL_WIDTH} content="TP" tooltip="Tööpäevade arv"
                                    sx={{backgroundColor: 'common.white'}} header />
                        <CustomCell width={DEFAULT_CELL_WIDTH} content="KP" tooltip="Keskmine tööpäeva pikkus"
                                    sx={{backgroundColor: 'common.white'}} header />
                        <CustomCell width={DEFAULT_CELL_WIDTH} content="NV"
                                    tooltip="Tööpäevi nädalavahetusel või riigipühal"
                                    sx={{backgroundColor: 'common.white'}} header />
                        <CustomCell width={DEFAULT_CELL_WIDTH} content="R" tooltip="Reservipäevi"
                                    sx={{backgroundColor: 'common.white'}} header
                                    button={
                                        <CollapseButton
                                            onClick={() => setShowExtra(false)}
                                            text="Peida lisainfo" isOpen={true}
                                        />
                                    }/>
                    </> :
                    <CustomCell
                        rotatedColumn={true}
                        content={!hideRotatedLabels ? <Box>Lisainfo</Box> : ''}
                        button={<CollapseButton onClick={() => setShowExtra(true)}
                                                text="Ava lisainfo"
                                                isOpen={false}/>}
                        sx={{...collapsedColumnSx, borderRightWidth: '1px'}}
                    />
            )}
            {showPrevWeek ?
                prevWeekDays.map((day, index) =>
                    <CustomCell
                        key={day.toString()}
                        width={DEFAULT_CELL_WIDTH}
                        sx={{
                            backgroundColor: disabledBackground,
                            borderLeftWidth: index === 0 ? '2px' : undefined,
                            borderRightWidth: prevWeekDays.length === index + 1 ? '3px' : undefined,
                        }}
                        content={<>
                            <Typography color={isOnWeekend(day) ? red[500] : undefined}>
                                {Weekday.get(day.day())}
                            </Typography>
                            <Typography
                                color={isOnPublicHoliday(day, calendarEntries ?? []) ? red[500] : undefined}>
                                {day.format('DD')}
                            </Typography>
                        </>}
                        button={prevWeekDays.length === index + 1 ?
                            <CollapseButton onClick={() => setShowPrevWeek(false)}
                                            text="Peida eelnev nädal" isOpen={true}/>
                            : null
                        }
                    />
                )
                :
                <CustomCell
                    rotatedColumn={true}
                    content={!hideRotatedLabels ? <Box>Eelnev nädal</Box> : ''}
                    button={<CollapseButton onClick={() => setShowPrevWeek(true)}
                                            text="Ava eelnev nädal" isOpen={false}/>}
                    sx={collapsedColumnSx}
                />
            }
            {currentMonthDays.map((day) =>
                <CustomCell
                    key={day.toString()}
                    sx={{
                        minWidth: DEFAULT_CELL_WIDTH,
                        backgroundColor: selectedDay && selectedDay !== day.format('YYYY-MM-DD') ? disabledBackground : 'common.white',
                    }}
                    onClick={handleSelectDay(day)}
                    content={<>
                        <Typography component="div" color={isOnWeekend(day) ? red[500] : undefined}>
                            {Weekday.get(day.day())}
                        </Typography>
                        <Typography
                            component="div"
                            color={isOnPublicHoliday(day, calendarEntries ?? []) ? red[500] : undefined}>
                            {day.format('DD')}
                        </Typography>
                    </>}
                />
            )}
        </Box>
    );

    return (
        <>
            <EditModeTooltipWrapper>
                <Box sx={{
                    '@media not print': {overflow: 'auto'},
                    '@media print': {overflow: 'visible'},
                }} role="table">
                    <Box sx={{top: 0, position: 'sticky', zIndex: 1}}>
                        <Box sx={{
                            justifyContent: 'space-between',
                            displayPrint: 'flex',
                            display: 'none',
                        }}>
                            <Typography component="div">{dayjs(selectedMonth).format('MMMM YYYY')}</Typography>
                            <Typography component="div">{selectedRegion?.name ?? ''}</Typography>
                        </Box>
                        {renderHeader()}
                    </Box>
                    <Box>
                        {resourceType === ResourceType.DRIVER
                            ? <DriverRows />
                            : <BusRows />
                        }
                    </Box>
                    <Box sx={{displayPrint: 'none', position: 'sticky', bottom: 0}}>
                        {renderHeader(true)}
                        <UnscheduledWorkGroupsRow
                            currentMonthDays={currentMonthDays}
                            labelColumnWidth={unscheduledWorkgroupsLabelColumnWidth}
                        />
                    </Box>
                </Box>
            </EditModeTooltipWrapper>
            <EditModeRow />
        </>
    );
}

export default memo(WorkScheduleTable);
