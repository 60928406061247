import React from 'react';
import {Box, IconButton, Typography} from "@mui/material";
import routes from "../../../../routes";
import {getStartAndEndPointLabel} from "../../../../utils/utils";
import Repetition from "../../../RepetitionRules";
import TooltipRowWithLink from "./TooltipRowWithLink";
import {DisplayTripDefinitionWorkItem, DisplayWorkGroupActivity, PlannerType, DisplayWorkItem} from "../../types";
import {RepetitionRules, ResourceType, WorkGroupTripDefinition} from '../../../../API/types';
import {useAppSelector} from "../../../../hooks";
import {selectResourceNameByWorkSheetId} from "../../store/selectors";
import {selectPlannerType} from "../../../../store/plannerSlice";
import {selectToggledResourceType} from "../../../../store/viewSlice";
import {Delete, Edit} from "@mui/icons-material";
import {getWorkGroupItemTypeTranslation} from "../../../../utils/enumTranslations";
import {getDisplayTimeWithModifier} from "../../../../utils/dateUtils";
import ActionIcon from "../../../Icons/ActionIcon";


interface ItemCardTooltipTextProps {
    item: WorkGroupTripDefinition | DisplayWorkGroupActivity | DisplayWorkItem | DisplayTripDefinitionWorkItem;
    handleActivityEdit?: () => void;
    handleActivityDelete?: () => void;
    handleTripMerge?: () => void;
    handleTripSplit?: () => void;
    isRowDisabled?: boolean;
}

const ItemCardTooltipText = ({
    item,
    handleActivityEdit,
    handleActivityDelete,
    handleTripSplit,
    handleTripMerge,
    isRowDisabled = false
}: ItemCardTooltipTextProps) => {
    const renderRow = (text: string) => <Typography color="common.white" fontSize="12px">{text}</Typography>;
    const plannerType = useAppSelector(selectPlannerType);
    const startAndEndPoint = 'route' in item && item.route ? getStartAndEndPointLabel(item.route) : undefined;

    const getOppositeResource = () => {
        if (plannerType === PlannerType.WORK_GROUP) {
            const oppositeTypeWorkGroup = 'workGroupsOfOppositeType' in item && item.workGroupsOfOppositeType
            && item.workGroupsOfOppositeType.length > 0 ? item.workGroupsOfOppositeType[0] : undefined;

            if (oppositeTypeWorkGroup) {
                return {
                    text: oppositeTypeWorkGroup.code,
                    route: routes.AUTHENTICATED.WORK_GROUPS.EDIT.replace(':id', oppositeTypeWorkGroup.id.toString()),
                    type: oppositeTypeWorkGroup.type
                }
            }
        }
        if (plannerType === PlannerType.WORK_SHEET) {
            const oppositeTypeWorkSheetId = 'workSheetsOfOppositeType' in item && item.workSheetsOfOppositeType
                && item.workSheetsOfOppositeType.length > 0 ? item.workSheetsOfOppositeType[0] : undefined;
            const toggledResourceType = useAppSelector(selectToggledResourceType);
            const resourceName = oppositeTypeWorkSheetId
                ? useAppSelector(state => selectResourceNameByWorkSheetId(state, oppositeTypeWorkSheetId)) : undefined;

            if (oppositeTypeWorkSheetId && resourceName) {
                return {
                    text: resourceName,
                    route: routes.AUTHENTICATED.WORK_SHEETS.DETAILS.replace(':id', oppositeTypeWorkSheetId.toString()),
                    type: toggledResourceType === ResourceType.DRIVER ? ResourceType.VEHICLE : ResourceType.DRIVER
                }
            }
        }
    };

    const oppositeResource = getOppositeResource();

    const iconButtonStyle = {'&:hover': {backgroundColor: 'action.focus'}};

    const renderRepetition = (repetition: RepetitionRules) => {
        return (
            <Typography component="div" color="common.white">
                <Repetition repetition={repetition} fontSize="small"/>
            </Typography>
        );
    };

    return (
        <Box px={0.5} py={1}>
            {!isRowDisabled && (handleActivityEdit || handleActivityDelete) &&
                <Box sx={{display: 'flex', justifyContent: 'flex-end', pb: 1}}>
                    {handleActivityEdit &&
                        <IconButton size="small" onClick={handleActivityEdit} aria-label="Muuda" sx={iconButtonStyle}>
                            <Edit sx={{fontSize: '16px', color: 'common.white'}} />
                        </IconButton>
                    }
                    {handleActivityDelete &&
                        <IconButton size="small" onClick={handleActivityDelete} aria-label="Kustuta" sx={iconButtonStyle}>
                            <Delete sx={{fontSize: '16px', color: 'common.white'}} />
                        </IconButton>
                    }
                </Box>
            }
            {plannerType === PlannerType.WORK_GROUP && (handleTripSplit || handleTripMerge) &&
				<Box sx={{display: 'flex', justifyContent: 'flex-end', pb: 1}}>
                    {handleTripMerge && <ActionIcon type="MERGE" id={item.id} handleClick={handleTripMerge} color="common.white" />}
                    {handleTripSplit && <ActionIcon type="SPLIT" id={item.id} handleClick={handleTripSplit} color="common.white" />}
                </Box>
            }
            {'code' in item && item.code && item.tripId ?
				<TooltipRowWithLink
					text={item.code}
					route={routes.AUTHENTICATED.TRIPS.EDIT.replace(':id', item.tripId.toString())}
				/>
                :
                ('type' in item ? renderRow(getWorkGroupItemTypeTranslation(item.type)) : '')
            }
            {renderRow(
                getDisplayTimeWithModifier(item.startTime, item.startTimeIsOnNextDay) + ' - '
                    + getDisplayTimeWithModifier(item.endTime, item.endTimeIsOnNextDay)
            )}
            {startAndEndPoint && renderRow(startAndEndPoint)}
            {item.distance !== null && item.distance > 0 && renderRow(`${item.distance}km`.replace('.', ','))}
            {'repetition' in item && item.repetition
                ? renderRepetition(item.repetition)
                : 'tripDefinitionRepetition' in item && item.tripDefinitionRepetition && renderRepetition(item.tripDefinitionRepetition)
            }
            {oppositeResource &&
                <TooltipRowWithLink
                    text={oppositeResource.text}
                    route={oppositeResource.route}
                    resourceType={oppositeResource.type}
					padTop
                />
            }
        </Box>
    );

}

export default ItemCardTooltipText;
