import {Region} from "../../../../../API/region/types";
import {useAppSelector} from "../../../../../hooks";
import {selectAllRegions} from "../../../../../store/regionSlice";
import {Box, TextField} from "@mui/material";
import theme from "../../../../../theme";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import FilterChip from "../../../../../components/FilterChip";

interface FilterToolbarProps {
    searchInput: string;
    setSearchInput: (searchInput: string) => void;
    searchRegions: Region[];
    setSearchRegions: (regions: Region[]) => void;
    showAllComments: boolean;
    setShowAllComments: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilterToolbar = ({searchInput, setSearchInput, searchRegions, setSearchRegions, showAllComments, setShowAllComments}: FilterToolbarProps) => {
    const regions = useAppSelector(selectAllRegions);

    return (
        <Box sx={{
            border: '1px solid',
            borderBottom: 'none',
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.action.hover,
            px: 1,
            py: 1.5,
            borderRadius: '5px 5px 0 0',
            mb: '-5px',
            display: 'flex',
            alignItems: {xs: 'flex-start', md: 'center'},
            flexDirection: {xs: 'column', md: 'row'},
            gap: 1,
        }}>
            <Box sx={{
                boxSizing: 'border-box',
                width: {xs: '100%', md: 'fit-content'},
                display: 'flex',
                flexDirection: {xs: 'column', sm: 'row'},
                gap: {xs: 2, sm: 1},
                pr: {xs: 0, sm: 1, md: 0}
            }}>
                <TextField
                    value={searchInput}
                    label="Otsing"
                    onChange={(event) => setSearchInput(event.target.value)}
                    InputLabelProps={{shrink: true}}
                    InputProps={{sx: {height: 40, backgroundColor: theme.palette.background.paper}}}
                    sx={{minWidth: {xs: '100%', sm: '40%', md: 200}, maxWidth: '100%'}}
                />
                <Autocomplete
                    multiple
                    options={regions}
                    size="small"
                    getOptionLabel={(option) => option.name}
                    value={searchRegions}
                    onChange={(_, newValue) => {
                        setSearchRegions([...newValue]);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Piirkond"
                            InputLabelProps={{shrink: true}}
                        />
                    )}
                    sx={{minWidth: {xs: '100%', sm: '60%', md: 300}, maxWidth: '100%', backgroundColor: theme.palette.background.paper}}
                />
            </Box>
            <FilterChip selected={showAllComments} setSelected={setShowAllComments} text="Kuva kommentaarid" />
        </Box>
    );
};

export default FilterToolbar;