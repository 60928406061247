import React from 'react';
import Dialog from '@mui/material/Dialog';
import {Box} from "@mui/material";
import WorkGroupEditFormWrapper, {getFormType} from "../../../../../scenes/authenticated/workGroups/detailsView/components/WorkGroupEditFromWrapper";
import {WorkGroup} from '../../../../../API/types';
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {
    addOrReplaceWorkGroup,
    clearDialogData,
    removeWorkGroup,
    selectDialogData,
    updateWorkGroup
} from "../../../../../store/plannerSlice";
import {selectDate, selectToggledResourceType} from "../../../../../store/viewSlice";


export default function WorkGroupDetailsDialog() {
    const dispatch = useAppDispatch();
    const selectedDate = useAppSelector(selectDate).toDate();
    const toggledResourceType = useAppSelector(selectToggledResourceType);
    const addOrEditWorkGroupDetailsDialogData = useAppSelector(selectDialogData).addOrEditWorkGroupDetails;
    if (!addOrEditWorkGroupDetailsDialogData) return <></>;

    const handleCloseDialog = () => dispatch(clearDialogData());

    const handleSuccessfulCreate = (workGroup?: WorkGroup, oldVersionWorkGroupId?: number) => {
        if (workGroup && workGroup.type === toggledResourceType) {
            dispatch(addOrReplaceWorkGroup({newWorkGroup: workGroup, oldVersionWorkGroupId: oldVersionWorkGroupId}));
        }
    }

    const handleSuccessfulEdit = (workGroup: WorkGroup) => {
        if (workGroup.type === toggledResourceType) {
            dispatch(updateWorkGroup([workGroup]));
        } else {
            dispatch(removeWorkGroup(workGroup.id));
        }
        handleCloseDialog();
    };

    return (
        <Dialog open={!!addOrEditWorkGroupDetailsDialogData} onClose={handleCloseDialog}>
            <Box sx={{width: '450px', maxWidth: '100%', mt: 1, p: {xs: 0, sm: 3}}}>
                <WorkGroupEditFormWrapper
                    workGroup={addOrEditWorkGroupDetailsDialogData.workGroup}
                    handleSuccessfulEdit={handleSuccessfulEdit}
                    handleSuccessfulCreate={handleSuccessfulCreate}
                    useDialogVariant
                    handleCancelButtonPress={handleCloseDialog}
                    selectedDate={selectedDate}
                    shouldCreateNewVersion={addOrEditWorkGroupDetailsDialogData.shouldCreateNewVersion}
                    formType={getFormType(
                        addOrEditWorkGroupDetailsDialogData.workGroup,
                        undefined,
                        addOrEditWorkGroupDetailsDialogData.shouldCreateNewVersion
                    )}
                    selectedVersion={undefined}
                    setSelectedVersion={() => {}}
                />
            </Box>
        </Dialog>
    );
}
