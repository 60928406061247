import {createAsyncThunk, createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {TransportContract} from "../API/types";
import {loadTransportContracts} from "../API";
import {RootState} from "../store";
import {setToast} from "./toastSlice";
import {mapErrors} from "../utils/errorMapping";


const transportContractAdapter = createEntityAdapter<TransportContract>({
    sortComparer: (a, b) => a.contractId.localeCompare(b.contractId),
});

export const fetchTransportContracts = createAsyncThunk(
    'transportContracts/getAll',
    async (_, { dispatch }) => {
        return loadTransportContracts()
            .then(result => {
                return result;
            })
            .catch(apiError => {
                dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Veolepingute laadimisel tekkis viga'}));
                throw Error;
            });
    }
);

const initialState: EntityState<TransportContract> = transportContractAdapter.getInitialState();

export const transportContractSlice = createSlice({
    name: 'transportContracts',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTransportContracts.fulfilled, (state, action) => {
            transportContractAdapter.setAll(state, action.payload);
        });
    },
});

export const {
    selectAll: selectTransportContracts,
} = transportContractAdapter.getSelectors<RootState>(state => state.transportContracts);

export default transportContractSlice.reducer;
