import React, {useContext, useEffect, useState} from "react";
import {Box} from "@mui/material";
import routes from "../../../../routes";
import {ToastContext} from "../../../../contexts/ToastContext";
import {useParams} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import {WorkGroup, WorkGroupVersion} from "../../../../API/types";
import Loader from "../../../../components/Loader";
import WorkGroupItems from "./components/WorkGroupItems";
import WorkGroupEditFormWrapper, {getFormType} from "./components/WorkGroupEditFromWrapper";
import {findClosestItemByValidRange} from "../../../../utils/dateUtils";
import {sortByStartTime} from "../../../../utils/utils";
import {loadWorkGroupDetails as apiLoadWorkGroupDetails} from "../../../../API";


interface StateData {
    previousWorkGroupVersion: WorkGroup | undefined | null;
}

export default function WorkGroupDetails() {
    const { id } = useParams<{ id: string }>();
    const { addToast } = useContext(ToastContext);
    const navigate = useNavigate();
    const location = useLocation();
    const stateData = location.state as StateData;
    const [workGroup, setWorkGroup] = useState<WorkGroup | null | undefined>(undefined);
    const [selectedVersion, setSelectedVersion] = useState<WorkGroupVersion | undefined>(undefined);

    useEffect(() => {
        if (id) {
            loadWorkGroupDetails(id);
        } else if (stateData?.previousWorkGroupVersion) {
            loadWorkGroupDetails(stateData.previousWorkGroupVersion.id.toString());
        } else {
            setWorkGroup(null);
        }
    }, [id]);

    const loadWorkGroupDetails = (id: string) => {
        apiLoadWorkGroupDetails(id)
            .then(result => {
                setWorkGroup(result);
                setSelectedVersion(findClosestItemByValidRange(result?.versions ?? []));
            })
            .catch(() => {
                addToast({type: 'error', text: 'Töögrupi pärimisel ilmnes viga'});
                navigate(routes.AUTHENTICATED.WORK_GROUPS.ROOT);
            });
    };

    const handleSuccessfulEdit = (workGroup: WorkGroup) => setWorkGroup(workGroup);

    const handleSuccessfulCreate = () => navigate(routes.AUTHENTICATED.WORK_GROUPS.ROOT);

    return (workGroup !== undefined ?
        <Box p={{xs: 1, sm: 0}}>
            <WorkGroupEditFormWrapper
                workGroup={workGroup}
                handleSuccessfulEdit={handleSuccessfulEdit}
                handleSuccessfulCreate={handleSuccessfulCreate}
                shouldCreateNewVersion={!!stateData?.previousWorkGroupVersion}
                formType={getFormType(workGroup, stateData)}
                selectedVersion={selectedVersion}
                setSelectedVersion={setSelectedVersion}
            />
            <WorkGroupItems
                items={selectedVersion ? [...selectedVersion.tripDefinitions, ...selectedVersion.activities].sort(sortByStartTime) : []}
            />
        </Box>
        :
        <Loader />
    );
}