import React, {useContext, useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {TransportContract, UpdateTransportContractRequest} from '../../../../API/types';
import {TransportContractForm} from './types';
import {useParams} from 'react-router-dom';
import Loader from "../../../../components/Loader";
import axios from "axios";
import {ToastContext} from "../../../../contexts/ToastContext";
import { Formik, FormikHelpers } from 'formik';
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {dateValidTo, validationSchema} from "../../../../utils/formValidation";
import routes from '../../../../routes';
import TransportContractEditForm from "./TransportContractEditForm";
import {decimalToDisplayStr, strToDecimal} from "../../../../utils/utils";
import {useAppSelector} from "../../../../hooks";
import {selectAllRegions} from "../../../../store/regionSlice";
import {getDateString} from "../../../../utils/dateUtils";


const transportContractValidationSchema = Yup.object().shape({
    contractId: validationSchema('Lepingu number').fields.shortTextFieldRequired,
    dateOfSigning: validationSchema('Kuupäev').fields.dateRequired,
    partnerName: validationSchema('Parteni nimi').fields.shortTextFieldRequired,
    profitCenter: validationSchema('Tulemusüksus').fields.varcharTextFieldRequired,
    monthlyPay: validationSchema('Kuupalk').fields.numberTextFieldRequired,
    hourlyRate: validationSchema('Tunnitasu').fields.numberTextFieldRequired,
    validFrom: validationSchema('Kuupäev').fields.dateRequired,
    validTo: dateValidTo('Lõppkuupäev', 'validFrom'),
    regionName: validationSchema('Piirkond').fields.textFieldRequired,
    comment: validationSchema('Kommentaar').fields.longTextField,
});

const defaultFormValues: TransportContractForm = {
    contractId: '',
    dateOfSigning: null,
    partnerName: '',
    profitCenter: '',
    monthlyPay: '',
    hourlyRate: '',
    validFrom: null,
    validTo: null,
    comment: '',
    regionName: '',
    active: true
}

export default function TransportContractDetails() {
    const navigate = useNavigate();
    const { addToast } = useContext(ToastContext);
    const regions = useAppSelector(selectAllRegions);
    const { id } = useParams<{ id: string }>();
    const [transportContract, setTransportContract] = useState<TransportContract | undefined>(undefined);
    const [initialValues, setInitialValues] = useState<TransportContractForm | undefined>(undefined);

    useEffect(() => {
        if (id === undefined) {
            setInitialValues(defaultFormValues);
        } else {
            axios.get<TransportContract>(`/api/transport-contracts/${id}`)
                .then(result => {
                    if (result.status == 200) {
                        setTransportContract(result.data);
                    } else {
                        addToast({type: 'error', text: 'Veolepingu pärimisel ilmnes viga'});
                        navigate(routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ROOT);
                    }
                })
                .catch(() => {
                    addToast({type: 'error', text: 'Veolepingu pärimisel ilmnes viga'});
                    navigate(routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ROOT);
                });
        }
    }, [id]);

    useEffect(() => {
        if (transportContract) {
            setInitialValues({
                ...transportContract,
                monthlyPay: decimalToDisplayStr(transportContract.monthlyPay),
                hourlyRate: decimalToDisplayStr(transportContract.hourlyRate),
                dateOfSigning: new Date(transportContract.dateOfSigning),
                validFrom: new Date(transportContract.validFrom),
                validTo: transportContract.validTo ? new Date(transportContract.validTo) : null,
                regionName: transportContract.regionName ?? '',
                comment: transportContract.comment ?? '',
            });
        }
    }, [transportContract]);

    const onSubmit = (form: TransportContractForm, formHelpers: FormikHelpers<TransportContractForm>) => {
        if (form.dateOfSigning && form.validFrom) {
            const request: UpdateTransportContractRequest = {
                contractId: form.contractId,
                dateOfSigning: getDateString(form.dateOfSigning),
                partnerName: form.partnerName,
                profitCenter: form.profitCenter,
                monthlyPay: form.monthlyPay?.length ? strToDecimal(form.monthlyPay) : 0,
                hourlyRate: form.hourlyRate?.length ? strToDecimal(form.hourlyRate) : 0,
                validFrom: getDateString(form.validFrom),
                validTo: form.validTo ? getDateString(form.validTo) : null,
                regionName: form.regionName && form.regionName !== '' ? form.regionName : null,
                comment: form.comment ?? null,
                active: form.active,
            }
            if (id) {
                axios.put(`/api/transport-contracts`, {id: id, ...request})
                    .then(result => {
                        if (result.status == 200) {
                            addToast({type: 'success', text: 'Veoleping edukalt uuendatud'});
                            setTransportContract({id: Number(id), ...request, regionName: request.regionName ?? ''});

                        } else {
                            addToast({type: 'error', text: 'Veolepingu uuendamisel ilmnes viga'});
                            formHelpers.setSubmitting(false);
                        }
                    })
                    .catch(() => {
                        addToast({type: 'error', text: 'Veolepingu uuendamisel ilmnes viga'});
                        formHelpers.setSubmitting(false);

                    });
            } else {
                axios.post('/api/transport-contracts', request)
                    .then(result => {
                        if (result.status == 200) {
                            addToast({type: 'success', text: 'Veoleping edukalt loodud'});
                            navigate(routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ROOT);
                        } else {
                            addToast({type: 'error', text: 'Veolepingu lisamisel ilmnes viga'});
                        }
                    })
                    .catch(() => {
                        addToast({type: 'error', text: 'Veolepingu lisamisel ilmnes viga'});
                    })
                    .finally(() => formHelpers.setSubmitting(false));
            }
        }
    };

    return (initialValues && regions ?
        <Box p={{xs: 1, sm: 0}}>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={transportContractValidationSchema} onSubmit={onSubmit}>
                <TransportContractEditForm transportContract={transportContract} id={id} defaultFormValues={defaultFormValues} regions={regions} />
            </Formik>
        </Box>
        :
        <Loader />
    );
}