import React from 'react';
import {ResourceType} from "../../../../API/types";
import {Link, Typography} from "@mui/material";
import {Badge, DirectionsBus} from "@mui/icons-material";


interface TooltipRowWithLinkProps {
    text: string;
    route: string;
    resourceType?: ResourceType | null;
    padTop?: boolean;
}

const TooltipRowWithLink = ({text, route, resourceType, padTop}: TooltipRowWithLinkProps) => {
    return (
        <Typography color="common.white" fontSize="12px">
            <Link
                target="_blank"
                href={route}
                color="common.white"
                sx={{display: 'flex', alignItems: 'center', pt: padTop ? 1 : 0}}
            >
                {resourceType && resourceType === ResourceType.VEHICLE &&
					<DirectionsBus sx={{fontSize: '14px', pr: 0.5}} />}
                {resourceType && resourceType === ResourceType.DRIVER &&
					<Badge sx={{fontSize: '14px', pb: 0.5, pr: 0.5}} />}
                {text}
            </Link>
        </Typography>
    );
};

export default TooltipRowWithLink;
