import {createEntityAdapter, createSelector, createSlice, EntityId, EntityState} from "@reduxjs/toolkit";
import {WorkScheduleItemStatus, WorkScheduleItemType} from "../API/workSchedule/types";
import {WorkGroupItemType, ResourceType} from "../API/workGroup/types";
import {RootState} from "../store";
import {
    addWorkScheduleItem,
    changeWorkScheduleItem,
    confirmWorkSchedule,
    removeWorkScheduleItem
} from "../scenes/authenticated/workSchedule/store/actions";
import {fetchWorkSchedule} from "./workScheduleSlice";
import {
    createWorkSheet,
    fetchWorkSheets,
    generateWorkSheet,
    updateWorkSheet
} from "../scenes/authenticated/workSheets/store/actions";
import {DefectPriority, RepetitionRules, RoutePoint} from "../API/types";
import {
    addActivity,
    addOppositeWorkSheetToActivity,
    addOtherRegionUnassignedWorkItem,
    addPreparationAndFinishingTime,
    addWorkSheet,
    deleteWorkSheetActivity,
    editActivity,
    fetchWorkGroupPlannerData,
    fetchWorkSheetPlannerData,
    selectPlannerType,
} from "./plannerSlice";
import {selectDate, selectToggledResourceType} from "./viewSlice";
import {getPlannerTripDefinitionWorkItem, getPlannerWorkItem} from "../components/Planner/utils/utils";
import {
    PlannerType,
    WorkItem as PlannerWorkItem,
    TripDefinitionWorkItem as PlannerTripDefinitionWorkItem
} from "../components/Planner/types";
import {selectSelectedDay} from "../scenes/authenticated/workSheets/store/selectors";
import {WorkScheduleItemWithId} from "../components/Planner/components/Dialogs/AddOppositeWorkSheet/types";
import {selectResourceNameByWorkSheetId} from "../components/Planner/store/selectors";
import {DefectSimple} from "../API/defects/types";
import {selectAllBuses, selectBusById} from "./busSlice";
import {BusWithDefects, BusWithDefectsAndHighestPriority} from "../scenes/authenticated/workSheets/types";
import {getHighestPriorityDefect, isPriorityHigher} from "../scenes/authenticated/workSheets/listView/BusesWithDefects";


export interface WorkScheduleItem {
    id?: number;
    type: WorkScheduleItemType;
    workGroupId?: number;
    resourceId?: number;
    resourceType: ResourceType;
    startDate: string;
    endDate: string;
    regionId: number;
    status: WorkScheduleItemStatus;
    workItemIds?: EntityId[];
    comment: string;
    confirmedAt?: string | null;
    driverComment?: string;
}

export interface WorkScheduleItemWithHours extends WorkScheduleItem {
    workGroupCode: string;
    startDateTime: string;
    endDateTime: string;
    workGroupStartDateTime?: string;
    workGroupEndDateTime?: string;
    hours: number;
    distance: number;
    isReserveItem: boolean;
}

export interface UnscheduledWorkGroup {
    workScheduleItemId?: number;
    id: number;
    code: string;
    date: string;
    regionId: number;
    workScheduleItemStartDateTime: string;
    workScheduleItemEndDateTime: string;
    hours: number;
    workGroupStartDateTime?: string;
    workGroupEndDateTime?: string;
}

export interface WorkItem {
    id: number;
    type: WorkGroupItemType;
    startDateTime: string;
    endDateTime: string;
    distance: number;
    comment: string | null;

    tripId?: number;
    tripSegmentId?: number;
    activityId?: number;
    route?: RoutePoint[] | null;
    code?: string;
    regionId?: number;
    workSheetsOfOppositeType?: number[];
    tripDefinitionRepetition?: RepetitionRules | null;
}

export interface TripDefinitionWorkItem {
    id: number; // tripSegmentId
    tripId: number;
    workItemId?: number;
    startDateTime: string;
    endDateTime: string;
    distance: number;
    comment: string | null;
    route?: RoutePoint[] | null;
    code?: string;
    regionId: number;
    workSheetsOfOppositeType?: number[];
    repetition?: RepetitionRules | null;
}

export const getWorkScheduleItemId = (id: EntityId) => {
    const workScheduleItemGroupId = /^(\d+)-/.exec(id.toString());
    return workScheduleItemGroupId ? workScheduleItemGroupId[1] : undefined;
};
export const getWorkScheduleItemIdAsNr = (id: EntityId): number | undefined => {
    const workScheduleItemGroupId = /^(\d+)-/.exec(id.toString());
    return workScheduleItemGroupId ? Number(workScheduleItemGroupId[1]) : undefined;
};

export const selectWorkScheduleItemEntityId = (workScheduleItem: {id?: number, workGroupId?: number, startDate: string} | undefined) =>
    workScheduleItem
        ? `${workScheduleItem.id}-${workScheduleItem.workGroupId}-${workScheduleItem.startDate}`
        : '';

export const workScheduleItemAdapter = createEntityAdapter<WorkScheduleItem>({
    selectId: selectWorkScheduleItemEntityId,
});
const workItemAdapter = createEntityAdapter<WorkItem>();
export const tripDefinitionWorkItemAdapter = createEntityAdapter<TripDefinitionWorkItem>();
export const defectAdapter = createEntityAdapter<DefectSimple>();

export interface WorkScheduleItemSliceState {
    workScheduleItems: EntityState<WorkScheduleItem>;
    workItems: EntityState<WorkItem>;
    tripDefinitionWorkItems: EntityState<TripDefinitionWorkItem>;
    defects: EntityState<DefectSimple>;
}

const initialState: WorkScheduleItemSliceState = {
    workScheduleItems: workScheduleItemAdapter.getInitialState(),
    workItems: workItemAdapter.getInitialState(),
    tripDefinitionWorkItems: tripDefinitionWorkItemAdapter.getInitialState(),
    defects: defectAdapter.getInitialState(),
};

export const workScheduleItemSlice = createSlice({
    name: 'workScheduleItems',
    initialState: initialState,
    reducers: {
        setWorkScheduleItems: (state, action) => {
            workScheduleItemAdapter.setAll(state.workScheduleItems, action.payload);
        },
        addWorkItem: (state, action) => {
            workItemAdapter.upsertOne(state.workItems, action.payload);
        },
        removeWorkItem: (state, action) => {
            workItemAdapter.removeOne(state.workItems, action.payload);
        },
        addTripDefinitionWorkItem: (state, action) => {
            tripDefinitionWorkItemAdapter.upsertOne(state.tripDefinitionWorkItems, action.payload);
        },
        removeTripDefinitionWorkItem: (state, action) => {
            tripDefinitionWorkItemAdapter.removeOne(state.tripDefinitionWorkItems, action.payload);
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchWorkSchedule.pending, (state) => {
            workScheduleItemAdapter.removeAll(state.workScheduleItems);
            workItemAdapter.removeAll(state.workItems);
        });
        builder.addCase(fetchWorkSchedule.fulfilled, (state, action) => {
            workScheduleItemAdapter.setAll(state.workScheduleItems, action.payload.workScheduleItems);
            workItemAdapter.setAll(state.workItems, action.payload.workItems);
            defectAdapter.setAll(state.defects, action.payload.defects);
        });

        builder.addCase(removeWorkScheduleItem.fulfilled, (state, action) => {
            if (action.payload.type === WorkScheduleItemType.WORK_GROUP) {
                workScheduleItemAdapter.updateOne(state.workScheduleItems, {
                    id: selectWorkScheduleItemEntityId(action.payload),
                    changes: {
                        ...action.payload,
                        id: undefined,
                        resourceId: undefined,
                    },
                });
            } else {
                workScheduleItemAdapter.removeOne(state.workScheduleItems, selectWorkScheduleItemEntityId(action.payload));
            }
        });

        builder.addCase(addWorkScheduleItem.fulfilled, (state, action) => {
            if (action.payload.type === WorkScheduleItemType.WORK_GROUP) {
                const existingItemId = selectWorkScheduleItemEntityId({...action.payload, id: undefined});
                workScheduleItemAdapter.updateOne(state.workScheduleItems, {
                    id: existingItemId,
                    changes: action.payload,
                });
            } else {
                workScheduleItemAdapter.addOne(state.workScheduleItems, action.payload);
            }
        });

        builder.addCase(changeWorkScheduleItem.fulfilled, (state, action) => {
            workScheduleItemAdapter.updateOne(state.workScheduleItems, {
                id: action.payload.entityId,
                changes: {
                    resourceId: action.payload.workScheduleItem.resourceId,
                    startDate: action.payload.workScheduleItem.startDate,
                    endDate: action.payload.workScheduleItem.endDate,
                    workGroupId: action.payload.workScheduleItem.workGroupId,
                    comment: action.payload.workScheduleItem.comment,
                },
            });
        });

        builder.addCase(confirmWorkSchedule.fulfilled, (state, action) => {
            workScheduleItemAdapter.removeMany(state.workScheduleItems, action.payload.removedIds);
            workScheduleItemAdapter.upsertMany(state.workScheduleItems, action.payload.items);
        });

        builder.addCase(fetchWorkSheets.fulfilled, (state, action) => {
            workScheduleItemAdapter.setAll(state.workScheduleItems, action.payload.workScheduleItems);
            workItemAdapter.setAll(state.workItems, action.payload.workItems);
            defectAdapter.setAll(state.defects, action.payload.defects);
        });

        builder.addCase(createWorkSheet.fulfilled, (state, action) => {
            workScheduleItemAdapter.updateOne(state.workScheduleItems, {
                id: action.payload.workScheduleItemId,
                changes: {
                    id: action.payload.newId,
                    resourceId: action.payload.resourceId,
                }
            });
        });
        builder.addCase(updateWorkSheet.fulfilled, (state, action) => {
            if (action.payload.hasNewId) {
                const workSheet = state.workScheduleItems.entities[action.payload.workScheduleItemId];
                if (workSheet) {
                    workScheduleItemAdapter.removeOne(state.workScheduleItems, action.payload.workScheduleItemId);
                    workScheduleItemAdapter.addOne(state.workScheduleItems, {
                        ...workSheet,
                        resourceId: undefined,
                        id: undefined,
                    });
                }
            } else {
                workScheduleItemAdapter.updateOne(state.workScheduleItems, {
                    id: action.payload.workScheduleItemId,
                    changes: {
                        resourceId: action.payload.resourceId,
                    }
                });
            }

        });
        builder.addCase(generateWorkSheet.fulfilled, (state, action) => {
            workScheduleItemAdapter.upsertMany(state.workScheduleItems, action.payload.workScheduleItems);
            workItemAdapter.upsertMany(state.workItems, action.payload.workItems);
        });

        // Planner
        builder.addCase(fetchWorkGroupPlannerData.fulfilled, (state) => {
            workScheduleItemAdapter.removeAll(state.workScheduleItems);
            workItemAdapter.removeAll(state.workItems);
            tripDefinitionWorkItemAdapter.removeAll(state.tripDefinitionWorkItems);
        });
        builder.addCase(addWorkSheet.fulfilled, (state, action) => {
            workScheduleItemAdapter.addOne(state.workScheduleItems, action.payload);
        });
        builder.addCase(fetchWorkSheetPlannerData.fulfilled, (state, action) => {
            workScheduleItemAdapter.setAll(state.workScheduleItems, action.payload.workSheets);
            workItemAdapter.setAll(state.workItems, action.payload.workItems);
            tripDefinitionWorkItemAdapter.setAll(state.tripDefinitionWorkItems, action.payload.tripDefinitionWorkItems);
        });
        builder.addCase(addActivity.fulfilled, (state, action) => {
            if (action.payload?.workItems) workItemAdapter.upsertMany(state.workItems, action.payload.workItems);
        });
        builder.addCase(editActivity.fulfilled, (state, action) => {
            if (action.payload?.workItems) workItemAdapter.upsertMany(state.workItems, action.payload.workItems);
        });
        builder.addCase(addPreparationAndFinishingTime.fulfilled, (state, action) => {
            if (action.payload?.workItems) workItemAdapter.upsertMany(state.workItems, action.payload.workItems);
        });
        builder.addCase(addOppositeWorkSheetToActivity.fulfilled, (state, action) => {
            workItemAdapter.updateOne(state.workItems, {
                id: action.payload.workItemId,
                changes: {
                    workSheetsOfOppositeType: action.payload.workSheetsOfOppositeType
                }
            });
        });
        builder.addCase(deleteWorkSheetActivity.fulfilled, (state, action) => {
            workItemAdapter.removeOne(state.workItems, action.payload.workItemId);
        });
        builder.addCase(addOtherRegionUnassignedWorkItem.fulfilled, (state, action) => {
            tripDefinitionWorkItemAdapter.upsertOne(state.tripDefinitionWorkItems, action.payload);
        });
    }
});

export const {
    selectAll: selectAllWorkScheduleItems,
    selectById: selectWorkScheduleItemById,
} = workScheduleItemAdapter.getSelectors<RootState>(state => state.workScheduleItems.workScheduleItems);

export const {
    selectAll: selectAllWorkItems,
    selectById: selectWorkItemById,
} = workItemAdapter.getSelectors<RootState>(state => state.workScheduleItems.workItems);

export const {
    selectAll: selectAllTripDefinitionWorkItems,
    selectById: selectTripDefinitionWorkItemById,
} = tripDefinitionWorkItemAdapter.getSelectors<RootState>(state => state.workScheduleItems.tripDefinitionWorkItems);

export const {
    selectAll: selectAllDefects,
} = defectAdapter.getSelectors<RootState>(state => state.workScheduleItems.defects);

export const {
    setWorkScheduleItems,
    addWorkItem,
    addTripDefinitionWorkItem,
    removeTripDefinitionWorkItem,
} = workScheduleItemSlice.actions;

export const selectAllPlannerWorkItems = createSelector(
    selectAllWorkItems,
    selectDate,
    (workItems, date): PlannerWorkItem[] => workItems.map(workItem => getPlannerWorkItem(workItem, date))
);

export const selectAllPlannerTripDefinitionWorkItems = createSelector(
    selectAllTripDefinitionWorkItems,
    selectDate,
    (workItems, date): PlannerTripDefinitionWorkItem[] => workItems.map(workItem => getPlannerTripDefinitionWorkItem(workItem, date))
);

export const selectPlannerWorkItemById = createSelector(
    selectWorkItemById,
    selectDate,
    (workItem, date): PlannerWorkItem | undefined => workItem ? getPlannerWorkItem(workItem, date) : undefined
);

export const selectPlannerTripDefinitionWorkItemById = createSelector(
    selectTripDefinitionWorkItemById,
    selectDate,
    (workItem, date): PlannerTripDefinitionWorkItem | undefined => workItem ? getPlannerTripDefinitionWorkItem(workItem, date) : undefined
);

export const selectWorkSheetsOfOppositeType = createSelector(
    selectAllWorkScheduleItems,
    selectToggledResourceType,
    (workSheets, selectedResourceType) => workSheets.filter(workSheet => workSheet.resourceType !== selectedResourceType)
);

export const selectWorkSheetsOfOppositeTypeWithResourceName = (state: RootState): WorkScheduleItemWithId[] =>
    selectWorkSheetsOfOppositeType(state)
        .filter(workSheet => workSheet.id !== undefined)
        .map(workSheet => ({
            ...workSheet,
            id: workSheet.id as number,
            resourceName: selectResourceNameByWorkSheetId(state, workSheet.id as number) ?? ''
        })) as WorkScheduleItemWithId[]
    ?? [];

export const selectWorkSheetsOfOppositeTypeWithResourceNameMemoized = createSelector(
    selectWorkSheetsOfOppositeTypeWithResourceName,
    (result) => result
);

export const selectConfirmedIds = (state: RootState) => {
    const items = selectAllWorkScheduleItems(state);

    return items
        .filter(item => item.confirmedAt)
        .map(item => selectWorkScheduleItemEntityId(item));
};

export const selectWorkScheduleItemWithWorkItems = (state: RootState, id: EntityId) => {
    const workScheduleItem = selectWorkScheduleItemById(state, id);
    if (!workScheduleItem) {
        return undefined;
    }
    const items: WorkItem[] = [];
    workScheduleItem?.workItemIds?.forEach(itemId => {
        const item = selectWorkItemById(state, itemId);
        if (item) {
            items.push(item);
        }
    });

    return {
        ...workScheduleItem,
        items: workScheduleItem?.workItemIds ? items : undefined,
    };
};

export const selectWorkScheduleItemsOnDay = createSelector(
    selectAllWorkScheduleItems,
    selectSelectedDay,
    (items, day) => {
        return items.filter(item => item.startDate === day);
    }
);

export const selectIsWorkScheduleItemGenerated = createSelector(
    selectWorkScheduleItemById,
    (item) => item && item.status === WorkScheduleItemStatus.GENERATED
);

export const selectIsPlannerRowDisabled = createSelector(
    selectIsWorkScheduleItemGenerated,
    selectPlannerType,
    (isGenerated, plannerType) => plannerType === PlannerType.WORK_SHEET && !isGenerated
);

export const selectBusesWithDefects = createSelector(
    selectAllBuses,
    selectAllDefects,
    (buses, defects) => {
        const busesWithDefects: BusWithDefects[] = [];

        buses.forEach(bus => {
            const defectsOfCurrentBus = defects.filter(defect => defect.busId === bus.id);

            if (defectsOfCurrentBus.length) {
                busesWithDefects.push({...bus, defects: defectsOfCurrentBus});
            }
        });

        return busesWithDefects
    }
);

export const selectBusesWithDefectsSortedByPriority = createSelector(
    selectBusesWithDefects,
    (buses): BusWithDefectsAndHighestPriority[] => buses
        .map(bus => ({...bus, highestPriorityDefect: getHighestPriorityDefect(bus.defects)}))
        .sort((a,b) => isPriorityHigher(a.highestPriorityDefect, b.highestPriorityDefect))
);

export const selectDisruptiveDefectsByBusId = createSelector(
    selectBusById,
    selectAllDefects,
    (bus, defects) => defects.filter(defect =>
        defect.priority !== DefectPriority.LOW && defect.busId === bus?.id
    )
);

export default workScheduleItemSlice.reducer;
