import React, {ReactElement, useEffect, useState} from "react";
import ItemCardTooltipText from "./ItemCardTooltipText";
import {Box, Tooltip} from "@mui/material";
import {WorkGroupTripDefinition} from "../../../../API/workGroup/types";
import {DisplayTripDefinitionWorkItem, DisplayWorkGroupActivity, DisplayWorkItem} from "../../types";


interface PlannerItemTooltipProps {
    children: ReactElement;
    isDragging: boolean;
    item: WorkGroupTripDefinition | DisplayWorkGroupActivity | DisplayWorkItem | DisplayTripDefinitionWorkItem;
    handleActivityEdit?: () => void;
    handleActivityDelete?: () => void;
    handleTripSplit?: () => void;
    handleTripMerge?: () => void;
    isRowDisabled?: boolean;
}

const PlannerItemTooltip = ({
    children,
    isDragging,
    item,
    handleActivityEdit,
    handleActivityDelete,
    handleTripSplit,
    handleTripMerge,
    isRowDisabled,
}: PlannerItemTooltipProps) => {
    const [enableTooltip, setEnableTooltip] = useState(true);

    useEffect(() => {
        if (!enableTooltip && !isDragging) {
            setTimeout(() => {
                setEnableTooltip(true);
            }, 300);
        }
        if (isDragging && enableTooltip) {
            setEnableTooltip(false);
        }
    }, [isDragging]);

    return (enableTooltip ?
        <Tooltip
            arrow
            placement="top"
            PopperProps={{
                modifiers: [
                    {name: 'offset', options: {offset: [0, -3]}}
                ],
            }}
            title={
                <ItemCardTooltipText
                    item={item}
                    handleActivityEdit={handleActivityEdit}
                    handleActivityDelete={handleActivityDelete}
                    handleTripSplit={handleTripSplit}
                    handleTripMerge={handleTripMerge}
                    isRowDisabled={isRowDisabled}
                />
            }
        >
            {children}
        </Tooltip>
        :
        <Box>{children}</Box>
    );
};

export default PlannerItemTooltip;
