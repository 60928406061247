import React, {useContext, useEffect, useState} from "react";
import {Box} from "@mui/material";
import {WorkGroupForm, WorkGroupFromType} from "../types";
import {ToastContext} from "../../../../../contexts/ToastContext";
import {ResourceType, ScheduleSetting, WorkGroup, WorkGroupVersion} from "../../../../../API/types";
import * as Yup from "yup";
import {dateValidTo, validationSchema} from "../../../../../utils/formValidation";
import Loader from "../../../../../components/Loader";
import {Formik, FormikHelpers, FormikProps} from "formik";
import WorkGroupEditForm from "./WorkGroupEditForm";
import {days} from "../../../../../types";
import {createWorkGroup, updateWorkGroup} from "../../../../../API";
import {mapErrors} from "../../../../../utils/errorMapping";
import {useAppSelector} from "../../../../../hooks";
import {selectAllRegions, selectSelectedRegion} from "../../../../../store/regionSlice";
import {getDateString, getDateWithAddedDays} from "../../../../../utils/dateUtils";
import {selectToggledResourceType} from "../../../../../store/viewSlice";


interface StateData {
    previousWorkGroupVersion: WorkGroup | undefined | null;
}

const workGroupValidationSchema = Yup.object().shape({
    code: validationSchema('Töögrupi kood').fields.shortTextFieldRequired,
    type: validationSchema('Tüüp').fields.textFieldRequired,
    startDate: validationSchema('Sesonaalse kehtivuse alguskuupäev').fields.date,
    endDate: validationSchema('Sesonaalse kehtivuse lõpukuupäev').fields.date,
    validFrom: validationSchema('Algus').fields.dateRequired,
    validTo: dateValidTo('Lõpp', 'validFrom'),
    comment: validationSchema('Kommentaar').fields.longTextField,
});

const defaultFormValues: WorkGroupForm = {
    code: '',
    type: ResourceType.DRIVER,
    startDate: null,
    endDate: null,
    validFrom: null,
    validTo: null,
    repetition: {
        mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false,
        scheduleSettings: {
            SCHOOL_HOLIDAY: ScheduleSetting.NoEffect,
            PUBLIC_HOLIDAY: ScheduleSetting.NoEffect,
            SUMMER_HOLIDAY: ScheduleSetting.NoEffect,
        },
    },
    comment: '',
};

export const getFormType = (workGroup?: WorkGroup | null, stateData?: StateData | null, shouldCreateNewVersion?: boolean) => {
    if (workGroup && shouldCreateNewVersion) {
        return 'newVersionFromPlanner';
    } else if (stateData?.previousWorkGroupVersion) {
        return 'newVersionFromList';
    } else if (workGroup) {
        return 'edit';
    } else {
        return 'add';
    }
};

interface WorkGroupEditFormWrapperProps {
    workGroup?: WorkGroup | null;
    handleSuccessfulEdit: (workGroup: WorkGroup) => void;
    handleSuccessfulCreate: (workGroup?: WorkGroup, oldVersionWorkGroupId?: number) => void;
    useDialogVariant?: boolean;
    handleCancelButtonPress?: () => void;
    selectedDate?: Date;
    shouldCreateNewVersion?: boolean;
    formType: WorkGroupFromType;
    selectedVersion?: WorkGroupVersion;
    setSelectedVersion: (newVersion: WorkGroupVersion | undefined) => void;
}

export default function WorkGroupEditFormWrapper({
    workGroup, handleSuccessfulEdit, handleSuccessfulCreate, useDialogVariant, handleCancelButtonPress, selectedDate, formType, selectedVersion, setSelectedVersion
}: WorkGroupEditFormWrapperProps) {
    const { addToast } = useContext(ToastContext);
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const resourceType = useAppSelector(selectToggledResourceType);
    const regions = useAppSelector(selectAllRegions);
    const [workGroupRegion, setWorkGroupRegion] = useState<string | undefined>(undefined);
    const [initialValues, setInitialValues] = useState<WorkGroupForm | undefined>(undefined);
    const isFormTypeNewVersion = formType === 'newVersionFromList' || formType === 'newVersionFromPlanner';

    useEffect(() => {
        switch (formType) {
            case 'add':
                if (selectedDate) {
                    const defaultFormValuesWithPreselectedData = {
                        ...defaultFormValues,
                        type: resourceType ?? ResourceType.DRIVER,
                        validFrom: selectedDate,
                        repetition: {...defaultFormValues.repetition}
                    }
                    defaultFormValuesWithPreselectedData.repetition[days[selectedDate.getDay()]] = true;
                    setInitialValues(defaultFormValuesWithPreselectedData);
                } else {
                    setInitialValues({...defaultFormValues, type: resourceType ?? ResourceType.DRIVER});
                }
                return;
            case 'edit':
                if (workGroup) generateInitialValues(workGroup);
                return;
            case 'newVersionFromList':
                if (workGroup) {
                    generateInitialValues({...workGroup, validFrom: null, validTo: null});
                }
                return;
            case 'newVersionFromPlanner':
                if (workGroup && selectedDate) {
                    generateInitialValues({...workGroup, validFrom: getDateString(selectedDate), validTo: null});
                }
                return;
        }
    }, []);

    const generateInitialValues = (workGroup: WorkGroup) => {
        if (workGroup.regionId) {
            setWorkGroupRegion(regions?.find(region => region.id === workGroup.regionId)?.name ?? undefined);
        }

        setInitialValues({
            code: workGroup.code,
            type: workGroup.type,
            startDate: workGroup.startDate ? new Date(workGroup.startDate) : null,
            endDate: workGroup.endDate ? new Date(workGroup.endDate) : null,
            validFrom: workGroup.validFrom ? new Date(workGroup.validFrom): null,
            validTo: workGroup.validTo ? new Date(workGroup.validTo) : null,
            repetition: {
                ...workGroup.repetition,
                scheduleSettings: {
                    ...defaultFormValues.repetition.scheduleSettings,
                    ...workGroup.repetition.scheduleSettings,
                },
            },
            comment: workGroup.comment ?? '',
        });
    };

    const onSubmit = async (form: WorkGroupForm, formHelpers: FormikHelpers<WorkGroupForm>) => {
        if (form.validFrom && form.type) {
            let updatedPreviousWorkGroup: WorkGroup | undefined;

            if (isFormTypeNewVersion && form.validFrom && workGroup) {
                const updatedPreviousWorkGroupEndDate = getDateString(getDateWithAddedDays(form.validFrom, -1));
                updatedPreviousWorkGroup = {...workGroup, validTo: updatedPreviousWorkGroupEndDate}

                const success = updatedPreviousWorkGroup && await endPreviousWorkGroup(updatedPreviousWorkGroup, formHelpers);
                if (!success) return;
            }

            const request: WorkGroup = {
                id: formType === 'edit' && workGroup?.id ? workGroup.id : 0,
                code: form.code,
                type: form.type,
                regionId: selectedRegion?.id ?? null,
                startDate: form.startDate ? getDateString(form.startDate) : null,
                endDate: form.endDate ? getDateString(form.endDate) : null,
                validFrom: form.validFrom ? getDateString(form.validFrom) : null,
                validTo: form.validTo ? getDateString(form.validTo) : null,
                repetition: form.repetition,
                comment: form.comment.length > 0 ? form.comment : null,
                tripDefinitions: workGroup?.tripDefinitions ?? [],
                activities: workGroup?.activities ?? [],
            };
            if (formType === 'edit' && workGroup) {
                updateWorkGroup(request)
                    .then(() => {
                        addToast({type: 'success', text: 'Töögrupp edukalt uuendatud'});
                        handleSuccessfulEdit(request);
                    })
                    .catch(apiError => {
                        addToast({type: 'error', text: mapErrors(apiError) ?? 'Töögrupi uuendamisel esines tõrge'});
                    })
                    .finally(() => formHelpers.setSubmitting(false));
            } else {
                createWorkGroup(request)
                    .then(result => {
                        addToast({type: 'success', text: 'Töögrupp edukalt loodud'});
                        if (formType === 'newVersionFromPlanner' && updatedPreviousWorkGroup) {
                            handleSuccessfulCreate(result, updatedPreviousWorkGroup.id);
                        } else {
                            handleSuccessfulCreate(result);
                        }
                    })
                    .catch(apiError => {
                        addToast({type: 'error', text: mapErrors(apiError) ?? 'Töögrupi loomisel esines tõrge'});
                    })
                    .finally(() => formHelpers.setSubmitting(false))
            }
        }
    };

    const endPreviousWorkGroup = (workGroup: WorkGroup, formHelpers: FormikHelpers<WorkGroupForm>): Promise<boolean> => {
        return updateWorkGroup(workGroup)
            .then(() => {
                return true;
            })
            .catch(apiError => {
                addToast({type: 'error', text: 'Algse töögrupi lõpetamisel esines tõrge. ' + (mapErrors(apiError) ?? '')});
                formHelpers.setSubmitting(false);
                return false;
            });
    };

    return (initialValues ?
        <Box p={{xs: 1, sm: 0}}>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={workGroupValidationSchema} onSubmit={onSubmit}>
                {(formikProps: FormikProps<WorkGroupForm>) =>
                    <WorkGroupEditForm
                        formikProps={formikProps}
                        workGroup={workGroup}
                        defaultFormValues={defaultFormValues}
                        workGroupRegion={workGroupRegion}
                        useDialogVariant={useDialogVariant}
                        handleCancelButtonPress={handleCancelButtonPress}
                        disableValidFrom={formType === 'newVersionFromPlanner'}
                        showNewVersionWarning={isFormTypeNewVersion}
                        selectedVersion={selectedVersion}
                        setSelectedVersion={setSelectedVersion}
                    />
                }
            </Formik>
        </Box>
        :
        <Loader />
    );
}
