import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import {GridPaginationModel} from "@mui/x-data-grid";

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

export type SearchContextType = {
    searchInput: string,
    setSearchInput: (term: string) => void,
    paginationModel: GridPaginationModel,
    setPaginationModel: (paginationModel: GridPaginationModel) => void,
};

const SearchLayoutWrapper = ({path}: { path?: string }) => {
    const [searchInput, setSearchInput] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: DEFAULT_PAGE,
        pageSize: DEFAULT_PAGE_SIZE,
    });

    // If path has changed reset search input
    useEffect(() => {
        setSearchInput('');
        setPaginationModel({
            page: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
        })
    }, [path]);

    return (
        <Outlet
            context={{
                searchInput,
                setSearchInput,
                paginationModel,
                setPaginationModel,
            }}
        />
    );
};

export default SearchLayoutWrapper;