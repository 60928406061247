import React, {ReactNode} from "react";
import {Box, darken, SxProps, Typography} from "@mui/material";
import {red} from "@mui/material/colors";
import {CustomCellTooltip} from "./CustomCellTooltip";
import {borderColor, disabledBackground} from "../index";
import {DefectSimple} from "../../../../../API/defects/types";
import {getPriorityColor} from "../../../defects/detailsView/components/DefectFields";
import {getHighestPriorityDefect} from "../../../workSheets/listView/BusesWithDefects";


const CustomCell = ({content, onClick, tooltip, bgColor, sx, button, rotatedColumn, hasWarning, width, header, defects}: {
    content: ReactNode;
    onClick?: () => void;
    tooltip?: ReactNode;
    bgColor?: string;
    sx?: SxProps
    button?: ReactNode;
    rotatedColumn?: boolean;
    hasWarning?: boolean;
    width?: string | number;
    header?: boolean;
    defects?: DefectSimple[];
}) => {
    const warningSx = hasWarning ? {
        '@media not print': {
            borderLeft: `3px solid ${red[500]}`,
            marginLeft: '-2px',
        }
    } : {};

    return (
        <Box
            sx={{
                boxSizing: 'border-box',
                border: `1px solid ${borderColor}`,
                flexGrow: width ? 0 : 1,
                minWidth: width ?? undefined,
                maxWidth: width ?? undefined,
                width: 0,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                '&:last-of-type': { borderRightWidth: 0 },
                textAlign: 'center',
                cursor: onClick ? 'pointer' : 'initial',
                backgroundColor: bgColor,
                '&:hover': () => {
                    if (onClick) {
                        if (bgColor) return {backgroundColor: darken(bgColor, 0.1)};
                        return {backgroundColor: disabledBackground}
                    }
                    return null;
                },
                position: 'relative',
                ...sx,
                backgroundClip: 'padding-box',
            }}
            role="cell"
        >
            {button}
            <CustomCellTooltip tooltip={tooltip}>
                <Box
                    sx={rotatedColumn ? {
                        position: 'absolute',
                        p: 0.15,
                        left: '50%',
                        top: '85px',
                        transform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
                        whiteSpace: 'nowrap',
                        zIndex: 10,
                    } : {
                        position: 'relative',
                        height: '100%',
                        p: 0.15,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        boxSizing: 'border-box',
                        ...warningSx,
                    }}
                    onClick={onClick}
                    role="button"
                >
                    {defects && defects.length > 0 &&
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                width: 0,
                                height: 0,
                                borderBottom: '10px solid transparent',
                                borderRight: '10px solid',
                                borderRightColor: getPriorityColor(getHighestPriorityDefect(defects).priority)
                            }}
                        />
                    }
                    <Typography component="div" sx={{fontSize: !header ? '.8rem' : undefined, lineHeight: 1.2}}>{content}</Typography>
                </Box>
            </CustomCellTooltip>
        </Box>
    );
};

export default React.memo(CustomCell);
