import React from 'react';
import {useField} from "formik";
import {Autocomplete, SxProps, TextField as MuiTextField} from '@mui/material';


interface SelectProps {
    name: string;
    label: string;
    options: string[];
    sx?: SxProps;
}

const SelectFreeInput = ({name, label, options, sx}: SelectProps) => {
    const [field, meta, helpers] = useField(name);

    return (
        <Autocomplete
            options={field.value !== '' && !options.includes(field.value) ? [field.value, ...options] : options}
            value={field.value}
            freeSolo
            size="small"
            onChange={(_, value) => void helpers.setValue(value)}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    label={label}
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                    onChange={(event) => void helpers.setValue(event.target.value)}
                />
            )}
            sx={{...sx, my: 1}}
        />
    );
};

export default SelectFreeInput;
