import React from 'react';
import {useAppSelector} from "../../../../../hooks";
import {EntityId} from "@reduxjs/toolkit";
import {shallowEqual} from "react-redux";
import TripCard from "../../Trip/Card";
import {selectDisplayTripDefinitionWorkItemById} from "../../../store/selectors";
import {selectSelectedRegion} from "../../../../../store/regionSlice";


interface TripDefinitionWorkItemCardWrapperProps {
    workItemId: EntityId;
}

const TripDefinitionWorkItemCardWrapper = ({workItemId}: TripDefinitionWorkItemCardWrapperProps) => {
    const selectedRegion = useAppSelector(selectSelectedRegion)
    const workItem = useAppSelector(state => selectDisplayTripDefinitionWorkItemById(state, workItemId), shallowEqual);
    const workSheetOfOppositeTypeId = workItem?.workSheetsOfOppositeType && workItem.workSheetsOfOppositeType.length > 0
        ? workItem.workSheetsOfOppositeType[0] : undefined;

    return (workItem
        ? <TripCard
                trip={workItem}
                tripSegmentId={workItem.id}
                isFromOtherRegion={workItem.regionId !== selectedRegion?.id}
                currentGroupId={0}
                groupOfOppositeTypeId={workSheetOfOppositeTypeId}
            />
        : <></>
    )
};

export default TripDefinitionWorkItemCardWrapper;
