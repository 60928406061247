import axios from "axios";
import {
    CopyTripDefinitionResponse,
    CopyTripDefinitionRequest,
    SaveTripDefinitionRequest,
    TripDefinition,
    SplitTripRequest,
    TripWorkItemChanges,
    TripWorkItemChangesWithRoute,
    MergeTripRequest,
    MergeTripResultingChanges
} from "./types";
import {handleError} from "../utils";
import { Dayjs } from "dayjs";
import {WorkSheetWorkItem} from "../workSheets/types";

export const loadTripDefinitions = (regionId: number) =>
    axios.get<TripDefinition[]>(`/api/regions/${regionId}/trip-definitions`)
        .then(result => result.data)
        .catch(handleError);

export const loadTripDefinitionsByDate = (regionId: number, date: Dayjs) => // trip segments
    axios.get<TripDefinition[]>(`/api/regions/${regionId}/trip-definitions?date=${date.format('YYYY-MM-DD')}`)
        .then(result => result.data)
        .catch(handleError);

export const updateTripDefinition = (request: SaveTripDefinitionRequest) =>
    axios.put<TripDefinition>(`/api/trip-definitions`, request)
        .then(result => result.data)
        .catch(handleError);

export const createTripDefinition = (request: SaveTripDefinitionRequest) =>
    axios.post<TripDefinition>('/api/trip-definitions', request)
        .then(result => result.data)
        .catch(handleError);


export const copyTripDefinition = (id: number | string, request: CopyTripDefinitionRequest) =>
    axios.post<CopyTripDefinitionResponse>(`/api/trip-definitions/${id}/copy`, request)
        .then(result => result.data)
        .catch(handleError);

export const loadTripDefinition = (id: number | string) =>
    axios.get<TripDefinition>(`/api/trip-definitions/${id}`)
        .then(result => result.data)
        .catch(handleError);

export const splitTrip = (id: number | string, request: SplitTripRequest) =>
    axios.put<TripDefinition[]>(`/api/trips/${id}/split-point`, request)
        .then(result => result.data)
        .catch(handleError);

export const mergeTrip = (id: number | string, request: MergeTripRequest) =>
    axios.put<MergeTripResultingChanges>(`/api/trips/${id}/merge`, request)
        .then(result => result.data)
        .catch(handleError);

export const getTripWorkItemsOnDate = async (id: number | string, date: string): Promise<{
    trip: TripDefinition,
    workItems: WorkSheetWorkItem[],
}> => {
    const workItems = await axios.get<WorkSheetWorkItem[]>(`/api/trips/${id}/work-items/${date}`)
            .then(result => result.data)
            .catch(handleError);
    const trip = await loadTripDefinition(id);

    return {trip, workItems};
};

export const saveTripWorkItemsOnDate = async (id: number | string, workItems: TripWorkItemChanges[], date: string) => {
    return axios.put<WorkSheetWorkItem[]>(`/api/trips/${id}/work-items/${date}`, workItems)
        .then(result => result.data)
        .catch(handleError);
};

export const saveTripWorkItemsWithRouteOnDate = async (id: number | string, workItems: TripWorkItemChangesWithRoute[], date: string) => {
    return axios.put<WorkSheetWorkItem[]>(`/api/trips/${id}/work-items-with-route/${date}`, workItems)
        .then(result => result.data)
        .catch(handleError);
};
