import React, {ReactNode} from 'react';
import {Box, styled, SxProps, useMediaQuery} from '@mui/material';
import {DataGrid as MuiDataGrid, DataGridProps as MuiDataGridProps, GridSortingInitialState} from '@mui/x-data-grid';
import theme from "../../theme";


export const StyledDataGrid = styled(MuiDataGrid)(() => ({
    overflowX: 'scroll',
    '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none',
    },
    '.MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },

    // editable data grid props
    '& .MuiSelect-select, & .MuiInputBase-input': {
        fontSize: '14px',
        height: '20px',
        padding: '2px 10px',
        display: 'flex',
        alignItems: 'center',
    },
    '& .MuiInputBase-root': {
        marginRight: '10px'
    },
}));

interface DataGridProps extends MuiDataGridProps {
    enableMultiRowCells?: boolean;
    sorting?: GridSortingInitialState;
    sx?: SxProps,
    filterToolbar?: ReactNode,
}

export default function DataGrid({rows, columns, enableMultiRowCells, sorting, editMode, sx, filterToolbar, ...props}: DataGridProps) {
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
            {filterToolbar}
            <Box sx={{overflow: 'hidden'}}>
                <StyledDataGrid
                    rows={rows}
                    editMode={editMode}
                    columns={columns}
                    columnHeaderHeight={40}
                    pageSizeOptions={[10, 25, 50, 100]}
                    disableRowSelectionOnClick
                    disableColumnSelector
                    getRowHeight={() => enableMultiRowCells ? 'auto' : isScreenSmall ? 32 : 35}
                    initialState={{
                        pagination: {paginationModel: { page: 0, pageSize: isScreenSmall ? 10 : 25 }},
                        sorting: sorting,
                    }}
                    slotProps={{noRowsOverlay: { style: { borderTop: '10px solid #ffffff' } }}}
                    {...props}
                    sx={{
                        '& .MuiDataGrid-cell': enableMultiRowCells ? {pt: 1, alignItems: 'flex-start'} : {},
                        ...sx,
                    }}
                />
            </Box>
        </Box>
    );
}
