import React, {useContext} from 'react';
import menuItems from './items';
import {Box, ListItem, SvgIconTypeMap, Tooltip, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router';
import {SidebarContext} from '../../../contexts/SidebarContext';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {doesUserHavePermission} from "../../Visible";
import {useAuth} from "../../../contexts/AuthContext";


interface SidebarMenuItemProps {
    href: string;
    title: string;
    icon: OverridableComponent<SvgIconTypeMap>;
}

function SidebarMenu() {
    const { authenticatedUser } = useAuth();
    const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
    const theme = useTheme();
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const SidebarMenuItem = ({ href, title, icon }: SidebarMenuItemProps) => {
        const Icon = icon;
        const router = useLocation();
        const navigate = useNavigate();
        const active = href === '/' ? href === router.pathname : router.pathname.includes(href.split('/')[1]);

        return (
            <ListItem disableGutters sx={{display: 'flex', mt: 0.5, p: 0}}>
                <Tooltip title={isSidebarOpen ? undefined : title} arrow placement="right">
                <Box
                    onClick={() => {
                        navigate(href, {replace: true});
                        if (isScreenSmall) toggleSidebar();
                    }}
                    sx={{
                        transition: theme.transitions.create('height', {
                            easing: theme.transitions.easing.easeIn,
                            duration: '2s',
                        }),
                        display: 'flex',
                        alignItems: 'center',
                        height: 'fit-content',
                        width: 'calc(100% - 8px)',
                        padding: isSidebarOpen ? theme.spacing(1, 1, 1) : '8px 8px 8px 8px',
                        cursor: 'pointer',
                        borderLeft: `4px solid ${active ? theme.palette.primary.main : 'transparent'}`,
                        backgroundColor: active ? theme.palette.action.hover : '',
                        mx: '4px',
                        borderRadius: '3px',
                        '&:hover': {
                            backgroundColor: theme.palette.action.selected,
                        },
                    }}
                >
                    <Icon sx={{color: theme.palette.text.secondary}} />
                    {isSidebarOpen && <Typography variant="body1" pl={2}>{title}</Typography>}
                </Box>
                </Tooltip>
            </ListItem>
        );
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            {menuItems
                .filter(item => item.permission === undefined || item.permission && doesUserHavePermission(authenticatedUser, item.permission))
                .map(permittedItem => (
                    <SidebarMenuItem
                        key={permittedItem.name}
                        href={permittedItem.link}
                        title={permittedItem.name}
                        icon={permittedItem.icon}
                    />
            ))}
        </Box>
    );
}

export default SidebarMenu;
