import {HOURS_IN_A_DAY} from "../../constants";


export const MIN_WORK_GROUP_ITEM_DURATION_HOURS = 0.05;
export const HOUR_DECIMALS = 3;

export const PLANNER_SIDEBAR_WIDTH = 138;
export const PLANNER_ROW_HEIGHT = 50;

export const DEFAULT_PLANNER_START_TIME = 3;
export const DEFAULT_PLANNER_DURATION = HOURS_IN_A_DAY;
export const MIN_PLANNER_DURATION = 6;

export const DEFAULT_PREPARATION_AND_FINISHING_TIME_MINUTES = 15;

export const TRIP_TITLE_LETTER_WIDTH = 9;
export const TRIP_SUBTITLE_LETTER_WIDTH = 6;
export const ACTIVITY_LETTER_WIDTH = 8;
