import React, {createContext, ReactElement, useEffect} from 'react';
import {useMediaQuery} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {useLocalStorage} from "usehooks-ts";


interface SidebarContextType {
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
}

interface Props {
    children: ReactElement;
}

export const SidebarContext = createContext<SidebarContextType>({} as SidebarContextType);

export const SidebarProvider = ({ children }: Props) => {
    const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage('isSidebarOpen', false);

    const toggleSidebar = () => setIsSidebarOpen(isOpen => !isOpen);

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'), {
        defaultMatches: true,
        noSsr: false,
    });

    useEffect(() => {
        if (isSidebarOpen) setIsSidebarOpen(lgUp);
    }, [lgUp]);

    return (
        <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar }}>
            {children}
        </SidebarContext.Provider>
    );
};
