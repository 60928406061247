import React, {ReactNode, useMemo} from "react";
import {Check, Clear, ExpandMore} from "@mui/icons-material";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import Dialog from "../../../../../components/Dialog";
import {CopyTripDefinitionResponse, Region, ResourceType,} from "../../../../../API/types";
import {useAppSelector} from "../../../../../hooks";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import ActivityCard from "./ActivityCard";
import WorkGroupCard from "./WorkGroupCard";
import {getDisplayValidDatesRange} from "../../../../../utils/dateUtils";


const countByRegion = (items: {region: Region}[]) => Array.from(items.reduce((grouped: Map<string, number>, item) => {
    const count = grouped.get(item.region.name) ?? 0;
    grouped.set(item.region.name, count + 1);
    return grouped;
}, new Map<string, number>()));

interface ChangeSection {
    summary: ReactNode;
    details: ReactNode[];
}

interface ConfirmDialogProps {
    plannedChanges: CopyTripDefinitionResponse;
    onCancel: () => void;
    onConfirm: () => void;
}

const ConfirmDialog = ({plannedChanges, onCancel, onConfirm}: ConfirmDialogProps) => {
    const selectedRegion = useAppSelector(selectSelectedRegion);

    const changes: ChangeSection[] = useMemo(() => {
        if (!plannedChanges) {
            return [];
        }

        const changes: ChangeSection[] = [];

        const newDriverWorkGroupVersions = plannedChanges.newWorkGroupVersions.filter(wg => wg.type === ResourceType.DRIVER);
        if (newDriverWorkGroupVersions.length > 0) {
            let text = `Muudetakse ${newDriverWorkGroupVersions.length} juhi töögruppi`;
            if (newDriverWorkGroupVersions.some(wg => wg.region.id !== selectedRegion?.id)) {
                text += ` (${countByRegion(newDriverWorkGroupVersions).map(([regionName, count]) => `${count} ${regionName}`).join(', ')})`;
            }
            changes.push({
                summary: text,
                details: newDriverWorkGroupVersions.map(wgv => <WorkGroupCard key={wgv.code+wgv.validTo+wgv.region.id} workGroup={wgv} />)
            });
        }

        const newBusWorkGroupVersions = plannedChanges.newWorkGroupVersions.filter(wg => wg.type === ResourceType.VEHICLE);
        if (newBusWorkGroupVersions.length > 0) {
            let text = `Muudetakse ${newBusWorkGroupVersions.length} bussi töögruppi`;
            if (newBusWorkGroupVersions.some(wg => wg.region.id !== selectedRegion?.id)) {
                text += ` (${countByRegion(newBusWorkGroupVersions).map(([regionName, count]) => `${count} ${regionName}`).join(', ')})`;
            }
            changes.push({
                summary: text,
                details: newBusWorkGroupVersions.map(wgv => <WorkGroupCard key={wgv.code+wgv.validTo+wgv.region.id} workGroup={wgv} />),
            });
        }

        const newActivities = plannedChanges.newActivities;
        if (newActivities.length > 0) {
            const text = `Muudetakse ${newActivities.length} tegevust`;
            changes.push({
                summary: text,
                details: newActivities.map((activity, index) => <ActivityCard key={index} activity={activity} />),
            });
        }

        plannedChanges.tripSegmentChanges.forEach((tripSegmentChanges) => {
            let tripLabel = plannedChanges.newTripDefinition.code;
            const route = tripSegmentChanges.tripSegment.route;
            if (route) {
                if (route.length > 0) {
                    tripLabel += ': ' + route[0].name;
                }
                if (route.length > 1) {
                    tripLabel += ' - ' + route[route.length - 1].name;
                }
            }

            const addedToWorkGroupVersions = tripSegmentChanges.addedToWorkGroupVersions;
            if (addedToWorkGroupVersions.length > 0) {
                let text = `${tripLabel} lisatakse ${addedToWorkGroupVersions.length} töögruppi`;
                if (addedToWorkGroupVersions.some(wg => wg.region.id !== selectedRegion?.id)) {
                    text += ` (${countByRegion(addedToWorkGroupVersions).map(([regionName, count]) => `${count} ${regionName}`).join(', ')})`;
                }
                changes.push({
                    summary: text,
                    details: addedToWorkGroupVersions.map(wgv => <WorkGroupCard key={wgv.code+wgv.validTo+wgv.region.id} workGroup={wgv} />),
                });
            }

            const removedFromWorkGroupVersions = tripSegmentChanges.removedFromWorkGroupVersions;
            if (removedFromWorkGroupVersions.length > 0) {
                let text = `${tripLabel} eemaldatakse kattuvuse tõttu ${removedFromWorkGroupVersions.length} töögrupist`;
                if (removedFromWorkGroupVersions.some(wg => wg.region.id !== selectedRegion?.id)) {
                    text += ` (${countByRegion(removedFromWorkGroupVersions).map(([regionName, count]) => `${count} ${regionName}`).join(', ')})`;
                }
                changes.push({
                    summary: text,
                    details: removedFromWorkGroupVersions.map(wgv => <WorkGroupCard key={wgv.code+wgv.validTo+wgv.region.id} workGroup={wgv} />),
                });
            }
        });

        return changes;
    }, [plannedChanges, selectedRegion]);

    return (
        <Dialog
            open={true}
            onClose={onCancel}
            title={`Uus ${plannedChanges.newTripDefinition.code}`}
            subtitle={getDisplayValidDatesRange(plannedChanges.newTripDefinition)}
            buttons={[
                {onClick: onCancel, text: 'Loobu', color: 'secondary', startIcon: <Clear />},
                {onClick: onConfirm, text: 'Kinnita', color: 'primary', startIcon: <Check />},
            ]}
        >
            {changes.map(({summary, details}, index) =>
                <Accordion key={index} sx={{
                    border: '1px solid #ebebeb',
                    boxShadow: 'none',
                    '.Mui-expanded': {m: 0},
                    '&.Mui-expanded': {m: 0},
                    '&::before': {display: 'none'}
                }}>
                    <AccordionSummary sx={{
                        '&.Mui-expanded': {minHeight: '48px'},
                        '.Mui-expanded': {m: 0}}
                    } expandIcon={<ExpandMore />}>
                        <Typography>{summary}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{pt: 0, pb: 1}}>
                        {details}
                    </AccordionDetails>
                </Accordion>
            )}
        </Dialog>
    );
};

export default ConfirmDialog;