import {useNavigate} from "react-router-dom";
import React from "react";
import {IconButton, Tooltip} from "@mui/material";
import {Permission} from "../../../types";
import Visible from "../../Visible";
import {AltRoute, ContentCopy, Edit, Visibility} from "@mui/icons-material";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {ICON_COLOR, ICON_SIZE_SMALL} from "../utils";

export const navIconTypeValues = ['EDIT', 'COPY', 'VERSION', 'VIEW'] as const;
export type NavIconType = typeof navIconTypeValues[number];

const navIconStyles = {...ICON_COLOR, ...ICON_SIZE_SMALL}

interface NavIconData {
    tooltipTitle: string;
    icon: ReactJSXElement;
}

const getIcon = (type: NavIconType): NavIconData => {
    switch (type) {
        case 'EDIT':
            return {tooltipTitle: 'Muuda', icon: <Edit sx={navIconStyles} />};
        case 'COPY':
            return {tooltipTitle: 'Kopeeri', icon: <ContentCopy sx={navIconStyles} />};
        case 'VERSION':
            return {tooltipTitle: 'Kopeeri ja lõpeta vana versioon', icon: <AltRoute sx={navIconStyles} />};
        case 'VIEW':
            return {tooltipTitle: 'Vaata', icon: <Visibility sx={navIconStyles} />};
    }
};

interface NavIconProps {
    type: NavIconType;
    id: number;
    route: string;
    tooltipTitle?: string;
    copyState?: any;
    navPermission?: Permission;
}

const NavIcon = ({type, id, route, tooltipTitle, copyState, navPermission}: NavIconProps) => {
    const navigate = useNavigate();
    const iconData = getIcon(type);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (copyState) {
            navigate(route, {state: copyState});
        } else {
            const navStr = route.replace(':id', id.toString());

            if (event.ctrlKey || event.metaKey) {
                window.open(navStr, '_blank');
            } else {
                navigate(navStr);
            }
        }
    };

    return (
        <Visible permission={navPermission}>
            <Tooltip title={tooltipTitle ?? iconData.tooltipTitle} arrow placement="top">
                <IconButton size="small" onClick={handleClick}>
                    {iconData.icon}
                </IconButton>
            </Tooltip>
        </Visible>
    );
};

export default NavIcon;
