import React from "react";
import {Box, DialogActions} from "@mui/material";
import {Add, Clear} from "@mui/icons-material";
import Button from "../../../../../Buttons/Button";
import {TripDefinition} from "../../../../../../API/types";
import {Form} from "formik";
import Autocomplete from "../../../../../Form/Autocomplete";
import LoadingField from "../../../../../Form/LoadingField";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {clearDialogData} from "../../../../../../store/plannerSlice";
import {TripDefinitionWorkItem as WorkScheduleTripDefinitionWorkItem} from "../../../../../../store/workScheduleItemSlice";
import {getDisplayTimeRange, getStartAndEndTimeWithModifierFromDateTimeStr} from "../../../../../../utils/dateUtils";
import {selectDate} from "../../../../../../store/viewSlice";


export interface OtherRegionWorkGroupTripDefinitionFormProps {
    options: (WorkScheduleTripDefinitionWorkItem | TripDefinition)[] | undefined;
    isLoading: boolean;
    name: string;
}

export default function OtherRegionTripForm({options, isLoading, name}: OtherRegionWorkGroupTripDefinitionFormProps) {
    const dispatch = useAppDispatch();
    const currentDay = useAppSelector(selectDate);

    const getDateTimeRange = (option: WorkScheduleTripDefinitionWorkItem | TripDefinition) => {
        if ('startDateTime' in option && 'endDateTime' in option) {
            return ' [' + getDisplayTimeRange(
                getStartAndEndTimeWithModifierFromDateTimeStr(option.startDateTime, option.endDateTime, currentDay)
            ) + ']';
        } else if ('startTime' in option && 'endTime' in option && 'startTimeIsOnNextDay' in option && 'endTimeIsOnNextDay' in option) {
            return ' [' + getDisplayTimeRange({
                startTime: option.startTime,
                startTimeIsOnNextDay: option.startTimeIsOnNextDay,
                endTime: option.endTime,
                endTimeIsOnNextDay: option.endTimeIsOnNextDay
            }) + ']';
        } else {
            return '';
        }
    };

    return (
        <Form>
            {!isLoading && options ?
                <Autocomplete
                    options={options}
                    name={name}
                    label="Reis"
                    getOptionLabel={option =>
                        option.code ? option.code + getDateTimeRange(option) : ''
                    }
                />
                :
                <LoadingField />
            }
            <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', py: 3}}>
                <Box maxWidth="50%">
                    <Button
                        text="Loobu"
                        variant="outlined"
                        onClick={() => dispatch(clearDialogData())}
                        startIcon={<Clear />}
                    />
                </Box>
                <Box maxWidth="50%">
                    <Button text="Lisa" type="submit" startIcon={<Add />} disabled={isLoading} />
                </Box>
            </DialogActions>
        </Form>
    );
}
