import React from "react";
import {Form, FormikProps} from "formik";
import {WorkGroupForm} from "../types";
import {WorkGroup, ResourceType, WorkGroupVersion} from "../../../../../API/types";
import {Box, Typography} from "@mui/material";
import RegionSelect from "../../../../../components/PageHeader/RegionSelect";
import DetailViewButtons from "../../../../../components/Buttons/DetailViewButtons";
import theme from "../../../../../theme";
import TextField from "../../../../../components/Form/TextField";
import DatePicker from "../../../../../components/Form/DatePicker";
import Select from "../../../../../components/Form/Select";
import {getResourceTypeTranslationFromStr} from "../../../../../utils/enumTranslations";
import RepetitionRulesInput from "../../../../../components/Form/RepetiotionRulesInput";
import routes from "../../../../../routes";
import {getDateWithAddedDays, getDisplayDate} from "../../../../../utils/dateUtils";
import VersionSelect from "./VersionSelect";
import {useAuth} from "../../../../../contexts/AuthContext";


interface WorkGroupEditFormProps {
    formikProps: FormikProps<WorkGroupForm>;
    workGroup: WorkGroup | null | undefined;
    defaultFormValues: WorkGroupForm,
    workGroupRegion: string | undefined,
    useDialogVariant?: boolean;
    handleCancelButtonPress?: () => void;
    disableValidFrom?: boolean;
    showNewVersionWarning?: boolean;
    selectedVersion?: WorkGroupVersion;
    setSelectedVersion: (newVersion: WorkGroupVersion | undefined) => void;
}

export default function WorkGroupEditForm({
    formikProps, workGroup, defaultFormValues, workGroupRegion, useDialogVariant, handleCancelButtonPress,
    disableValidFrom, showNewVersionWarning, selectedVersion, setSelectedVersion
}: WorkGroupEditFormProps) {
    const { authenticatedUser } = useAuth();
    const canEdit = (authenticatedUser?.regionalFilteringApplies && workGroup)
        ? authenticatedUser.regions.some(region => region.id === workGroup.regionId)
        : true;

    const showVersionSelect = (
        !useDialogVariant && !!workGroup && !!workGroup.versions && workGroup.versions.length > 1
    );

    const renderDetailViewButtons = () => {
        return (
            <DetailViewButtons
                id={workGroup ? workGroup.id : undefined}
                defaultFormValues={defaultFormValues}
                formikProps={formikProps}
                listViewPath={routes.AUTHENTICATED.WORK_GROUPS.LIST}
                useDialogVariant={useDialogVariant}
                handleCancelButtonPress={handleCancelButtonPress}
                allowSaveWithoutChanges={disableValidFrom}
            />
        );
    };

    return (
        <Form>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, justifyContent: 'space-between', mb: 2}}>
                <Box>
                    <Typography variant="h5" pt={{xs: 1, sm: 0}}>
                        {workGroup ? workGroup.code : 'Uus töögrupp'}
                    </Typography>
                    {workGroupRegion &&
                        <Typography variant="body2" color="text.secondary">
                            {workGroupRegion}
                        </Typography>
                    }
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', mt: {xs: 1, md: 0}}}>
                    {!workGroup && <RegionSelect styles={{pr: {xs: 0, sm: useDialogVariant ? 0 : 1}}} />}
                    {showVersionSelect && <VersionSelect versions={workGroup?.versions ?? []}
                                                         selectedVersion={selectedVersion}
                                                         handleChange={setSelectedVersion}
                                                         sx={{pr: {xs: 0, sm: useDialogVariant ? 0 : 1}}} />}
                    {!useDialogVariant &&
                        <Box sx={{my: 0.5, [theme.breakpoints.down('sm')]: {display: 'none'}}}>
                            {renderDetailViewButtons()}
                        </Box>
                    }
                </Box>
            </Box>
            <Box sx={{
                display: 'flex', flexDirection: 'column', width: '450px', maxWidth: '100%',
                [theme.breakpoints.down('sm')]: {width: '100%'}
            }}>
                <TextField
                    name="code"
                    label="Kood*"
                    disabled={!canEdit}
                    description="Tööajakavas jääb nähtavale üksnes alakriipsule eelnev osa või kuni neli tähemärki."
                />
                <Select
                    name="type"
                    label="Tüüp*"
                    options={Object.values(ResourceType)}
                    translationFunction={getResourceTypeTranslationFromStr}
                    translationEnumType={ResourceType}
                    disabled={!canEdit}
                />
                <DatePicker name="validFrom" label="Algus*" disabled={!!disableValidFrom || !canEdit} />
                <DatePicker name="validTo" label="Lõpp" disabled={!canEdit} />
                <DatePicker name="startDate" label="Sesonaalse kehtivuse alguskuupäev" hideYear disabled={!canEdit} />
                <DatePicker name="endDate" label="Sesonaalse kehtivuse lõpukuupäev" hideYear disabled={!canEdit} />
                <RepetitionRulesInput name="repetition" disabled={!canEdit} />
                <TextField name="comment" label="Kommentaar" multiline minRows={2} maxRows={5} disabled={!canEdit} />
                {showNewVersionWarning && formikProps.values.validFrom &&
                    <Typography variant="body2" color="warning.light">
                        {'Uue versiooni loomisel määratakse algse töögrupi lõppkuupäevaks ' +
                            getDisplayDate(getDateWithAddedDays(formikProps.values.validFrom, -1))}
                    </Typography>
                }
                <Box sx={useDialogVariant ? {} : {[theme.breakpoints.up('sm')]: {display: 'none'}}}>
                    {renderDetailViewButtons()}
                </Box>
            </Box>
        </Form>
    );
}