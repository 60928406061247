import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {
    addOppositeWorkGroupToTrip,
    clearDialogData,
    selectDialogData,
    selectTripDefinitionById
} from "../../../../../store/plannerSlice";
import {selectDisplayWorkGroupByIdMemoized} from "../../../store/selectors";
import {AddOppositeWorkGroupForm} from "./types";
import {FormikHelpers} from "formik";
import {getStartAndEndPointLabel} from "../../../../../utils/utils";
import React from "react";
import AddOppositeWorkGroupDialog from "./index";

export default function AddOppositeWorkGroupToTrip() {
    const dispatch = useAppDispatch();
    const addOppositeWorkGroupToTripDialogData = useAppSelector(selectDialogData).addOppositeWorkGroupToTrip;
    if (!addOppositeWorkGroupToTripDialogData) return <></>;
    const workGroup = useAppSelector(state => selectDisplayWorkGroupByIdMemoized(state, addOppositeWorkGroupToTripDialogData.workGroupId));
    const trip = useAppSelector(state => selectTripDefinitionById(state, addOppositeWorkGroupToTripDialogData.tripId));

    if (!workGroup || !trip) return <></>;

    const handleClose = () => dispatch(clearDialogData());

    const handleSubmit = (form: AddOppositeWorkGroupForm, formHelpers: FormikHelpers<AddOppositeWorkGroupForm>) => {
        if (form.workGroup) {
            formHelpers.setSubmitting(true);
            dispatch(addOppositeWorkGroupToTrip({
                tripId: addOppositeWorkGroupToTripDialogData.tripId,
                newWorkGroup: form.workGroup,
            })).finally(() => formHelpers.setSubmitting(false));
        }
    };

    return <AddOppositeWorkGroupDialog
        isOpen={!!addOppositeWorkGroupToTripDialogData}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={`Reisile ${trip.code} ${getStartAndEndPointLabel(trip.route)}`}
        workGroup={workGroup}
    />
}