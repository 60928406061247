import React, {useEffect, useMemo, useState} from "react";
import {GridColDef} from "@mui/x-data-grid";
import {Dayjs} from "dayjs";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {PayslipViewType, payslipViewTypeValues} from "../../types";
import {formatName} from "../../../workSchedule/utils";
import EditableDataGrid, {defaultColDef, numberColDef} from "../../../../../components/EditableDataGrid";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import {Region} from "../../../../../API/types";
import {getPayslipHours, updatePayslipHours} from "../../../../../API";
import {decimalToStr, strToDecimal} from "../../../../../utils/utils";
import {getTransportContractLabels, getTransportContractOptionIds} from "../../utils";
import {fetchTransportContracts, selectTransportContracts} from "../../../../../store/transportContractsSlice";
import {setToast} from "../../../../../store/toastSlice";
import {mapErrors} from "../../../../../utils/errorMapping";
import {getYearMonthString, startOfPreviousMonth} from "../../../../../utils/dateUtils";
import ListView from "../../../../../layouts/ListViewWrapper";


interface CharterTripHoursRow {
    id: string;
    driverContractId: number;
    transportContractId: number;
    driverName: string;
    workingHours: string;
    nationalHolidayWorkHours: string;
    nightTimeHours: string;
    disabled: boolean;
}

interface CharterTripHoursProps {
    month: Dayjs;
    handleChangeMonth: (month: Dayjs) => void;
    payslipViewType: PayslipViewType,
    handleChangePayslipViewType: (value: string) => void;
}

const CharterTripHours = ({month, handleChangeMonth, payslipViewType, handleChangePayslipViewType}: CharterTripHoursProps) => {
    const dispatch = useAppDispatch();
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const transportContracts = useAppSelector(selectTransportContracts);
    const [initialRows, setInitialRows] = useState<CharterTripHoursRow[] | undefined>(undefined);
    const transportContractLabels = useMemo(() => getTransportContractLabels(transportContracts),[transportContracts]);

    useEffect(() => {
        dispatch(fetchTransportContracts());
    }, []);

    useEffect(() => {
        loadCharterTripHours(month, selectedRegion);
    }, [month, selectedRegion]);

    const loadCharterTripHours = (month: Dayjs, selectedRegion?: Region) => {
        if (selectedRegion) {
            getPayslipHours(selectedRegion.id, month)
                .then(result => {
                    setInitialRows(result
                        .map(payslipHoursRow => {
                            const driverContract = payslipHoursRow.driverContract;

                            return {
                                id: `${driverContract.id}-${getYearMonthString(month)}`,
                                driverContractId: driverContract.id,
                                transportContractId: payslipHoursRow.transportContractId ?? 0,
                                driverName: `${formatName(driverContract.driver, 'lastNameFirst')} (${driverContract.number})`,
                                workingHours: decimalToStr(payslipHoursRow.workingHours),
                                nationalHolidayWorkHours: decimalToStr(payslipHoursRow.nationalHolidayHours),
                                nightTimeHours: decimalToStr(payslipHoursRow.nightTimeHours),
                                disabled: payslipHoursRow.payslipGenerated,
                            };
                        })
                        .sort((a,b) => a.driverName.localeCompare(b.driverName))
                    );
                })
                .catch(apiError =>
                    dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Tellimusvedude töötundide pärimisel esines viga'}))
                );
        } else {
            setInitialRows([]);
        }
    };

    const handleSave = (row: CharterTripHoursRow): Promise<void> => {
        return updatePayslipHours(row.driverContractId, month, {
            workingHours: strToDecimal(row.workingHours),
            nationalHolidayHours: strToDecimal(row.nationalHolidayWorkHours),
            nightTimeHours: strToDecimal(row.nightTimeHours),
            transportContractId: row.transportContractId
        });
    };

    const columns: GridColDef[] = [
        {
            ...defaultColDef,
            field: 'driverName',
            headerName: 'Juht ja leping',
            editable: false,
            width: 250
        },
        {
            field: 'transportContractId',
            headerName: 'Veoleping',
            width: 250,
            editable: true,
            type: 'singleSelect',
            valueOptions: getTransportContractOptionIds(transportContracts, month, selectedRegion),
            getOptionLabel: (value) => transportContractLabels.get(value as number) ?? '-',
            renderCell: (params) => transportContractLabels.get(params.value as number) ?? '-',
        },
        {
            ...numberColDef,
            field: 'workingHours',
            headerName: 'Töötunde',
            width: 100
        },
        {
            ...numberColDef,
            field: 'nationalHolidayWorkHours',
            headerName: 'sh. riigipüha tunde',
            width: 160
        },
        {
            ...numberColDef,
            field: 'nightTimeHours',
            headerName: 'sh. öötöö tunde',
            width: 140
        },
    ];

    const getErrors = (row: CharterTripHoursRow) => (row.transportContractId === 0) ? 'Veoleping on kohustuslik väli' : undefined;

    return (
        <ListView
            headerProps={{
                title: 'Palgalehed',
                toggleProps: {
                    options: payslipViewTypeValues.map(value => value.toString()),
                    value: payslipViewType,
                    handleChange: handleChangePayslipViewType,
                },
                showRegionSelect: true,
            }}
            isLoading={!initialRows}
        >
            <EditableDataGrid
                initialRows={initialRows ?? []}
                columns={columns}
                monthSelect={{month: month, handleChangeMonth: handleChangeMonth, defaultMonth: startOfPreviousMonth()}}
                handleSave={handleSave}
                getErrors={getErrors}
                disabledRowText="Juhile on juba palgaleht genereeritud. Töötundide muutmiseks kustuta palgaleht."
            />
        </ListView>
    );
};

export default CharterTripHours;
