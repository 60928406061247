export interface CharterOrder {
    orderNumber: string;
    client: string;
}

export interface CharterTrip {
    id: number;
    orderNumber: string;
    route: string;
    departurePoint: string;
    destination: string;
    client: string;
    startDateTime: string;
    endDateTime: string;
    date: string;
    distance: number;
    numberOfPassengers: number;
    regionId: number;
    status: CharterTripStatus;
    isInvoiced: boolean;
    price: number;
    comment?: string;
}

export interface SaveCharterTrip {
    orderNumber: string;
    route: string;
    departurePoint: string;
    destination: string;
    client: string;
    startDateTime: string;
    endDateTime: string;
    date: string;
    distance: number;
    numberOfPassengers: number;
    regionId: number;
    status: CharterTripStatus;
    isInvoiced: boolean;
    price: number;
    comment?: string;
}

export enum CharterTripStatus {
    CONFIRMED = 'CONFIRMED',
    CANCELLED = 'CANCELLED',
    PLANNED = 'PLANNED',
    DONE = 'DONE',
}
