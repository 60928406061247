import {TripReport} from "../../../../API/reports/types";
import {Dayjs} from "dayjs";
import {Region} from "../../../../API/region/types";
import {Columns, Row, SheetData} from "write-excel-file";
import {BORDER_COLOR} from "../constants";
import {getStartAndEndPointLabel} from "../../../../utils/utils";

const groupByContractAndLineNumber = (data: TripReport[]): Map<string, Map<string, TripReport[]>> => {
    const map = new Map<string, Map<string, TripReport[]>>();
    data.forEach((item) => {
        const key = item.transportContractName;
        let collection = map.get(key);
        if (!collection) {
            collection = new Map<string, TripReport[]>();
            map.set(key, collection);
        }
        const trip = collection.get(item.lineNumber);
        if (!trip) {
            collection.set(item.lineNumber, [item]);
        } else {
            trip.push(item);
        }
    });

    return map;
};
export const formatMonthlyLineReportData = (data: TripReport[], startDate: Dayjs, endDate: Dayjs, selectedRegion?: Region): [SheetData, Columns] => {
    data.sort((a, b) => a.lineNumber > b.lineNumber ? 1 : -1)

    const titleRows: Row[] = [
        [{value: `${selectedRegion?.name.toUpperCase()} PIIRKONNA LIINIARUANNE`, fontWeight: 'bold', span: 4}],
        [{value: `${startDate.format('DD.MM.YYYY')} - ${endDate.format('DD.MM.YYYY')}`, fontWeight: 'bold', span: 3}],
        [],
        []
    ];

    const columnHeaders: Row = [
        {value: 'Liin', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Liini nimetus', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Läbisõit (plaaniline)', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Läbisõit (tegelik)', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Liiniaeg (tegelik)', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Kohtkilomeetrid', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
    ];

    const grouped = groupByContractAndLineNumber(data);
    const dataRows: Row[] = [];
    grouped.forEach(((lineNrToTripsMap, contractName) => {
        dataRows.push([]);
        dataRows.push([{value: `[Veoleping] ${contractName}`, span: 2, fontWeight: 'bold'}]);
        let totalContractPlannedDistance = 0;
        let totalContractRealDistance = 0;
        let totalContractRealLineTime = 0;
        let totalContractPaxKm = 0;

        lineNrToTripsMap.forEach((tripReports, lineNumber) => {
            const startAndEndPoint = tripReports.length && lineNumber !== '' ? getStartAndEndPointLabel(tripReports[0].route) : '';
            const totalPlannedDistance: number = tripReports.reduce((sum, {plannedDistance}) => sum + plannedDistance, 0);
            const totalRealDistance: number = tripReports.reduce((sum, {realDistance}) => sum + realDistance, 0);
            const totalRealLineTime: number = tripReports.reduce((sum, {realLineTime}) => sum + realLineTime, 0);
            const paxKm: number = tripReports.reduce((sum, {paxKm}) => sum + paxKm, 0);

            totalContractPlannedDistance += totalPlannedDistance;
            totalContractRealDistance += totalRealDistance;
            totalContractRealLineTime += totalRealLineTime;
            totalContractPaxKm += paxKm;

            dataRows.push([
                {value: lineNumber === '' ? 'määramata' : lineNumber, type: String},
                {value: startAndEndPoint, type: String},
                {value: totalPlannedDistance, type: Number},
                {value: totalRealDistance, type: Number},
                {value: totalRealLineTime, type: Number},
                {value: paxKm, type: Number},
            ]);
        });
        dataRows.push([
            {value: 'Kokku', type: String, span: 2, align: 'right', fontWeight: 'bold'},
            null,
            {value: totalContractPlannedDistance, type: Number},
            {value: totalContractRealDistance, type: Number},
            {value: totalContractRealLineTime, type: Number},
            {value: totalContractPaxKm, type: Number},
        ]);
    }));

    return [[...titleRows, columnHeaders, ...dataRows], []];
};