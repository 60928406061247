import dayjs, {Dayjs} from "dayjs";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {getItemBgColor, shortenWorkGroupCode} from "../utils";
import CustomCell from "./CustomCell";
import {ItemCellTooltip} from "./ItemCellTooltip";
import React, {useCallback, useMemo} from "react";
import {isActiveOnDay, restTimeLessThan9Hours} from "../../utils";
import {DEFAULT_CELL_WIDTH, disabledBackground} from "../index";
import {shallowEqual} from "react-redux";
import {selectActivityByDayAndId, selectRequestByDayAndId} from "../../store/selectors";
import {darken} from "@mui/material";
import {WorkScheduleItemStatus, WorkScheduleItemType} from "../../../../../API/workSchedule/types";
import {EntityId} from "@reduxjs/toolkit";
import {selectDriverContractById} from "../../../../../store/driverContractSlice";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import {Region, ResourceType} from "../../../../../API/types";
import {WorkScheduleItemContraction} from "../../types";
import {setDialogData} from "../../../../../store/workScheduleSlice";
import {selectToggledResourceType} from "../../../../../store/viewSlice";
import {UnscheduledWorkGroup} from "../../../../../store/workScheduleItemSlice";
import {addWorkScheduleItem} from "../../store/actions";
import {DefectSimple} from "../../../../../API/defects/types";


export const getCellLabel = (item: any, selectedRegion?: Region): string =>
    item?.workGroupCode
        ? shortenWorkGroupCode(item.workGroupCode)+ (item?.regionId !== selectedRegion?.id ? '*' : '')
        : WorkScheduleItemContraction.get(item?.type) ?? '';

const ItemCell = ({contractId, day, defects}: {
    contractId: EntityId;
    day: Dayjs;
    defects?: DefectSimple[];
}) => {
    const activity = useAppSelector(selectActivityByDayAndId(contractId, day), shallowEqual);
    const prevDayActivity = useAppSelector(selectActivityByDayAndId(contractId, day.subtract(1, 'day')), shallowEqual);
    const request = useAppSelector(selectRequestByDayAndId(contractId, day), shallowEqual);
    const selectedRegion = useAppSelector(selectSelectedRegion);

    const confirmed = useAppSelector(state => state.workSchedule.view.scheduleType === 'KINNITATUD');
    const driverContract = useAppSelector((state) => selectDriverContractById(state, contractId.toString()));
    const resourceType = useAppSelector(selectToggledResourceType);
    const dispatch = useAppDispatch();

    const isEditModeActive = useAppSelector(state => state.workSchedule.view.editModeData.isActive);
    const selectedEditModeWorkGroup = useAppSelector(state => state.workSchedule.view.editModeData.selectedWorkGroup);

    const warning = resourceType === ResourceType.DRIVER && restTimeLessThan9Hours(activity, prevDayActivity);
    const cellLabel = getCellLabel(activity ?? request, selectedRegion);

    const sx = activity && request ? {
        background: `repeating-linear-gradient(
            -45deg,
            ${getItemBgColor(activity.type)},
            ${getItemBgColor(activity.type)} 10px,
            ${getItemBgColor(request.type)} 10px,
            ${getItemBgColor(request.type)} 15px
        )`,
    } : {};

    if (activity && !request && activity.type === WorkScheduleItemType.WORK_GROUP && activity?.regionId !== selectedRegion?.id) {
        sx.background = `repeating-linear-gradient(
            -45deg,
            ${getItemBgColor(activity.type)},
            ${getItemBgColor(activity.type)} 10px,
            ${darken(getItemBgColor(activity.type) ?? '#FFF',.1)} 10px,
            ${darken(getItemBgColor(activity.type) ?? '#FFF',.1)} 15px
        )`;
    }

    const handleEditModeWorkScheduleItemSave = (unscheduledWorkScheduleItem: UnscheduledWorkGroup) => {
        dispatch(addWorkScheduleItem({
            comment: '',
            regionId: selectedRegion?.id ?? 0,
            status: WorkScheduleItemStatus.SCHEDULED,
            type: WorkScheduleItemType.WORK_GROUP,
            workGroupId: unscheduledWorkScheduleItem.id,
            workItemIds: [],
            id: undefined,
            resourceType: resourceType,
            resourceId: Number(contractId),
            startDate: day.format('YYYY-MM-DD'),
            endDate: day.format('YYYY-MM-DD'),
        }));
    };

    const handleCellClick = useCallback(() => {
        if (!confirmed) {
            if (isEditModeActive && selectedEditModeWorkGroup) {
                handleEditModeWorkScheduleItemSave(selectedEditModeWorkGroup);
            } else {
                dispatch(setDialogData({
                    resourceId: contractId,
                    date: day.format('YYYY-MM-DD'),
                }));
            }
        }
    }, [contractId, day, dispatch, confirmed, isEditModeActive, selectedEditModeWorkGroup]);

    const driverIsActiveOnDay = useMemo(() => {
        if (!driverContract) {
            return false;
        }
        return isActiveOnDay({
            contractStartDate: dayjs(driverContract.startDate),
            contractEndDate: driverContract.endDate ? dayjs(driverContract.endDate) : null,
            nominalWeeklyWorkingHours: driverContract.nominalWeeklyWorkingHours,
        }, day);
    }, [driverContract, day]);

    if (resourceType === ResourceType.DRIVER && !driverIsActiveOnDay) {
        return <CustomCell
            content={cellLabel}
            sx={{backgroundColor: disabledBackground, minWidth: DEFAULT_CELL_WIDTH}}
        />
    }

    return (
        <CustomCell
            hasWarning={warning}
            bgColor={getItemBgColor((activity ?? request)?.type)}
            content={cellLabel}
            onClick={handleCellClick}
            defects={defects}
            tooltip={(activity || request || (defects && defects.length > 0)) && !selectedEditModeWorkGroup ? (
                <ItemCellTooltip activity={activity} request={request} warning={warning} defects={defects} />
            ) : undefined}
            sx={{
                ...sx,
                minWidth: DEFAULT_CELL_WIDTH,
                opacity: confirmed ? 0.7 : undefined,
            }}
        />
    );
};

export default React.memo(ItemCell);
