import {Box} from '@mui/material';
import React from 'react';
import TimelineBackground from "../../Timeline/BackgroundRow";
import TripCardWrapper from "../../Trip/CardWrapper";
import {useAppSelector} from "../../../../../hooks";
import {selectPlannerType} from "../../../../../store/plannerSlice";
import {PlannerType} from "../../../types";
import TripDefinitionWorkItemCardWrapper from '../../TripDefinitionWorkItem/CardWrapper';


interface UnassignedTripRowProps {
    unassignedTripIds: number[];
}

const UnassignedTripRow = ({ unassignedTripIds }: UnassignedTripRowProps) => {
    const plannerType = useAppSelector(selectPlannerType);

    return (
        <Box>
            <TimelineBackground />
            {plannerType === PlannerType.WORK_GROUP && unassignedTripIds.map(tripId =>
                <TripCardWrapper key={tripId} tripSegmentId={tripId} />
            )}
            {plannerType === PlannerType.WORK_SHEET && unassignedTripIds.map(tripId =>
                <TripDefinitionWorkItemCardWrapper key={tripId} workItemId={tripId} />
            )}
        </Box>
    );
};

export default UnassignedTripRow;
