import {
    DisplayWorkGroupActivity,
    DisplayWorkGroupItemDetails,
    PlannerTimeline,
    StartAndEndTimeWithModifier,
} from "../types";
import {getTimeInHours, truncateStr} from "./utils";
import {ACTIVITY_LETTER_WIDTH, TRIP_SUBTITLE_LETTER_WIDTH, TRIP_TITLE_LETTER_WIDTH} from "../constants";
import {MIN_TRIP_CARD_WIDTH} from "../components/Trip/Card";
import {getWorkGroupItemTypeTranslation} from "../../../utils/enumTranslations";
import {MIN_ACTIVITY_CARD_WIDTH} from "../components/AssignedItemsContainer/Activity/Card";
import {RoutePoint} from "../../../API/types";
import {getStartAndEndPoint} from "../../../utils/utils";


export const getItemXPos = (startHh: number, plannerTimeline: PlannerTimeline, contentWidth: number) =>
    ((startHh - plannerTimeline.startTime) / plannerTimeline.duration) * contentWidth;

export const getItemWidth = (startHh: number, endHh: number, plannerTimeline: PlannerTimeline, contentWidth: number) =>
    ((endHh - startHh) / plannerTimeline.duration) * contentWidth;

export const getDisplayWorkGroupItemDetails = (
    item: StartAndEndTimeWithModifier, plannerTimeline: PlannerTimeline, contentWidth: number
): DisplayWorkGroupItemDetails => {
    const startHh = getTimeInHours(item.startTime, item.startTimeIsOnNextDay);
    const endHh = getTimeInHours(item.endTime, item.endTimeIsOnNextDay);

    return {
        xPos: getItemXPos(startHh, plannerTimeline, contentWidth),
        width: getItemWidth(startHh, endHh, plannerTimeline, contentWidth),
    };
};

export const getDisplayTripCode = (code: string, width: number) => {
    const maxLength = Math.round((width - 3 * TRIP_TITLE_LETTER_WIDTH) / TRIP_TITLE_LETTER_WIDTH);
    if (width < MIN_TRIP_CARD_WIDTH) return '';
    return truncateStr(code, maxLength);
};

export const getDisplayStartAndEndPoint = (route: RoutePoint[] | null, width: number) => {
    const {startPoint, endPoint} = getStartAndEndPoint(route);

    // card width, minus dots and dash length, divided by one letter px, divided equally between start and end point
    const maxLength = Math.round((width - 7 * TRIP_SUBTITLE_LETTER_WIDTH) / TRIP_SUBTITLE_LETTER_WIDTH);
    // if there is not enough room for one letter from both point then return empty string
    if (maxLength < 2) return '';

    if (startPoint && endPoint) {
        let textLength = startPoint.name.length + endPoint.name.length;
        // if text fits card then give names space respectively to their length, if not then shorten equally
        if (maxLength > textLength) textLength = maxLength / 2;
        return (
            truncateStr(startPoint.name, (maxLength / textLength * startPoint.name.length))
            + ' - '
            + truncateStr(endPoint.name, (maxLength / textLength * endPoint.name.length))
        ).toUpperCase();
    }

    const point = startPoint ?? endPoint;
    if (point) return truncateStr(point.name, point.name.length).toUpperCase();

    return '';
};

export const getDisplayActivityName = (activity: DisplayWorkGroupActivity) => {
    const maxLength = Math.round((activity.width - 3 * ACTIVITY_LETTER_WIDTH) / ACTIVITY_LETTER_WIDTH);
    if (activity.width < MIN_ACTIVITY_CARD_WIDTH / 2) return '';
    return truncateStr(getWorkGroupItemTypeTranslation(activity.type), maxLength).toUpperCase();
};
