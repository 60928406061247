import {WorkSheetWorkItem} from "../../../../../../../API/workSheets/types";
import React, {useState} from "react";
import CardBase from "../../../components/CardBase";
import {Box, Collapse, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography} from "@mui/material";
import {getWorkGroupItemTypeTranslation} from "../../../../../../../utils/enumTranslations";
import BusLabel from "../../../components/BusLabel";
import TimeLabel from "../../../components/TimeLabel";
import {getStartAndEndPointLabel} from "../../../../../../../utils/utils";
import {RoutePoint} from "../../../../../../../API/types";
import {Comment, Hail} from "@mui/icons-material";
import {useAppSelector} from "../../../../../../../hooks";
import {selectCurrentDriverBusByBusWorkSheetId} from "../../../../../../../store/currentDriverWorkSheetsSlice";

const getRoutePointLabel = (routePoint: RoutePoint) => {
    return (
        <Typography variant="body2" sx={{alignItems: 'center', display: 'flex', height: '20px'}}>
            {routePoint.name}
            {routePoint.stopName && ` (${routePoint.stopName})`}
            {routePoint.requestStop && <Tooltip title="Nõudepeatus"><Hail sx={{height: '18px'}} /></Tooltip>}
        </Typography>
    );
};

const ActivityCard = ({item}: { item: WorkSheetWorkItem }) => {
    const [cardOpen, setCardOpen] = useState(false);

    const bus = useAppSelector(state => selectCurrentDriverBusByBusWorkSheetId(state, item.busWorkSheetId ?? 0));

    const handleClick = () => {
        setCardOpen(prevState => !prevState);
    };

    return (
        <CardBase onClick={item.route ? handleClick : undefined}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Typography>
                    <Box component="span" sx={{fontWeight: 'bold'}}>
                        {getWorkGroupItemTypeTranslation(item.type)}
                    </Box>
                    {item.trip?.code && ` ${item.trip.code}`}
                    {item.trip?.lineNumber && ` (liin ${item.trip.lineNumber})`}
                </Typography>
                <Typography>
                    <TimeLabel startDateTime={item.startDateTime} endDateTime={item.endDateTime} />
                </Typography>
            </Box>
            <Box sx={{pb: 0.5}}>
                {item.trip?.comment && item.trip.comment.length > 0 &&
                    <Typography variant="body2" color="text.secondary" sx={{display: 'flex', alignItems: 'center'}}>
                        <Comment sx={{fontSize: '16px', pr: 0.5, color: 'text.secondary'}}/>{item.trip.comment}
                    </Typography>
                }
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="body2">
                    {getStartAndEndPointLabel(item.route)}
                    {item.distance ? ` (${item.distance.toFixed(0)}km)` : ''}
                </Typography>
                {bus && <BusLabel licencePlateNumbers={[bus.licencePlateNumber]}/>}
            </Box>
            {item.comment && <Typography variant="body2" sx={{whiteSpace: 'pre-wrap'}}>{item.comment}</Typography>}
            <Collapse in={cardOpen}>
                {item.route && item.route.length > 0 && <Box>
                    <List sx={{pl: 1}}>
                        {item.route.map(routePoint =>
                            <ListItem key={routePoint.id} disablePadding>
                                <ListItemIcon sx={{minWidth: '10px'}}> - </ListItemIcon>
                                <ListItemText primary={getRoutePointLabel(routePoint)} sx={{m: 0}}/>
                            </ListItem>
                        )}
                    </List>
                </Box>}
            </Collapse>
        </CardBase>
    );
};

export default ActivityCard;
