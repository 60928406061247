import {Box, Collapse, FormHelperText, Typography, useTheme} from "@mui/material";
import React, {useMemo, useState} from "react";
import {Add, Close, Delete} from "@mui/icons-material";
import Button from "../../../../../components/Buttons/Button";
import {FieldArray, useField} from "formik";
import {RoutePointForm} from "../../../tripDefinitions/detailsView/types";
import {shallowEqual} from "react-redux";
import {FuelCard} from "../../../../../API/types";
import TextField from "../../../../../components/Form/TextField";
import IconButton from "@mui/material/IconButton";

const createEmptyItem = () => ({
    name: '',
    pin: '',
});

interface FuelCardsProps {
    name: string;
    disabled?: boolean;
}

const FuelCards = ({name, disabled}: FuelCardsProps) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [field, meta, helpers] = useField(name);

    const hasErrors = !isOpen && meta.error && meta.touched;

    const values: FuelCard[] = field.value;

    const handleClose = () => {
        const emptyPoint = createEmptyItem();
        const nonEmptyPoints = field.value.filter((point: RoutePointForm) => !shallowEqual(point, emptyPoint));
        helpers.setValue(nonEmptyPoints);
        setIsOpen(false);
    };

    const handleOpen = () => {
        if (field.value.length === 0) {
            helpers.setValue([createEmptyItem()]);
        }
        setIsOpen(true);
    };

    const label = useMemo(() => {
        return values.map(value => value.name).join(', ')
    }, [values]);

    return (
        <FieldArray name={name} render={arrayHelpers => (
            <Box>
                <Box sx={{
                    border: '1px solid rgba(0,0,0,0.23)',
                    '&:hover': {
                        borderColor: isOpen || hasErrors ? undefined : 'rgba(0,0,0,0.87)',
                    },
                    borderColor: hasErrors ? theme.palette.error.main : undefined,
                    borderRadius: '4px',
                    py: 1,
                    px: 1.5,
                    my: 1,
                    mx: 0,
                    boxSizing: 'border-box',
                }} component="fieldset">
                    <Box width="100%" display={label.length > 0 || isOpen ? 'initial': 'none'}>
                        <Typography
                            color={hasErrors ? theme.palette.error.main : 'text.secondary'}
                            component="h5"
                            sx={{width: 'fit-content', fontSize: '12px', px: 0.5, mt: -2.2, backgroundColor: theme.palette.common.white}}
                        >
                            Kütusekaardid
                        </Typography>
                    </Box>
                    <Typography
                        color={hasErrors
                            ? theme.palette.error.main
                            : label.length > 0 ? 'initial' : 'text.secondary'}
                        display={isOpen ? 'none' : 'block'}
                        role="button"
                        sx={{cursor: 'pointer'}}
                        onClick={handleOpen}
                    >
                        {label.length > 0 ? label : 'Kütusekaardid'}
                    </Typography>
                    <Collapse in={isOpen}>
                        <Box sx={{py: 0.5}}>
                            {values.map((_: FuelCard, index: number) => {
                                return (
                                    <Box key={index} sx={{display: 'flex', alignItems: 'top', justifyContent: 'space-between'}}>
                                        <TextField name={`${name}[${index}].name`} label="Kaardi nimi" disabled={disabled} />
                                        <TextField name={`${name}[${index}].pin`} label="PIN" style={{width: '6rem'}} disabled={disabled} />
                                        <Box sx={{mt: '10px'}}>
                                            <IconButton size="small" onClick={() => arrayHelpers.remove(index)} aria-label="Kustuta" disabled={disabled}>
                                                <Delete />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                );
                            })}
                            <Button
                                size="small"
                                text="Lisa kaart"
                                onClick={() => arrayHelpers.push(createEmptyItem())}
                                startIcon={<Add />}
                                variant="text"
                                styles={{width: {xs: 'fit-content'}}}
                                disabled={disabled}
                            />
                            <Box sx={{mb: '-27px', mx: 'auto', width: 'fit-content', backgroundColor: theme.palette.common.white}}>
                                <Button
                                    size="small"
                                    text="Sulge kütusekaardid"
                                    onClick={handleClose}
                                    startIcon={<Close />}
                                    variant="text"
                                />
                            </Box>
                        </Box>
                    </Collapse>
                </Box>
                {hasErrors && <FormHelperText sx={{color: theme.palette.error.main, mt: -0.5, mx: 1.75}}>
                    Kütusekaartide vormis esineb vigu
                </FormHelperText>}
            </Box>
        )} />
    );
};

export default FuelCards;
