import {Box} from "@mui/material";
import CustomCell from "./CustomCell";
import {roundToDecimals} from "../../../../../utils/utils";
import React from "react";
import dayjs, {Dayjs} from "dayjs";
import {useAppDispatch, useAppSelector, useWorkScheduleSelectedDay} from "../../../../../hooks";
import {selectUnscheduledWorkGroups} from "../../store/selectors";
import {selectToggledResourceType} from "../../../../../store/viewSlice";
import {ResourceType, UnscheduledWorkGroup} from "../../../../../API/types";
import Button from "../../../../../components/Buttons/Button";
import {Add} from "@mui/icons-material";
import {openAddDriverDialog} from "../../../../../store/workScheduleSlice";
import {DEFAULT_CELL_WIDTH, disabledBackground} from "../index";

const getByDaySorted = (items: UnscheduledWorkGroup[], day: Dayjs) => items
    .filter(item => day.isSame(item.date, 'days'))
    .sort((a, b) => (a.code < b.code) ? -1 : (a.code > b.code) ? 1 : 0);

interface UnscheduledWorkGroupsProps {
    currentMonthDays: Dayjs[];
    labelColumnWidth: number;
}

const UnscheduledWorkGroupsRow = ({currentMonthDays, labelColumnWidth}: UnscheduledWorkGroupsProps) => {
    const unscheduledWorkGroups = useAppSelector(selectUnscheduledWorkGroups);
    const resourceType = useAppSelector(selectToggledResourceType);
    const { selectedDay, handleSelectDay } = useWorkScheduleSelectedDay();
    const dispatch = useAppDispatch();

    const handleOpenAddDriverDialog = () => {
        dispatch(openAddDriverDialog());
    };

    return (unscheduledWorkGroups.length > 0
        ?
        <Box sx={{
            displayPrint: 'none',
            zIndex: 1,
            marginTop: '-1px',
            display: 'flex',
        }} role="row">
            <CustomCell
                sx={{
                    textAlign: 'right',
                    paddingRight: 1,
                    minWidth: labelColumnWidth,
                    maxWidth: labelColumnWidth,
                    flexGrow: 0,
                    borderTopWidth: 1,
                    backgroundColor: 'common.white',
                }}
                content={
                    resourceType === ResourceType.DRIVER ?
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Button text="Lisa juht" startIcon={<Add />} variant="text" onClick={handleOpenAddDriverDialog} />
                        <Box sx={{lineHeight: 1.4}}>Planeerimata<br/> töögrupid</Box>
                    </Box>
                    : <Box sx={{lineHeight: 1.4}}>Planeerimata<br/> töögrupid</Box>
                }
            />
            {currentMonthDays.map((day) => {
                const items = getByDaySorted(unscheduledWorkGroups, day);
                const content = items.length > 0 ? items.length.toString() : '';
                const tooltip = items.length > 0 && (
                    <>{items.map(item =>
                        <Box key={item.id}>
                            {item.code}
                            {item.workScheduleItemStartDateTime && item.workScheduleItemEndDateTime && item.hours ?
                                ' (' + dayjs(item.workScheduleItemStartDateTime).format('HH:mm')
                                + ' - ' + dayjs(item.workScheduleItemEndDateTime).format('HH:mm') + ')'
                                + ' ' + roundToDecimals(item.hours, 1) + 'h'
                                : null}
                        </Box>
                    )}</>
                );

                return (
                    <CustomCell
                        key={day.toString()}
                        sx={{
                            minWidth: DEFAULT_CELL_WIDTH,
                            borderTopWidth: 1,
                            backgroundColor: selectedDay && selectedDay !== day.format('YYYY-MM-DD') ? disabledBackground : 'common.white'
                        }}
                        content={content}
                        tooltip={tooltip}
                        onClick={handleSelectDay(day)}
                    />
                )
            })}
        </Box>
        : null
    );
};

export default React.memo(UnscheduledWorkGroupsRow);