import React, {useEffect, useState} from "react";
import {Region, TripDefinition} from "../../../../../../API/types";
import {Formik, FormikHelpers, FormikProps} from "formik";
import {TripForm} from "./types";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {selectAllTripDefinitions} from "../../../../../../store/plannerSlice";
import {loadTripDefinitionsByDate} from "../../../../../../API";
import {selectDate, selectToggledResourceType} from "../../../../../../store/viewSlice";
import {setToast} from "../../../../../../store/toastSlice";
import * as Yup from "yup";
import {validationSchema} from "../../../../../../utils/formValidation";
import OtherRegionTripForm from "../Form";


const tripValidationSchema = Yup.object().shape({
    trip: validationSchema('Reis').fields.objectRequired,
});

export interface OtherRegionWorkGroupTripDefinitionFormProps {
    selectedOtherRegion: Region | undefined;
    onSubmit: (form: TripForm, formikHelpers: FormikHelpers<TripForm>) => void;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OtherRegionWorkGroupTripDefinitionForm({
    selectedOtherRegion, onSubmit, isLoading, setIsLoading
}: OtherRegionWorkGroupTripDefinitionFormProps) {
    const dispatch = useAppDispatch();
    const selectedDate = useAppSelector(selectDate);
    const currentTrips = useAppSelector(selectAllTripDefinitions);
    const resourceType = useAppSelector(selectToggledResourceType);

    const [tripDefinitions, setTripDefinitions] = useState<TripDefinition[] | undefined>(undefined);
    const initialValues: TripForm = {trip: null};

    useEffect(() => {
        if (selectedOtherRegion && selectedDate) {
            loadTripDefinitionsByDate(selectedOtherRegion.id, selectedDate)
                .then(result => {
                    const currentTripsIds = currentTrips.map(trip => trip.id);
                    const filteredOtherRegionTrips = result
                        .filter(otherRegionTrip =>
                            !(otherRegionTrip.workGroups.map(workGroup => workGroup.type).includes(resourceType)
                                || currentTripsIds.includes(otherRegionTrip.id)))
                        .sort((a,b) => a.code > b.code ? 1 : -1);
                    setTripDefinitions(filteredOtherRegionTrips);
                })
                .catch(() => {
                    setTripDefinitions([]);
                    dispatch(setToast({type: 'error', text: 'Reiside pärimisel ilmnes viga'}));
                });
        }
    }, [selectedOtherRegion]);

    useEffect(() => {
        if (tripDefinitions) setIsLoading(false);
    }, [tripDefinitions]);

    return (
        <Formik initialValues={initialValues} validationSchema={tripValidationSchema} onSubmit={onSubmit}>
            {(formikProps: FormikProps<TripForm>) => {
                useEffect(() => {
                    formikProps.setFieldValue('trip', null).then(() => {
                        setIsLoading(false);
                    });
                }, [tripDefinitions]);

                return <OtherRegionTripForm options={tripDefinitions} isLoading={isLoading} name="trip" />
            }}
        </Formik>
    );
}
