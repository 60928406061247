import React from 'react';
import {Snackbar} from "@mui/material";
import MuiAlert, {AlertProps} from '@mui/material/Alert';

export interface ToastProps {
    type: 'success' | 'info' | 'error' | 'warning';
    text: string;
}

export interface ToastInternalProps {
    open: boolean;
    type: 'success' | 'info' | 'error' | 'warning';
    text: string;
    closeToast?: () => void;
}

const Toast = ({open, type, text, closeToast}: ToastInternalProps) => {

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        if (closeToast) closeToast();
    };

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} {...props} />;
    });

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        >
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                {text}
            </Alert>
        </Snackbar>
    );
};

export default Toast;
