import {ResourceType, WorkGroupTripDefinition} from "../../../API/workGroup/types";
import {UnassignedTripsState} from "../../../store/plannerSlice";
import {getTimeInHours} from "./utils";
import {getEndPoint, getStartPoint} from "../../../utils/utils";
import {RoutePoint} from "../../../API/types";
import {TripDefinitionWorkItem} from "../types";


export const getUnassignedTrips = (allTrips: WorkGroupTripDefinition[], resourceType: ResourceType): WorkGroupTripDefinition[] =>
    allTrips.filter(trip => !trip.workGroups?.find(workGroup => workGroup.type === resourceType));

export const getUnassignedTripRows = (displayTrips: WorkGroupTripDefinition[], resourceType: ResourceType): number[][] =>
    groupUnassignedTrips(getUnassignedTrips(displayTrips, resourceType));

export const groupUnassignedTrips = (
    unassignedTrips: {
        id: number,
        startTime: string,
        startTimeIsOnNextDay: boolean,
        endTime: string,
        endTimeIsOnNextDay: boolean,
        route?: RoutePoint[] | null
    }[]
) => {
    const sortedUnassignedTrips = unassignedTrips.sort((a, b) =>
        getTimeInHours(a.startTime, a.startTimeIsOnNextDay) - getTimeInHours(b.startTime, b.startTimeIsOnNextDay)
    );
    const unassignedTripRows: number[][] = sortedUnassignedTrips.length ? [[sortedUnassignedTrips[0].id]] : [];

    if (sortedUnassignedTrips.length > 1) {
        sortedUnassignedTrips.slice(1).forEach(trip => {
            let addedToRow = false;

            for (let rowIndex = 0; rowIndex < unassignedTripRows.length; rowIndex++) {
                const lastTripInRowId = unassignedTripRows[rowIndex][unassignedTripRows[rowIndex].length - 1];
                const lastTripInRow = sortedUnassignedTrips.find(trip => trip.id === lastTripInRowId);

                if (lastTripInRow) {
                    const lastTripInRowEndHh = getTimeInHours(lastTripInRow.endTime, lastTripInRow.endTimeIsOnNextDay);
                    const lastTripInRowEndPointId = lastTripInRow.route ? getEndPoint(lastTripInRow.route)?.name : undefined;

                    const currentTripStartHh = getTimeInHours(trip.startTime, trip.startTimeIsOnNextDay);
                    const lastTripInRowStartPointId = trip.route ? getStartPoint(trip.route)?.name : undefined;

                    if (lastTripInRowEndHh <= currentTripStartHh && lastTripInRowEndPointId === lastTripInRowStartPointId) {
                        unassignedTripRows[rowIndex].push(trip.id);
                        addedToRow = true;
                        break;
                    }
                }
            }

            if (!addedToRow) unassignedTripRows.push([trip.id]);
        });
    }

    return unassignedTripRows;
};

export const getUpdatedUnassignedTripRows = (
    movedTripId: number,
    oldRows: number[][],
) => {
    const updatedRows = [...oldRows.map(unassignedTripRow => [...unassignedTripRow])];

    let modifiedExistingTrip = false;
    oldRows.forEach((unassignedTripRow, rowIndex) =>
        unassignedTripRow.forEach((unassignedTripId, tripIndex) => {
            if (movedTripId === unassignedTripId) {
                if (updatedRows[rowIndex].length === 1) {
                    updatedRows.splice(rowIndex, 1);
                } else {
                    updatedRows[rowIndex].splice(tripIndex, 1);
                }
                modifiedExistingTrip = true;
            }
        })
    )
    if (!modifiedExistingTrip) {
        if (updatedRows.length > 0 && updatedRows[updatedRows.length - 1].length === 0) {
            updatedRows[updatedRows.length - 1].push(movedTripId);
        } else {
            updatedRows.push([movedTripId]);
        }
    }

    return updatedRows;
};

export const getUpdatedUnassignedTripsState = (
    movedUnassignedTrip: {id: number, regionId: number},
    unassignedTripsState: UnassignedTripsState,
    selectedRegionId: number,
) => {
    return {
        filterInput: unassignedTripsState.filterInput,
        rows: movedUnassignedTrip.regionId === selectedRegionId
            ? getUpdatedUnassignedTripRows(movedUnassignedTrip.id, unassignedTripsState.rows)
            : unassignedTripsState.rows,
        otherRegionRows: movedUnassignedTrip.regionId !== selectedRegionId
            ? getUpdatedUnassignedTripRows(movedUnassignedTrip.id, unassignedTripsState.otherRegionRows)
            : unassignedTripsState.otherRegionRows
    };
};

export const getResetUnassignedTripsState = (
    tripDefinitionWorkItems: (TripDefinitionWorkItem | WorkGroupTripDefinition)[],
    unassignedTripsState: UnassignedTripsState,
): UnassignedTripsState => {
    const tripIdsWithinRegion = unassignedTripsState.rows.flat();
    const tripIdsFromOtherRegion = unassignedTripsState.otherRegionRows.flat();

    return {
        filterInput: unassignedTripsState.filterInput,
        rows: groupUnassignedTrips(tripDefinitionWorkItems.filter(trip => tripIdsWithinRegion.includes(trip.id))),
        otherRegionRows: groupUnassignedTrips(tripDefinitionWorkItems.filter(trip => tripIdsFromOtherRegion.includes(trip.id))),
    };
};
