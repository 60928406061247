import React, {useEffect} from "react";
import {Box} from "@mui/material";
import PageHeader from "../../../components/PageHeader";
import dayjs, {Dayjs} from "dayjs";
import {BusReport, BusStopUsagesReport, OverUnderTimeReport, Region, TripReport, WorkGroup} from "../../../API/types";
import {
    getBusReport,
    getLineReport,
    getDriverWorkGroupsReport,
    getOverUnderTimeReport,
    getBusStopUsagesReport
} from "../../../API";
import {formatMonthlyLineReportData} from "./formatters/monthlyLineReportFormatter";
import {formatBusReportData} from "./formatters/busReportFormatter";
import {formatDailyLineReport} from "./formatters/dailyLineReportFormatter";
import {SelectOptionWithIdAndPermission} from "../../../types";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectSelectedRegion} from "../../../store/regionSlice";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import {setToast} from "../../../store/toastSlice";
import writeXlsxFile, {Columns, SheetData} from "write-excel-file";
import DatePicker from "../../../components/Form/DatePicker";
import Button from "../../../components/Buttons/Button";
import {Download} from "@mui/icons-material";
import SelectWithId from "../../../components/Form/SelectWithId";
import * as Yup from "yup";
import {dateValidToRequired, validationSchema} from "../../../utils/formValidation";
import {formatWorkGroupReportData} from "./formatters/workGroupReportFormatter";
import {formatWorkGroupSimplifiedReportData} from "./formatters/workGroupSimplifiedReportFormatter";
import {formatOverUnderTimeReportData} from "./formatters/overUnderPayReportFormatter";
import PageHeaderRegionSelect from "../../../components/PageHeader/RegionSelect";
import {formatBusStopUsagesReport} from "./formatters/busStopUsageReportFormatter";


const reportValidationSchema = Yup.object().shape({
    reportType: validationSchema('Tüüp').fields.textFieldRequired,
    startDate: validationSchema('Alguskuupäev').fields.dateRequired,
    endDate: dateValidToRequired('Lõpukuupäev', 'startDate')
});

export interface ReportDownloadFormData {
    reportType: number;
    startDate: Date | null;
    endDate: Date | null;
}

interface ReportSelectOption extends SelectOptionWithIdAndPermission {
    fieldConfig: FieldConfig;
}

interface FieldConfig {
    region: boolean;
    startDate: boolean;
    endDate: boolean;
    yearMonth: boolean;
}

const defaultFieldConfig: FieldConfig = {
    region: true,
    startDate: true,
    endDate: true,
    yearMonth: false,
}

const reportTypes: ReportSelectOption[] = [
    {id: 0, name: '-', fieldConfig: defaultFieldConfig},
    {id: 1, name: 'Reisiaruanne', fieldConfig: defaultFieldConfig},
    {id: 2, name: 'Liiniaruanne', fieldConfig: defaultFieldConfig},
    {id: 3, name: 'Tööaruanne', fieldConfig: defaultFieldConfig},
    {id: 4, name: 'Töögruppide aruanne', fieldConfig: defaultFieldConfig},
    {id: 5, name: 'Töögruppide aruanne (print)', fieldConfig: defaultFieldConfig},
    {id: 6, name: 'Ületundide aruanne', fieldConfig: {region: false, startDate: false, endDate: false, yearMonth: true}, requireAllRegions: true},
    {id: 7, name: 'Peatuste kasutamise aruanne', fieldConfig: {...defaultFieldConfig, region: false}, requireAllRegions: true},
];

const FormContent = ({reportTypeId}: {reportTypeId: number}) => {
    const reportType = reportTypes.find(reportType => reportType.id === reportTypeId);

    return (
        <>
            {reportType?.fieldConfig.region && <PageHeaderRegionSelect styles={{width: '100%'}} />}
            {reportType?.fieldConfig.yearMonth && <DatePicker name="startDate" label="Kuu" hideDay hasCopiedContent />}
            {reportType?.fieldConfig.startDate && <DatePicker name="startDate" label="Alguskuupäev" disabled={reportTypeId === 0} hasCopiedContent />}
            {reportType?.fieldConfig.endDate && <DatePicker name="endDate" label="Lõpukuupäev" disabled={reportTypeId === 0} hasCopiedContent />}
        </>
    )

}

const Reports = () => {
    const today = dayjs();
    const startOfPrevMonth = today.subtract(1, 'month').startOf('month');
    const endOfPrevMonth = startOfPrevMonth.endOf('month');
    const startOfPrevWeek = today.subtract(1, 'week').startOf('week');
    const endOfPrevWeek = startOfPrevWeek.endOf('week');

    const selectedRegion = useAppSelector(selectSelectedRegion);
    const dispatch = useAppDispatch();

    const initialValues: ReportDownloadFormData = {
        reportType: 0,
        startDate: null,
        endDate: null,
    };

    const handleDownloadFile = (data: ReportDownloadFormData, helpers: FormikHelpers<ReportDownloadFormData>) => {
        switch (data.reportType) {
            case 1:
                composeAndDownloadFileWithDateRange<TripReport[]>(
                    data,
                    helpers,
                    reportTypes[1].name,
                    'reisiaruanne_%region%_%startDate%-%endDate%.xlsx',
                    getLineReport,
                    formatDailyLineReport
                );
                break;
            case 2:
                composeAndDownloadFileWithDateRange<TripReport[]>(
                    data,
                    helpers,
                    reportTypes[2].name,
                    'liiniaruanne_%region%_%startDate%-%endDate%.xlsx',
                    getLineReport,
                    formatMonthlyLineReportData
                );
                break;
            case 3:
                composeAndDownloadFileWithDateRange<BusReport[]>(
                    data,
                    helpers,
                    reportTypes[3].name,
                    'tooaruanne_%region%_%startDate%-%endDate%.xlsx',
                    getBusReport,
                    formatBusReportData
                );
                break;
            case 4:
                composeAndDownloadFileWithDateRange<WorkGroup[]>(
                    data,
                    helpers,
                    reportTypes[4].name,
                    'bussijuhi_toogruppide_aruanne_%region%_%startDate%-%endDate%.xlsx',
                    getDriverWorkGroupsReport,
                    formatWorkGroupReportData
                );
                break;
            case 5:
                composeAndDownloadFileWithDateRange<WorkGroup[]>(
                    data,
                    helpers,
                    reportTypes[5].name,
                    'bussijuhi_toogruppide_print_aruanne_%region%_%startDate%-%endDate%.xlsx',
                    getDriverWorkGroupsReport,
                    formatWorkGroupSimplifiedReportData
                );
                break;
            case 6:
                composeAndDownloadFileWithYearMonth<OverUnderTimeReport[]>(
                    data,
                    helpers,
                    reportTypes[6].name,
                    'yletundide_aruanne_%yearMonth%.xlsx',
                    getOverUnderTimeReport,
                    formatOverUnderTimeReportData
                );
                break;
            case 7:
                composeAndDownloadFileWithDateRange<BusStopUsagesReport>(
                    data,
                    helpers,
                    reportTypes[7].name,
                    'peatuste_kasutamise_aruanne_%startDate%-%endDate%.xlsx',
                    getBusStopUsagesReport,
                    formatBusStopUsagesReport
                );
        }
    };

    async function composeAndDownloadFileWithDateRange<T>(
        data: ReportDownloadFormData,
        helpers: FormikHelpers<ReportDownloadFormData>,
        name: string,
        fileNameFormat: string,
        fetchData: (regionId: number | string, startDate: Dayjs, endDate: Dayjs) => T | Promise<T>,
        composeReport: (data: T, startDate: Dayjs, endDate: Dayjs, region: Region) => [SheetData, Columns],
    ) {
        if (!selectedRegion) {
            dispatch(setToast({type: 'error', text: `${name} allalaadimisel tekkis viga`}));
            return;
        }
        const start = dayjs(data.startDate);
        const end = dayjs(data.endDate);

        try {
            const fileName = fileNameFormat
                .replace('%region%', selectedRegion.name)
                .replace('%startDate%', start.format('DD.MM.YYYY'))
                .replace('%endDate%', end.format('DD.MM.YYYY'));
            const data = await fetchData(selectedRegion.id, start, end);
            const [formattedData, columns] = composeReport(data, start, end, selectedRegion);

            await writeXlsxFile(
                formattedData,
                {
                    fileName: fileName,
                    columns: columns,
                    sheet: name,
                }
            );
        } catch (e) {
            console.error(e);
            dispatch(setToast({type: 'error', text: `${name} allalaadimisel tekkis viga`}));
        }
        helpers.setSubmitting(false);
    }

    async function composeAndDownloadFileWithYearMonth<T>(
        data: ReportDownloadFormData,
        helpers: FormikHelpers<ReportDownloadFormData>,
        name: string,
        fileNameFormat: string,
        fetchData: (yearMonth: string) => T | Promise<T>,
        composeReport: (data: T, startDate: Dayjs, endDate: Dayjs) => [SheetData, Columns],
    ) {
        const start = dayjs(data.startDate).startOf('month');
        const end = dayjs(data.startDate).endOf('month');
        const yearMonth = start.format('YYYY-MM');

        try {
            const fileName = fileNameFormat.replace('%yearMonth%', yearMonth)
            const data = await fetchData(yearMonth);
            const [formattedData, columns] = composeReport(data, start, end);

            await writeXlsxFile(
                formattedData,
                {
                    fileName: fileName,
                    columns: columns,
                    sheet: name,
                }
            );
        } catch (e) {
            console.error(e);
            dispatch(setToast({type: 'error', text: `${name} allalaadimisel tekkis viga`}));
        }
        helpers.setSubmitting(false);
    }

    return (
        <Box>
            <PageHeader title="Aruanded" />
            <Formik initialValues={initialValues} onSubmit={handleDownloadFile} validationSchema={reportValidationSchema}>
                {(formikProps: FormikProps<ReportDownloadFormData>) => {
                    useEffect(() => {
                        if (formikProps.values.startDate === null && formikProps.values.endDate === null && formikProps.values.reportType !== 0) {
                            switch (formikProps.values.reportType) {
                                case 1:
                                    formikProps.setFieldValue('startDate', startOfPrevWeek.toDate());
                                    formikProps.setFieldValue('endDate', endOfPrevWeek.toDate());
                                    break;
                                case 2:
                                case 3:
                                case 6:
                                    formikProps.setFieldValue('startDate', startOfPrevMonth.toDate());
                                    formikProps.setFieldValue('endDate', endOfPrevMonth.toDate());
                                    break;
                            }
                        } else if (formikProps.values.startDate !== null && formikProps.values.endDate !== null && formikProps.values.reportType === 0) {
                            formikProps.resetForm();
                        }
                    }, [formikProps.values.reportType]);

                    return <Form>
                        <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: {xs: '100%', sm: '400px'}}}>
                            <SelectWithId name="reportType" label="Aruanne" options={reportTypes} />
                            <FormContent reportTypeId={formikProps.values.reportType} />
                            <Box pt={1}>
                                <Button
                                    text="Lae alla"
                                    type="submit"
                                    startIcon={<Download/>}
                                    disabled={formikProps.isSubmitting || formikProps.values.reportType === 0}
                                />
                            </Box>
                        </Box>
                    </Form>
                }}
            </Formik>
        </Box>
    );
};

export default Reports;
