import {Driver} from "../../../../../../API/driver/types";
import {formatName, sortNames} from "../../../../workSchedule/utils";
import React, {useState} from "react";
import {Alert, Box, Link, Typography} from "@mui/material";


interface DriversWithoutPayslipsInfoAlertProps {
    drivers: Driver[];
}

const DriversWithoutPayslipsInfoAlert = ({drivers}: DriversWithoutPayslipsInfoAlertProps) => {
    if (!drivers.length) return null;
    const [namesDisplayType, setNamesDisplayType] = useState<'short' | 'long'>('short');

    const names = sortNames(drivers).map(driver => formatName(driver, 'firstNameWithInitials'));
    const totalNames = names.length;
    const shortDriverNamesList = totalNames > 5 ? names.slice(0, 5) : names;

    const handleSuffixChange = () => {
        if (namesDisplayType === 'short') {
            setNamesDisplayType('long');
        } else {
            setNamesDisplayType('short');
        }
    };

    return (
        <Alert
            severity="info"
            variant="outlined"
            onClick={handleSuffixChange}
            sx={{width: 'fit-content', maxWidth: '100%', mb: 1.5, cursor: totalNames > 5 ? 'pointer' : ''}}
        >
            <Box sx={{display: 'flex', flexDirection: 'row-reverse'}}>
                <Typography variant="body2">
                    {`Palgaleht on genereerimata ${drivers.length === 1 ? 'juhil' : 'juhtidel'} `}
                    {namesDisplayType === 'short' && shortDriverNamesList.join(', ') + ' '}
                    {namesDisplayType === 'short' && totalNames > 5 && <Link>ja veel {totalNames - 5} juhil</Link>}
                    {namesDisplayType === 'long' && totalNames > 5 && names.join(', ')}
                </Typography>
            </Box>
        </Alert>
    )
}

export default DriversWithoutPayslipsInfoAlert;
