import React, {useEffect} from 'react';
import {Box, CircularProgress, Typography, useMediaQuery} from '@mui/material';
import {CharterTrip, CharterTripStatus} from "../../../../API/types";
import {GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import {filterByValue} from "../../../../utils/utils";
import theme from "../../../../theme";
import {Permission} from "../../../../types";
import Visible from '../../../../components/Visible';
import DataGrid from "../../../../components/DataGrid";
import {useNavigate, useOutletContext} from 'react-router-dom';
import routes from "../../../../routes";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import NavLink from "../../../../components/NavLink";
import NavIcon from "../../../../components/Icons/NavIcon";
import {SearchContextType} from "../../../../layouts/SearchLayoutWrapper";
import ListView from "../../../../layouts/ListViewWrapper";
import {
    deleteCharterTrip,
    fetchCharterTrips,
    selectCharterTripsWithSearchFields
} from "../../../../store/charterTripsSlice";
import {selectAllRegions} from "../../../../store/regionSlice";
import ActionIcon from "../../../../components/Icons/ActionIcon";
import StaticIcon from "../../../../components/Icons/StaticIcon";


const StatusWithProgress = ({status, displayStatus}: {status: CharterTripStatus, displayStatus: string}) => {
    const getStatusWithProgress = (size: number, isCancelled = false) => {
        return (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box sx={{position: 'relative', height: '18px', mr: 0.5}}>

                    <CircularProgress
                        variant="determinate"
                        size={18}
                        thickness={8}
                        value={size}
                        sx={{position: 'absolute', color: isCancelled ? 'error.light' : 'primary.light'}}
                    />
                    <CircularProgress
                        variant="determinate"
                        size={18}
                        thickness={8}
                        value={100}
                        sx={{color: 'divider'}}
                    />
                </Box>
                <Typography variant="body2">{displayStatus}</Typography>
            </Box>
        );
    };

    switch (status) {
        case CharterTripStatus.CONFIRMED:
            return getStatusWithProgress(33);
        case CharterTripStatus.PLANNED:
            return getStatusWithProgress(66);
        case CharterTripStatus.DONE:
            return getStatusWithProgress(100);
        case CharterTripStatus.CANCELLED:
            return getStatusWithProgress(33, true);
    }
};

export default function CharterTrips() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {searchInput, setSearchInput} = useOutletContext<SearchContextType>();
    const regions = useAppSelector(selectAllRegions);
    const charterTrips = useAppSelector(selectCharterTripsWithSearchFields);
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('xl'));

    useEffect(() => {
        dispatch(fetchCharterTrips());
    }, []);

    const handleAddTripDefinition = () => navigate(routes.AUTHENTICATED.CHARTER_TRIPS.ADD);

    const getFilteredRows = (charterTrips: CharterTrip[]) => filterByValue(charterTrips, searchInput);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Sõidu nr',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 90,
            renderCell: (params) =>
                <NavLink id={params.row.id} value={params.row.id} route={routes.AUTHENTICATED.CHARTER_TRIPS.EDIT} navPermission={Permission.UpdateTripDefinitions} />
        },
        {
            field: 'orderNumber',
            headerName: 'Tellimuse nr',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 90 : 100,
        },
        {
            field: 'displayStatus',
            headerName: 'Staatus',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 110 : 120,
            renderCell: (params) => <StatusWithProgress status={params.row.status} displayStatus={params.value} />
        },
        {
            field: 'isInvoiced',
            headerName: 'Arve',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 50 : 60,
            renderCell: params => params.value ? <StaticIcon type="CHECK" tooltipTitle="Arve väljastatud" /> : ''
        },
        {
            field: 'client',
            headerName: 'Klient',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 100 : 120,
        },
        {
            field: 'departurePoint',
            headerName: 'Lähtekoht',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 100 : 120,
        },
        {
            field: 'destination',
            headerName: 'Sihtkoht',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 100 : 120,
        },
        {
            field: 'displayStartDateTime',
            headerName: 'Algusaeg',
            sortable: true,
            filterable: false,
            width: 120,
        },
        {
            field: 'displayEndDateTime',
            headerName: 'Lõpuaeg',
            sortable: true,
            filterable: false,
            width: 120,
        },
        {
            field: 'distance',
            headerName: 'Läbisõit',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 100,
        },
        {
            field: 'numberOfPassengers',
            headerName: 'Reisijaid',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 100,
        },
        {
            field: 'regionId',
            headerName: 'Piirkond',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 100 : 120,
            valueGetter: (params: GridValueGetterParams) => regions.find(region => region.id === params.value)?.name ?? '',
        },
        {
            field: 'comment',
            headerName: 'Lisainfo',
            sortable: true,
            filterable: false,
            flex: 1,
            minWidth: isScreenSmall ? 100 : 200,
        },
        {
            field: 'otherSearchFields',
            headerName: '',
            sortable: false,
            filterable: false,
            align: 'right',
            minWidth: 100,
            renderCell: (params) => {
                const isDeleteDisabled = params.row.status === CharterTripStatus.PLANNED || params.row.status === CharterTripStatus.DONE;

                return (
                    <Visible permission={Permission.UpdateTripDefinitions}>
                        <Box>
                            <NavIcon type="COPY" id={params.row.id} route={routes.AUTHENTICATED.CHARTER_TRIPS.ADD} copyState={{copiedCharterTrip: params.row}} />
                            <NavIcon type="EDIT" id={params.row.id} route={routes.AUTHENTICATED.CHARTER_TRIPS.EDIT} />
                            <ActionIcon
                                type="DELETE"
                                id={params.row.id}
                                tooltipTitle={isDeleteDisabled ? 'Planeeritud sõitu ei saa kustutada' : 'Kustuta'}
                                disabled={isDeleteDisabled}
                                handleClick={() => {
                                    if (confirm(
                                        (params.row.isInvoiced ? 'Sõidu arve on juba väljastatud. ' : '')
                                        + `Kas oled kindel, et soovid sõidu #${params.row.id} kustutada?`
                                    )) {
                                        dispatch(deleteCharterTrip({id: params.row.id}));
                                    }
                                }}
                            />
                        </Box>
                    </Visible>
                );
            }
        }
    ];

    return (
        <ListView
            headerProps={{
                title: 'Tellimusveod',
                searchProps: {input: searchInput, setInput: setSearchInput},
                buttonProps: {
                    title: 'Lisa sõit',
                    onClick: handleAddTripDefinition,
                    permission: Permission.UpdateTripDefinitions
                },
            }}
            isLoading={!charterTrips}
        >
            <DataGrid rows={getFilteredRows(charterTrips ?? [])} columns={columns} />
        </ListView>
    );
}
