import {WorkSheetDetails as ApiWorkSheetDetails, WorkSheetWorkItem} from "../../../../API/workSheets/types";
import {ResourceType, WorkGroupItemType} from "../../../../API/workGroup/types";
import {useAppSelector} from "../../../../hooks";
import {selectBusOrDriverIdByResourceId, selectResourceNameByType} from "../store/selectors";
import React, {useMemo} from "react";
import {GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {Alert, Box, IconButton, Link, Tooltip, Typography} from "@mui/material";
import {CallSplit, Comment, Delete, Edit, WarningAmberRounded} from "@mui/icons-material";
import DataGrid from "../../../../components/DataGrid";
import {getWorkGroupItemTypeTranslation} from "../../../../utils/enumTranslations";
import {MAX_DRIVER_WORK_GROUP_DURATION_HOURS} from "../../../../constants";
import {Link as RouterLink} from "react-router-dom";
import routes from "../../../../routes";
import {decimalToFixedDisplayStr, getOppositeResourceType, getStartAndEndPointLabel} from "../../../../utils/utils";
import {WorkScheduleItemStatus} from "../../../../API/workSchedule/types";
import {BusUsageWithDistance} from "../../dashboard/DriverDashboard/types";
import {OdometerReadingWithBusId} from "../../../../API/types";
import {selectAllBuses} from "../../../../store/busSlice";
import {getBusUsages, getReadingRows} from "./utils";
import {formatTimeAtDate} from "../../../../utils/dateUtils";
import OdometerReadings from "./OdometerReadings";
import {AddReadingDialogData} from "./AddReadingDialog";


export interface OdometerReadingRow extends OdometerReadingWithBusId {
    type: 'Algnäit' | 'Lõppnäit' | 'Näit' | 'Tankimine';
    licencePlateNumber?: string;
    expectedTotalDistance?: number;
    distanceDiff?: number;
}

const getWorkItemLabel = (workItem: WorkSheetWorkItem) => {
    if (!workItem.type) return '';
    return workItem.type === WorkGroupItemType.TRIP_DEFINITION
        ? getWorkGroupItemTypeTranslation(workItem.type) + (workItem.route ? ` (${getStartAndEndPointLabel(workItem.route)})` : '')
        : getWorkGroupItemTypeTranslation(workItem.type);
};

const SecondaryResourceName = ({workItem, secondaryWorkSheets, secondaryResourceType}: {
    workItem: WorkSheetWorkItem,
    secondaryWorkSheets: ApiWorkSheetDetails[],
    secondaryResourceType: ResourceType,
}) => {
    const secondaryWorkSheetId = secondaryResourceType === ResourceType.DRIVER
        ? workItem.driverWorkSheetId
        : workItem.busWorkSheetId;
    const secondaryResourceId = secondaryWorkSheets
        .find(sws => sws.id === secondaryWorkSheetId)?.resourceId ?? 0;
    const resourceName = useAppSelector(
        state => selectResourceNameByType(state, secondaryResourceId, secondaryResourceType)
    );

    if (secondaryWorkSheetId) {
        return <Link
            component={RouterLink}
            to={routes.AUTHENTICATED.WORK_SHEETS.DETAILS.replace(':id', secondaryWorkSheetId.toString())}>
            {resourceName ? resourceName : ''}
        </Link>;
    }

    return <>{resourceName ? resourceName : ''}</>;
};

export const WorkSheetDetails = ({workSheet, secondaryWorkSheets, handleDelete, handleEdit, handleSplit, handleEditOdometerReading, handleAddOdometerReading, handleDeleteOdometerReading}: {
    workSheet: ApiWorkSheetDetails,
    secondaryWorkSheets: ApiWorkSheetDetails[],
    handleDelete: (workItem: WorkSheetWorkItem) => void,
    handleEdit: (workItem: WorkSheetWorkItem) => void,
    handleSplit: (workItem: WorkSheetWorkItem) => void,
    handleEditOdometerReading: (reading: OdometerReadingWithBusId) => void;
    handleAddOdometerReading: (dialogData: AddReadingDialogData) => void;
    handleDeleteOdometerReading: (reading: OdometerReadingWithBusId) => void;
}) => {
    const resourceName = useAppSelector(state => selectResourceNameByType(state, workSheet.resourceId, workSheet.resourceType));
    const buses = useAppSelector(selectAllBuses);
    const busOrDriverId = useAppSelector(state => selectBusOrDriverIdByResourceId(state, workSheet.resourceId, workSheet.resourceType));
    const sortedWorkItems = useMemo(() => {
        return [...workSheet.workItems]
            .sort((a, b) => a.startDateTime.localeCompare(b.startDateTime))
            .map((item, index, array) => ({
                ...item,
                warning: array[index-1]?.endDateTime > item.startDateTime,
            }));
    }, [workSheet]);

    const [odometerReadingRows, busUsages] = useMemo(() => {
        if (workSheet.resourceType === ResourceType.VEHICLE) return [[], []];
        const busUsages: BusUsageWithDistance[] = getBusUsages(sortedWorkItems, secondaryWorkSheets, workSheet.id);
        return [getReadingRows(workSheet, busUsages, buses, busOrDriverId), busUsages];
    }, [sortedWorkItems]);

    const dayLengthWarning = useMemo(() => {
        if (workSheet.resourceType === ResourceType.VEHICLE) {
            return false;
        }

        const {dayStart, dayEnd} = workSheet.workItems.reduce(
            ({dayStart, dayEnd}: {dayStart: string | null, dayEnd: string | null}, item) => {
                return {
                    dayStart: dayStart && dayStart < item.startDateTime ? dayStart : item.startDateTime,
                    dayEnd: dayEnd && dayEnd > item.endDateTime ? dayEnd : item.endDateTime,
                }
            },
            {dayStart: null, dayEnd: null}
        );
        const total = dayStart && dayEnd ? dayjs(dayEnd).diff(dayStart, 'hours', true) : 0;

        return total > MAX_DRIVER_WORK_GROUP_DURATION_HOURS;
    }, [workSheet]);

    const distanceDifferenceWarning = useMemo(() => {
        if (workSheet.resourceType === ResourceType.VEHICLE) return false;

        for (const row of odometerReadingRows) {
            if (row.distanceDiff && Math.abs(row.distanceDiff) > 1) return true;
        }

        return false;
    }, [odometerReadingRows]);

    const handleAddOdometerReadingClick = () => {
        if (busOrDriverId && sortedWorkItems.length > 0) {
            handleAddOdometerReading({
                workSheet: workSheet,
                busUsages: busUsages,
                driverId: busOrDriverId,
            });
        }
    };

    const workSheetColumns: GridColDef[] = [
        {
            field: 'type',
            headerName: 'Tegevus',
            sortable: false,
            disableColumnMenu: true,
            width: 200,
            valueGetter: (params: GridValueGetterParams) => getWorkItemLabel(params.row),
        },
        {
            field: 'tripDefinitionCode',
            headerName: 'Reisi kood',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
        },
        {
            field: 'tripDefinitionLineNumber',
            headerName: 'Liini nr',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
        },
        {
            field: 'startDateTime',
            headerName: 'Algus',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
            valueFormatter: (params: GridValueFormatterParams) => formatTimeAtDate(params.value, workSheet.startDate),
            renderCell: (params: GridRenderCellParams) => <>
                {params.formattedValue}
                {params.row.warning &&
                    <Tooltip title="Tegevus algab enne eelmise tegevuse lõppu" arrow placement="top">
                        <WarningAmberRounded sx={{px: 0.2, fontSize: '18px'}} color="warning" />
                    </Tooltip>
                }
            </>,
        },
        {
            field: 'endDateTime',
            headerName: 'Lõpp',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
            valueFormatter: (params: GridValueFormatterParams) => formatTimeAtDate(params.value, workSheet.startDate),
        },
        {
            field: 'distance',
            headerName: 'Läbisõit',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
            valueFormatter: (params: GridValueFormatterParams) =>
                params.value ? decimalToFixedDisplayStr(params.value) + ' km' : '',
        },
        {
            field: 'otherResource',
            headerName: workSheet.resourceType === ResourceType.DRIVER ? 'Buss' : 'Juht',
            sortable: false,
            disableColumnMenu: true,
            width: 200,
            renderCell: (params: GridRenderCellParams) => <SecondaryResourceName
                workItem={params.row}
                secondaryWorkSheets={secondaryWorkSheets}
                secondaryResourceType={getOppositeResourceType(workSheet.resourceType)}
            />,
        },
        {
            field: 'comment',
            headerName: 'Kommentaar',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => params.value,
        },
        {
            field: 'menu',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            align: 'right',
            minWidth: 100,
            renderCell: (params: GridRenderCellParams) => workSheet.status === WorkScheduleItemStatus.CONFIRMED ? <></> :
                <Box>
                    {params.row.type === WorkGroupItemType.TRIP_DEFINITION &&
                        <Tooltip title="Halda reisi osadena" arrow placement="top">
                            <IconButton size="small" onClick={() => handleSplit(params.row)}>
                                <CallSplit sx={{height: '20px', width: '20px', rotate: '180deg'}}/>
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title="Muuda" arrow placement="top">
                        <IconButton size="small" onClick={() => handleEdit(params.row)}>
                            <Edit sx={{height: '20px', width: '20px'}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Kustuta" arrow placement="top">
                        <IconButton size="small" onClick={() => handleDelete(params.row)}>
                            <Delete sx={{height: '20px', width: '20px'}}/>
                        </IconButton>
                    </Tooltip>
                </Box>
        },
    ];

    const showOdometerReadings = workSheet.resourceType === ResourceType.DRIVER && secondaryWorkSheets.length > 0;

    return (
        <>
            <Typography variant="body2" color="text.secondary" mt={-0.5} pb={1.5}>
                {resourceName === '' ? 'Määramata' : resourceName} - {dayjs(workSheet?.startDate).format('DD. MMMM')}
            </Typography>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}}}>
                {dayLengthWarning &&
                    <Box display="flex" sx={{minWidth: '200px'}}>
                        <Alert severity="warning" variant="outlined" sx={{mb: 1}}>
                            Tööpäeva pikkus ületab lubatud {MAX_DRIVER_WORK_GROUP_DURATION_HOURS} tunni piiri
                        </Alert>
                    </Box>
                }
                {distanceDifferenceWarning &&
                    <Box display="flex" sx={{minWidth: '200px'}}>
                        <Alert severity="warning" variant="outlined" sx={{mb: 1, ml: dayLengthWarning ? 1 : 0}}>
                            Juhi sisestatud lõppnäit erineb planeeritud läbisõidust
                        </Alert>
                    </Box>
                }
                {workSheet.driverComment && workSheet.driverComment.length > 0 &&
                    <Box display="flex">
                        <Alert severity="info" variant="outlined" icon={<Comment fontSize="small" />} sx={{mb: 1, ml: dayLengthWarning || distanceDifferenceWarning ? 1 : 0}}>
                            <span style={{fontWeight: 'bold'}}>Juhi kommentaar</span>: {workSheet.driverComment}
                        </Alert>
                    </Box>
                }
            </Box>
            <DataGrid rows={sortedWorkItems} columns={workSheetColumns} />
            {showOdometerReadings &&
                <OdometerReadings
                    workSheet={workSheet}
                    secondaryWorkSheets={secondaryWorkSheets}
                    odometerReadingRows={odometerReadingRows}
                    handleEditOdometerReading={handleEditOdometerReading}
                    handleAddOdometerReading={handleAddOdometerReadingClick}
                    handleDeleteOdometerReading={handleDeleteOdometerReading}
                />
            }
        </>
    );
};