import React, {useEffect, useState} from "react";
import {Region} from "../../../../../../API/types";
import {Formik, FormikHelpers, FormikProps} from "formik";
import {WorkItemForm} from "./types";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {getUnplannedWorkItems} from "../../../../../../API";
import {selectDate, selectToggledResourceType} from "../../../../../../store/viewSlice";
import {setToast} from "../../../../../../store/toastSlice";
import {
    selectAllTripDefinitionWorkItems,
    TripDefinitionWorkItem as WorkScheduleTripDefinitionWorkItem
} from "../../../../../../store/workScheduleItemSlice";
import * as Yup from "yup";
import {validationSchema} from "../../../../../../utils/formValidation";
import OtherRegionTripForm from "../Form";


const tripValidationSchema = Yup.object().shape({
    workItem: validationSchema('Reis').fields.objectRequired,
});

export interface OtherRegionWorkSheetWorkItemFormProps {
    selectedOtherRegion: Region | undefined;
    onSubmit: (form: WorkItemForm, formikHelpers: FormikHelpers<WorkItemForm>) => void;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OtherRegionWorkSheetWorkItemForm({
    selectedOtherRegion, onSubmit, isLoading, setIsLoading
}: OtherRegionWorkSheetWorkItemFormProps) {
    const dispatch = useAppDispatch();
    const selectedDate = useAppSelector(selectDate);
    const currentTripDefinitionWorkItems = useAppSelector(selectAllTripDefinitionWorkItems);
    const resourceType = useAppSelector(selectToggledResourceType);

    const [workItems, setWorkItems] = useState<WorkScheduleTripDefinitionWorkItem[] | undefined>(undefined);
    const initialValues: WorkItemForm = {workItem: null};

    useEffect(() => {
        if (selectedOtherRegion && selectedDate) {
            getUnplannedWorkItems(selectedOtherRegion.id, selectedDate, resourceType)
                .then(result => {
                    const currentTripDefinitionWorkItemIds = currentTripDefinitionWorkItems.map(trip => trip.id);
                    const filteredOtherRegionWorkItems = result
                        .filter(otherRegionTrip => otherRegionTrip.tripSegmentId && !(currentTripDefinitionWorkItemIds.includes(otherRegionTrip.tripSegmentId)))
                        .sort((a,b) => a.trip && b.trip && a.trip.code > b.trip.code ? 1 : -1)
                        .map(workItem => ({
                            id: workItem.tripSegmentId as number,
                            tripId: workItem.trip?.id as number,
                            workItemId: workItem.id,
                            startDateTime: workItem.startDateTime,
                            endDateTime: workItem.endDateTime,
                            distance: workItem.distance ?? 0,
                            comment: workItem.comment,
                            code: workItem.trip?.code,
                            regionId: selectedOtherRegion.id,
                            route: workItem.route,
                            workSheetsOfOppositeType: [],
                        }));
                    setWorkItems(filteredOtherRegionWorkItems);
                })
                .catch(() => {
                    setWorkItems([]);
                    dispatch(setToast({type: 'error', text: 'Reiside pärimisel ilmnes viga'}));
                });
        }
    }, [selectedOtherRegion]);

    useEffect(() => {
        if (workItems) setIsLoading(false);
    }, [workItems]);

    return (
        <Formik initialValues={initialValues} validationSchema={tripValidationSchema} onSubmit={onSubmit}>
            {(formikProps: FormikProps<WorkItemForm>) => {
                useEffect(() => {
                    formikProps.setFieldValue('workItem', null).then(() => {
                        setIsLoading(false);
                    });
                }, [workItems]);

                return <OtherRegionTripForm options={workItems} isLoading={isLoading} name="workItem" />
            }}
        </Formik>
    );
}
