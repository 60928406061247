import React from 'react';
import {useAppSelector} from "../../../../../../hooks";
import {EntityId} from "@reduxjs/toolkit";
import {shallowEqual} from "react-redux";
import PlannerItemGroupCard from "../../PlannerItemGroup/Card";
import {
    selectResourceNameByType,
    selectWorkScheduleItemWorkGroupCode,
} from "../../../../../../scenes/authenticated/workSheets/store/selectors";
import {selectAllItemTimesInPlannerItemGroupMemoized} from "../../../../store/selectors";
import {ResourceType, WorkScheduleItemStatus} from "../../../../../../API/types";
import {isDriverWorkDurationOverLimit} from "../../../../utils/utils";
import {getWorkScheduleItemId, selectWorkScheduleItemById} from "../../../../../../store/workScheduleItemSlice";
import {selectRegion} from "../../../../../../store/regionSlice";
import {RootState} from "../../../../../../store";


interface WorkSheetWrapperProps {
    groupId: EntityId;
    index: number;
}

function selectOtherRegion(state: RootState, groupId: number | string) {
    const workSheet = selectWorkScheduleItemById(state, groupId)
    const selectedRegionId = state.region.selected;
    if (workSheet?.regionId && workSheet?.regionId !== selectedRegionId) {
        return selectRegion(state, workSheet?.regionId);
    } else {
        return undefined;
    }
}

const WorkSheetCardWrapper = ({groupId, index}: WorkSheetWrapperProps) => {
    const workSheet = useAppSelector(state => selectWorkScheduleItemById(state, groupId), shallowEqual);
    if (!workSheet) return <></>;

    const items = workSheet.resourceType === ResourceType.DRIVER
        ? useAppSelector(state => selectAllItemTimesInPlannerItemGroupMemoized(state, groupId))
        : undefined;
    const isDurationOverLimit = items && items.length > 0
        ? isDriverWorkDurationOverLimit(workSheet.resourceType, items[0].startHh, items[items.length - 1].endHh, items)
        : undefined;

    const resourceName = workSheet.resourceId !== undefined
        ? useAppSelector(state => selectResourceNameByType(state, workSheet.resourceId as number, workSheet.resourceType))
        : undefined;

    const workGroupCode = useAppSelector(state => selectWorkScheduleItemWorkGroupCode(state, groupId), shallowEqual) ?? 'TG';
    const isRowDisabled = workSheet.status !== WorkScheduleItemStatus.GENERATED;
    const workSheetId = isRowDisabled ? undefined : getWorkScheduleItemId(groupId);
    const otherRegion = useAppSelector(state => selectOtherRegion(state, groupId));


    return (
        <PlannerItemGroupCard
            groupId={groupId}
            index={index}
            workGroupCode={workGroupCode}
            workGroupId={workSheet.workGroupId}
            workSheetId={workSheetId}
            resourceName={resourceName}
            isDurationOverLimit={isDurationOverLimit}
            otherRegion={otherRegion}
            isRowDisabled={isRowDisabled}
        />
    )
};

export default WorkSheetCardWrapper;
