import React from 'react';
import {useField} from "formik";
import {Box, MenuItem, SxProps, TextField as MuiTextField, Typography} from '@mui/material';
import {RoutePoint} from "../../../../../../API/types";
import {getInputHelperText, renderOption} from "../../../../../Form/SelectGeoPoint";
import {getDisplayTimeWithModifier} from "../../../../../../utils/dateUtils";


const getSelectedOption = (options: RoutePoint[], id: string) => options.find(option => option.id.toString() === id);

interface SelectProps {
    name: string;
    label: string;
    options: RoutePoint[];
    sx?: SxProps;
}

const RoutePointSelect = ({name, label, options, sx}: SelectProps) => {
    const [field, meta, helpers] = useField(name);

    return (
        <MuiTextField
            value={field.value?.id.toString() ?? ''}
            onChange={(event) => {
                const selectedOption = getSelectedOption(options, event.target.value);
                helpers.setValue(selectedOption ?? null);
            }}
            select
            size="small"
            label={label}
            error={meta.touched && !!meta.error}
            helperText={(() => {
                if (meta.touched && meta.error) {
                    return meta.error;
                } else {
                    return field.value ? getInputHelperText(field.value) : '';
                }
            })()}
            SelectProps={{
                renderValue: (value) => {
                    const selectedOption = value ? getSelectedOption(options, value as string) : undefined;
                    return selectedOption ? (
                        <Box sx={{display: 'flex'}}>
                            {selectedOption.name ?? ''}
                            {selectedOption.time ? (
                                <Typography color="text.secondary" pl={0.5}>
                                    {getDisplayTimeWithModifier(selectedOption.time, !!selectedOption.timeIsOnNextDay)}
                                </Typography>
                            ) : ''}
                        </Box>
                    ) : '';
                },
            }}
            sx={{my: 1.5, width: '100%', ...sx}}
        >
            {options.map(option =>
                <MenuItem key={option.id} value={option.id.toString()}>
                    {renderOption(option, option.time, option.timeIsOnNextDay)}
                </MenuItem>
            )}
        </MuiTextField>
    );
};

export default RoutePointSelect;
