import {Form, FormikProps} from "formik";
import {Box, Typography} from "@mui/material";
import TextField from "../../../../../components/Form/TextField";
import React, {useEffect, useMemo} from "react";
import {CharterTripForm} from "../types";
import DetailViewButtons from "../../../../../components/Buttons/DetailViewButtons";
import NumberTextField from "../../../../../components/Form/NumberTextField";
import routes from "../../../../../routes";
import {useAppSelector} from "../../../../../hooks";
import {selectAllRegions} from "../../../../../store/regionSlice";
import {useAuth} from "../../../../../contexts/AuthContext";
import {getRegionChoicesForUser} from "../../../../../utils/utils";
import SelectWithId from "../../../../../components/Form/SelectWithId";
import {Contract} from "../../../../../API/driver/types";
import {getCharterTripStatusTranslationFromStr} from "../../../../../utils/enumTranslations";
import Select from "../../../../../components/Form/Select";
import {CharterTripStatus} from "../../../../../API/types";
import SelectFreeInput from "../../../../../components/Form/SelectFreeInput";
import {selectAllCharterOrders, selectTripsCountWithSelectedOrderId} from "../../../../../store/charterTripsSlice";
import {Permission} from "../../../../../types";
import ModifierStartAndEndTime from "../../../../../components/Form/ModifierStartAndEndTime";
import Switch from "../../../../../components/Form/Switch";
import DatePicker from "../../../../../components/Form/DatePicker";


const getStatusOptions = (currentStatus: CharterTripStatus) => {
    const options = [CharterTripStatus.CONFIRMED, CharterTripStatus.CANCELLED];
    if (currentStatus === CharterTripStatus.PLANNED || currentStatus === CharterTripStatus.DONE) options.push(currentStatus);

    return options;
};

interface CharterTripEditFormProps {
    id: string | undefined;
    formikProps: FormikProps<CharterTripForm>;
    defaultFormValues: CharterTripForm;
    hasCopiedContent?: boolean;
}

export default function CharterTripEditForm({id, formikProps, defaultFormValues, hasCopiedContent}: CharterTripEditFormProps) {
    const { authenticatedUser } = useAuth();
    const regions = useAppSelector(selectAllRegions);
    const [regionChoices] = getRegionChoicesForUser(regions, authenticatedUser);
    const charterOrderOptions = useAppSelector(selectAllCharterOrders);
    const charterOrderTripsCount = useAppSelector(state => selectTripsCountWithSelectedOrderId(state, formikProps.values.orderNumber));
    const selectedCharterOrder = useMemo(() =>
        charterOrderOptions.find(order => order.orderNumber === formikProps.values.orderNumber)
        , [formikProps.values.orderNumber]
    );

    useEffect(() => {
        if (formikProps.isSubmitting) {
            formikProps.setSubmitting(false);
        }
    }, [formikProps.initialValues]);

    const renderDetailViewButtons = () => {
        return (
            <DetailViewButtons
                id={id}
                defaultFormValues={defaultFormValues}
                formikProps={formikProps}
                listViewPath={routes.AUTHENTICATED.CHARTER_TRIPS.ROOT}
                allowSaveWithoutChanges={hasCopiedContent}
                savePermission={Permission.UpdateCharterTrips}
            />
        );
    };

    return (
        <Form>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2}}>
                <Box>
                    <Typography variant="h5" pt={{xs: 1, sm: 0}}>
                        {id ?? 'Uus sõit'}
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <Box sx={{display: {xs: 'none', sm: 'flex'}}}>
                        {renderDetailViewButtons()}
                    </Box>
                </Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '100%', width: {xs: '100%', sm: '450px'}}}>
                <SelectFreeInput
                    name="orderNumber"
                    label="Tellimuse number*"
                    options={charterOrderOptions.map(charterOrderOption => charterOrderOption.orderNumber)}
                />
                <TextField name="client" label="Kliendi nimi*" />
                {charterOrderTripsCount > 1 && selectedCharterOrder && formikProps.values.client && selectedCharterOrder.client !== formikProps.values.client &&
                    <Typography variant="body2" fontSize="12px" color="warning.main" mt={-0.5} mb={0.5} ml={1.5}>
                        {`Kliendi nimi erineb varasemalt tellimusele #${formikProps.values.orderNumber} lisatud 
                        nimest "${selectedCharterOrder.client}". Salvestamisel uueneb see kõigil tellimuse sõitudel.`}
                    </Typography>
                }
                <Select name="status"
                        label="Staatus*"
                        options={getStatusOptions(formikProps.values.status)}
                        translationFunction={getCharterTripStatusTranslationFromStr}
                        translationEnumType={Contract}
                        disabled={formikProps.values.status === CharterTripStatus.PLANNED || formikProps.values.status === CharterTripStatus.DONE}
                />
                <TextField name="route" label="Marsruut*" />
                <TextField name="departurePoint" label="Lähtekoht*" />
                <TextField name="destination" label="Sihtkoht*" />
                <ModifierStartAndEndTime />
                <DatePicker name="date" label="Kuupäev*" />
                <NumberTextField name="distance" label="Pikkus* (km)" decimals={2} />
                <NumberTextField name="numberOfPassengers" label="Reisijate arv*" />
                <SelectWithId name="regionId" label="Planeerimispiirkond*" options={regionChoices} />
                <NumberTextField name="price" label="Hind* (ilma KM-ta)" decimals={2} />
                <Switch name="isInvoiced" label="Arve väljastatud" />
                <TextField name="comment" label="Lisainfo" multiline minRows={2} maxRows={5} />
                <Box sx={{display: {xs: 'flex', sm: 'none'}}}>
                    {renderDetailViewButtons()}
                </Box>
            </Box>
        </Form>
    );
}
