import {TransportContract} from "./types";
import axios from "axios";
import {handleError} from "./utils";

export {
    loadBuses,
    createBus,
    loadBus,
    saveOdometerReading,
    updateBus,
    updateOdometerReading,
    deleteOdometerReading
} from './bus/api';
export { loadDrivers } from './driver/api';
export { loadCalendarEntries, createCalendarEntry, deleteCalendarEntry } from './calendar/api';
export { loadGeoPoints } from './geoPoint/api';
export { loadRegions } from './region/api';
export {
    copyTripDefinition,
    loadTripDefinition,
    loadTripDefinitions,
    loadTripDefinitionsByDate,
    updateTripDefinition,
    createTripDefinition,
    splitTrip,
    mergeTrip,
    getTripWorkItemsOnDate,
    saveTripWorkItemsOnDate,
    saveTripWorkItemsWithRouteOnDate,
} from './tripDefinition/api';
export {
    loadCharterTrips, loadCharterTrip, createCharterTrip, updateCharterTrip, deleteCharterTrip
} from './charterTrip/api';
export {
    createWorkScheduleItem,
    deleteWorkScheduleItem,
    updateWorkScheduleItem,
    changeDriversOrder,
    confirmBusesWorkSchedule,
    confirmDriversWorkSchedule,
    loadMonthWorkScheduleForDrivers,
    loadMonthWorkScheduleForBuses,
} from './workSchedule/api';
export {
    addActivityToWorkGroup,
    updateWorkGroup,
    createWorkGroup,
    createWorkGroupActivity,
    moveTripDefinition,
    loadWorkGroupDetails,
    loadWorkGroups,
    loadWorkGroupsOnDate,
    removeActivityFromWorkGroup,
    updateWorkGroupActivity,
    copyOppositeWorkGroup,
} from './workGroup/api';
export {
    getWorkSheetsByRegionAndDate,
    getWorkSheetsByRegionAndDateWithPrevAndNextDay,
    createWorkSheet,
    updateWorkSheet,
    generateWorkSheet,
    deleteWorkItem,
    createWorkItem,
    updateWorkItem,
    updateWorkSheetComment,
    getWorkSheet,
    getUnplannedWorkItems,
    setTripSegmentResources,
    getWorkSheetsConnectedToBus,
} from './workSheets/api';
export {
    getBusStopUsagesReport,
    getLineReport,
    getBusReport,
    getDriverWorkGroupsReport,
    getOverUnderTimeReport
} from './reports/api';
export {loadDefects, getDefect, createDefect, updateDefect} from './defects/api';
export {getUsers, getMe, updateUserPermissions} from './users/api';
export {getPayslipHours, getPayslipExtras, updatePayslipHours, updatePayslipExtras} from './payslip/api';

export const loadTransportContracts = async () => {
    return axios.get<TransportContract[]>('/api/transport-contracts')
        .then(result => result.data)
        .catch(handleError)
};
