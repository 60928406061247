import React from 'react';
import {WorkGroupItemType} from "../../../../../API/types";
import {Box, Typography} from "@mui/material";
import {PLANNER_ROW_HEIGHT} from "../../../constants";
import {markerColors} from "../../../utils/colorUtils";
import {useDrag} from 'react-dnd';
import {green} from '@mui/material/colors';
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {getDisplayStartAndEndPoint, getDisplayTripCode} from "../../../utils/displayItemUtils";
import {
    selectOppositeGroupMarkerColorMap,
    selectPlannerType,
    selectSelectedOppositeGroupId, setDialogData,
    setSelectedOppositeGroupId,
    updateTripDefinitionWorkGroup,
    updateTripItemWorkSheet
} from '../../../../../store/plannerSlice';
import {
    DisplayTripDefinitionWorkItem,
    DisplayWorkGroupTripDefinition,
    DisplayWorkItem,
    PlannerType,
    StartAndEndTimeWithModifierAndId
} from "../../../types";
import {EntityId} from '@reduxjs/toolkit';
import PlannerItemTooltip from "../../Tooltip";


export const MIN_TRIP_CARD_WIDTH = 20;

interface TripCardProps {
    tripSegmentId: EntityId;
    trip: DisplayWorkGroupTripDefinition | DisplayWorkItem | DisplayTripDefinitionWorkItem;
    isFromOtherRegion: boolean;
    isRowDisabled?: boolean;
    currentGroupId?: EntityId;
    groupOfOppositeTypeId?: number;
    handleTripSplit?: () => void;
    handleTripMerge?: () => void;
}

const TripCard = ({
    tripSegmentId,
    trip,
    isFromOtherRegion,
    isRowDisabled = false,
    currentGroupId = 0,
    groupOfOppositeTypeId,
    handleTripSplit,
    handleTripMerge,
}: TripCardProps) => {
    const dispatch = useAppDispatch();

    const plannerType = useAppSelector(selectPlannerType);
    const oppositeGroupMarkerColorMap = useAppSelector(selectOppositeGroupMarkerColorMap);
    const selectedOppositeGroupId = useAppSelector(selectSelectedOppositeGroupId);

    const [{ isDragging }, drag] = useDrag<StartAndEndTimeWithModifierAndId | {id: number}, any, { isDragging: boolean }>({
        type: WorkGroupItemType.TRIP_DEFINITION,
        item: trip,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (tripSegment, monitor) => {
            const dropResult = monitor.getDropResult<{id: number}>();
            if (dropResult !== null) {
                if (plannerType === PlannerType.WORK_GROUP) {
                    dispatch(updateTripDefinitionWorkGroup({
                        movedTripId: tripSegment.id,
                        originWorkGroupId: currentGroupId,
                        targetWorkGroupId: dropResult.id
                    }));
                }
                if (plannerType === PlannerType.WORK_SHEET) {
                    dispatch(updateTripItemWorkSheet({
                        movedTripSegmentId: tripSegment.id,
                        originWorkSheetId: currentGroupId,
                        targetWorkSheetId: dropResult.id
                    }));
                }
            }
        },
    });

    const renderOppositeGroupMarker = () => {
        return (groupOfOppositeTypeId && trip.width > 10 ?
            <Box
                onClick={() => {
                    if (currentGroupId !== 0) {
                        dispatch(setSelectedOppositeGroupId(groupOfOppositeTypeId));
                    }
                }}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: '8px',
                    width: '8px',
                    borderRadius: '20px',
                    backgroundColor: currentGroupId !== 0 && oppositeGroupMarkerColorMap
                        ? markerColors[oppositeGroupMarkerColorMap[groupOfOppositeTypeId]]
                        : green[600],
                    border: '2px solid white',
                }}
            />
            :
            <></>
        );
    };

    const renderAddOppositeGroupMarker = () => {
        return (!isRowDisabled && currentGroupId !== 0 ?
            <Box
                onClick={() => {
                    if (plannerType === PlannerType.WORK_GROUP) {
                        dispatch(setDialogData({
                            addOppositeWorkGroupToTrip: {workGroupId: currentGroupId, tripId: trip.id}
                        }));
                    }
                    if (plannerType === PlannerType.WORK_SHEET) {
                        dispatch(setDialogData({
                            addOppositeWorkSheetToWorkItem: {workSheetId: currentGroupId, workItemId: trip.id}
                        }));
                    }
                }}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: '10px',
                    width: '10px',
                    borderRadius: '20px',
                    backgroundColor: 'common.white',
                    border: '1px solid white',
                }}
            >
                <Typography sx={{
                    fontSize: '14px',
                    lineHeight: '8px',
                    position: 'absolute',
                    top: '0px',
                    right: '1px',
                    color: green[500],
                    fontWeight: 600
                }}>
                    +
                </Typography>
            </Box>
            :
            <></>
        );
    };

    return (trip ?
        <Box key={tripSegmentId} sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            boxSizing: 'border-box',
            mt: `-${PLANNER_ROW_HEIGHT - 2}px`,
        }}>
            <PlannerItemTooltip isDragging={isDragging} item={trip} handleTripSplit={handleTripSplit} handleTripMerge={handleTripMerge}>
                <Box ref={isRowDisabled ? undefined : drag} aria-label={`Reis ${trip.code}`} sx={{
                    position: 'relative',
                    zIndex: 10,
                    height: `${PLANNER_ROW_HEIGHT - 6}px`,
                    borderRadius: '5px 15px 15px 5px',
                    my: '2px',
                    lineHeight: '15px',
                    left: trip.xPos,
                    maxWidth: trip.width,
                    minWidth: trip.width,
                    background: isFromOtherRegion ? `repeating-linear-gradient(
                        -45deg,
                        ${green[600]},
                        ${green[600]} 10px,
                        ${green[700]} 10px,
                        ${green[700]} 15px
                    )` : green[600],
                    boxSizing: 'border-box',
                    cursor: isRowDisabled ? '' : 'pointer',
                    opacity: isDragging ? 0.5 :
                        !isRowDisabled && selectedOppositeGroupId && selectedOppositeGroupId !== groupOfOppositeTypeId ? 0.7 : 1
                }}>
                    <Box sx={{position: 'relative', padding: trip.width > MIN_TRIP_CARD_WIDTH ? '6px 4px' : '6px 2px'}}>
                        {groupOfOppositeTypeId ? renderOppositeGroupMarker() : renderAddOppositeGroupMarker()}
                        <Typography sx={{color: 'white', fontWeight: 'bolder', fontSize: '12px'}}>
                            {trip.code ? getDisplayTripCode(trip.code, trip.width) : ''}
                        </Typography>
                        <Typography sx={{color: 'white', fontSize: '10px', fontWeight: 'bolder', letterSpacing: -0.1}}>
                            {getDisplayStartAndEndPoint(trip.route ?? null, trip.width)}
                        </Typography>
                    </Box>
                </Box>
            </PlannerItemTooltip>
        </Box>
        :
        <></>
    );
};

export default TripCard;
