import React from "react";
import MuiAutocomplete from "@mui/material/Autocomplete";
import {TextField} from "@mui/material";
import {useField} from "formik";

interface WithId {
    id: number;
}

interface AutocompleteProps<T extends WithId> {
    options: T[];
    name: string;
    label: string;
    autoFocus?: boolean;
    autoHighlight?: boolean;
    openOnFocus?: boolean;
    getOptionLabel: (option: T) => string;
    disabled?: boolean;
    noOptionsText?: string;
    onChange?: (value: T | null) => void;
}

function Autocomplete<T extends WithId>({options, name, label, autoFocus, autoHighlight, openOnFocus, getOptionLabel, disabled, noOptionsText, onChange}: AutocompleteProps<T>) {
    const [field, meta, helpers] = useField<T | null>(name);

    const noOptionsTextProps = noOptionsText ? {noOptionsText} : {}

    return (
        <MuiAutocomplete
            renderInput={(params) =>
                <TextField {...params} label={label}
                           autoFocus={autoFocus}
                           error={meta.touched && !!meta.error}
                           helperText={meta.touched && meta.error}
                />
            }
            autoSelect={true}
            autoHighlight={autoHighlight}
            openOnFocus={openOnFocus}
            onChange={(event, value) => {
                helpers.setValue(value);
                if (onChange) {
                    onChange(value);
                }
            }}
            options={options}
            size="small"
            value={field.value ?? null}
            sx={{my: 1}}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={getOptionLabel}
            disabled={disabled}
            {...noOptionsTextProps}
        />
    )
}

export default Autocomplete
