import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {
    ResourceGroupToggleButtonType,
    resourceGroupToggleButtonTypeToResourceGroupTypeMap,
    resourceGroupToggleButtonTypeValuesWithAll,
    ResourceGroupToggleButtonTypeWithAll
} from "../types";
import {useAppDispatch, useAppSelector} from "../hooks";
import {useCallback, useMemo} from "react";
import dayjs, {Dayjs} from "dayjs";
import {ResourceType} from "../API/workGroup/types";


interface ViewState {
    resourceToggleValue: ResourceGroupToggleButtonTypeWithAll;
    date: string;
}

const initialState: ViewState  = {
    resourceToggleValue: 'KÕIK',
    date: dayjs().format('YYYY-MM-DD'),
};

export const viewSlice = createSlice({
    name: 'regions',
    initialState: initialState,
    reducers: {
        setDate: (state, action: PayloadAction<string>) => {
            state.date = action.payload;
        },
        setResourceToggleValue: (state, action: PayloadAction<ResourceGroupToggleButtonTypeWithAll>) => {
            state.resourceToggleValue = action.payload;
        },
    },
});

export const {
    setDate,
    setResourceToggleValue,
} = viewSlice.actions;

export const selectResourceToggleValue = (state: RootState) => state.view.resourceToggleValue;

export const selectDate = createSelector(
    (state: RootState) => state.view.date,
    (date) => dayjs(date)
);

export const selectToggledResourceType = createSelector(
    selectResourceToggleValue,
    (state: RootState) => state.view.resourceToggleValue,
    (resourceToggleValue) => resourceGroupToggleButtonTypeToResourceGroupTypeMap.get(resourceToggleValue) ?? ResourceType.DRIVER
);

export const useResourceTypeToggle = <T extends ResourceGroupToggleButtonTypeWithAll | ResourceGroupToggleButtonType>(
    includeAll = false
): [T, (value: string) => void] => {
    const resourceToggleValue = useAppSelector(state => state.view.resourceToggleValue);
    const dispatch = useAppDispatch();

    const handleResourceToggleValueChange = useCallback((value: string) => {
        if (resourceGroupToggleButtonTypeValuesWithAll.includes(value as T)) {
            dispatch(setResourceToggleValue(value as T));
        }
    }, [dispatch]);

    return [(resourceToggleValue === 'KÕIK' && !includeAll ? 'JUHT' : resourceToggleValue) as T, handleResourceToggleValueChange];
};

export const useDayNavigation = (): [Dayjs, (date: Dayjs) => void] => {
    const date = useAppSelector(state => state.view.date);
    const dispatch = useAppDispatch();

    const day = useMemo(() => dayjs(date), [date]);

    const handleChangeDate = useCallback((value: Dayjs) => {
        dispatch(setDate(value.format('YYYY-MM-DD')));
    }, [dispatch]);

    return [day, handleChangeDate];
};

export default viewSlice.reducer;
